import React, { useState } from 'react';
import { Table } from 'src/components/IMUI';
import { getOrgText } from 'src/services/DictionaryService';
import classNames from 'classnames/bind';
import cls from './PreviewDataTable.module.css';
const cx = classNames.bind(cls);

const PreviewDataRow = ({ row, index }) => {
  const [expanded, setExpanded] = useState(false);

  const oldValue = (note, field) => {
    const value =
      field === 'custom_fields'
        ? JSON.stringify(note.changes?.[field]?.[0])
        : note.changes?.[field]?.[0];

    return !value ? '<blank>' : value;
  };

  const newValue = (note, field) => {
    const value =
      field === 'custom_fields'
        ? JSON.stringify(note.changes?.[field]?.[1])
        : note.changes?.[field]?.[1];

    return !value ? '<blank>' : value;
  };

  const formatChanges = (note) => {
    return Object.keys(note.changes || {}).map((field, fieldIndex) => (
      <li key={`field-${fieldIndex}`}>
        <p>
          <strong>{field}:</strong>
        </p>
        <p>
          <span style={{ color: 'red' }}>{oldValue(note, field)}</span>
          &nbsp;=&gt;&nbsp;
          <span style={{ color: 'green' }}>{newValue(note, field)}</span>
        </p>
      </li>
    ));
  };

  return (
    <Table.Row>
      <Table.Cell
        className={cx({
          'table-row-valid': !row.has_error,
          'table-row-error': row.has_error,
        })}
      >
        {index + 1}
      </Table.Cell>
      <Table.Cell>
        {row.notes.map((note, noteIndex) => (
          <li
            key={`notes-${noteIndex}`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <strong>{getOrgText(note.message)}</strong>
            <br />
            <ul>{expanded && formatChanges(note)}</ul>
          </li>
        ))}
      </Table.Cell>
      {Object.values(row.row).map((value, colIndex) => (
        <Table.Cell width={70} key={`preview-col-${colIndex}`}>
          {value?.toString() || ''}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export default PreviewDataRow;
