import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  Container,
  Dialog,
  Section,
  TabSegments,
  TextField,
} from 'src/components/IMUI';
import debounce from 'src/utils/debounce';
import { Icon } from 'im/ui/Icon';
import OnboardingImg from 'src/static/onboarding.png';
import stripeData from 'src/data/stripeDataStaging.json';
import cls from './UserOrgEditLicensePlan.module.css';
import LicenseSurveyOnlyCard from '../components/LicenseSurveyOnlyCard';
import LicenseFullSuiteCard from '../components/LicenseFullSuiteCard';
import { useDispatch } from 'react-redux';
import { capitalize } from 'src/utils/string';
import stripeCheckoutApi from 'src/api/Stripe';
import stripeSessionApi from 'src/api/StripeSession';
import { where } from 'im/api/Query';
import { loadStripe } from '@stripe/stripe-js';
import {
  isSubscribed,
  isFullSuite,
  isStaff,
  surveyOnly,
  LICENSE_UPGRADE_URL,
} from 'src/userStorage';
import store from 'src/store';

const stripePromise = loadStripe(stripeData['publishable-key']);

const UserOrgEditLicensePlan = ({ location }) => {
  const dispatch = useDispatch();

  const aiTaggingEnabled = store
    .getState()
    ?.organizationCurrent?.data?.flags?.includes('ai-tagging');
  const aiWriterEnabled = store
    .getState()
    ?.organizationCurrent?.data?.flags?.includes('ai-writer');
  const aiTranslateEnabled = store
    .getState()
    ?.organizationCurrent?.data?.flags?.includes('ai-translation');

  const urlParams = new URLSearchParams(location.search);
  const postCheckoutSessionId = urlParams.get('session_id');
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeError, setCouponCodeError] = useState('');
  const [paymentInterval, setPaymentInterval] = useState('monthly');
  const [hasPurchasedService, setHasPurchasedService] = useState(false);
  const [showPostCheckoutDialog, setShowPostCheckoutDialog] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [activePlan, setActivePlan] = useState(
    isSubscribed() ? 'full-suite' : 'survey-only'
  );

  const [additionalUserCount, setAdditionalUserCount] = useState(0);
  const [aiTranslationAndInsightsActive, setAiTranslationAndInsightsActive] =
    useState(aiTranslateEnabled && aiWriterEnabled);
  const [aiTaggingActive, setAiTaggingActive] = useState(aiTaggingEnabled);

  const onClosePostCheckoutDialog = () => {
    setShowPostCheckoutDialog(false);
  };

  const baseSubscriptionVoid = useCallback(() => {
    return (
      (activePlan === 'survey-only' && surveyOnly()) ||
      ((activePlan === 'full-suite' ||
        activePlan === 'full-suite-for-large-organizations') &&
        isFullSuite())
    );
  }, [activePlan]);

  useEffect(() => {
    if (postCheckoutSessionId) {
      dispatch(
        stripeSessionApi.find(where({ id: postCheckoutSessionId }))
      ).then((response) => {
        setHasPurchasedService(response.has_service);
        setShowPostCheckoutDialog(true);
      });
    }
  }, [dispatch, postCheckoutSessionId]);

  useEffect(() => {
    const currentLineItems = [
      ...(!baseSubscriptionVoid() // Has subscription to purchase
        ? [
            {
              price: stripeData[activePlan][paymentInterval].base.priceId,
              quantity: 1,
            },
          ]
        : []),
      ...(additionalUserCount > 0 // Additional User Add-On
        ? [
            {
              price: stripeData[activePlan][paymentInterval].user.priceId,
              quantity: additionalUserCount,
            },
          ]
        : []),
      ...(aiTranslationAndInsightsActive &&
      !(aiTranslateEnabled || aiWriterEnabled) // Ai TranslationAndInsights Add-On
        ? [
            {
              price:
                stripeData[activePlan][paymentInterval]
                  .aiTranslationAndInsightsJan2025.priceId,
              quantity: 1,
            },
          ]
        : []),
      ...(aiTaggingActive &&
      (activePlan === 'full-suite' ||
        activePlan === 'full-suite-for-large-organizations') &&
      !aiTaggingEnabled // Ai Tagging AddOn
        ? [
            {
              price: stripeData[activePlan][paymentInterval].aiTagging.priceId,
              quantity: 1,
            },
          ]
        : []),
    ];

    setLineItems(currentLineItems);
  }, [
    activePlan,
    additionalUserCount,
    paymentInterval,
    aiTranslationAndInsightsActive,
    aiTaggingActive,
  ]);

  const checkoutWithStripe = (response) => {
    const sessionId = response.id;

    stripePromise
      .then((stripe) => {
        if (!stripe) {
          throw new Error('Stripe failed to load');
        }
        return stripe.redirectToCheckout({
          sessionId: sessionId, // Use the session ID from your backend
        });
      })
      .then((result) => {
        if (result.error) {
          console.error('Stripe checkout error:', result.error);
        }
      })
      .catch((error) => {
        console.error('Stripe load error:', error);
      });
  };

  const onCouponCodeChange = debounce((value) => {
    setCouponCode(value);

    if (Object.keys(stripeData['coupon-codes']).includes(value)) {
      setCouponCodeError('');
    } else {
      setCouponCodeError('Coupon Code not found');
    }
  }, 400);

  const onUpgradeLicense = () => {
    dispatch(
      stripeCheckoutApi.create(
        where().payload({
          line_items: lineItems,
          mode: 'subscription',
          coupon_code: stripeData['coupon-codes']?.[couponCode],
        })
      )
    ).then(checkoutWithStripe);
  };

  const onPurchaseTraining = (priceId) => {
    dispatch(
      stripeCheckoutApi.create(
        where().payload({
          line_items: [
            {
              price: priceId,
              quantity: 1,
            },
          ],
          mode: 'payment',
        })
      )
    ).then(checkoutWithStripe);
  };

  const baseSubscriptionPrice = (plan) => {
    return !isSubscribed()
      ? stripeData[plan][paymentInterval].base.amount
      : isSubscribed() &&
        surveyOnly() &&
        (plan === 'full-suite' || plan === 'full-suite-for-large-organizations')
      ? stripeData[plan][paymentInterval].base.amount
      : 0;
  };

  const additionalUserPrice = (plan) => {
    return stripeData[plan][paymentInterval].user.amount * additionalUserCount;
  };

  const aiTranslationAndInsightsPrice = (plan) => {
    return aiTranslationAndInsightsActive &&
      !(aiWriterEnabled || aiTranslateEnabled)
      ? stripeData[plan][paymentInterval].aiTranslationAndInsightsJan2025.amount
      : 0;
  };

  const aiTaggingPrice = (plan) => {
    return aiTaggingActive &&
      (plan === 'full-suite' ||
        plan === 'full-suite-for-large-organizations') &&
      !aiTaggingEnabled
      ? stripeData[plan][paymentInterval].aiTagging.amount
      : 0;
  };

  const totalAmount = () => {
    return (
      baseSubscriptionPrice(activePlan) +
      additionalUserPrice(activePlan) +
      aiTranslationAndInsightsPrice(activePlan) +
      aiTaggingPrice(activePlan)
    );
  };

  const calculateTotal = () => {
    const suffix = paymentInterval === 'monthly' ? '/mo' : '/yr';

    return `$${totalAmount()}${suffix}`;
  };

  const calculateBaseSubscriptionSavings = () => {
    if (paymentInterval === 'monthly') return 0;
    if (baseSubscriptionVoid()) return 0;

    const monthlyBaseSubscriptionPrice =
      stripeData[activePlan].monthly.base.amount;
    const yearlyBaseSubscriptionPrice =
      stripeData[activePlan].yearly.base.amount;

    return monthlyBaseSubscriptionPrice * 12 - yearlyBaseSubscriptionPrice;
  };

  const paymentIntervals = [
    {
      id: 'monthly',
      active: paymentInterval === 'monthly',
      text: 'Monthly',
    },
    {
      id: 'yearly',
      active: paymentInterval === 'yearly',
      text: 'Yearly',
    },
  ];

  return (
    <Section>
      <h3>Subscriptions</h3>
      <Dialog
        title={
          <div className={cls.postCheckoutImage}>
            <img
              src={OnboardingImg}
              alt="Thank you for you purchase"
              height={200}
              width={200}
            />
          </div>
        }
        small
        open={showPostCheckoutDialog}
        onRequestClose={onClosePostCheckoutDialog}
        bodyStyle={{ textAlign: 'center' }}
        rightActions={
          <Button
            negative
            size="l"
            label="Close"
            onClick={onClosePostCheckoutDialog}
          />
        }
      >
        <Container>
          <h3>Thank you for your purchase!</h3>
          <p style={{ color: 'inherit', textAlign: 'center' }}>
            {hasPurchasedService && (
              <>
                Our representative will reach out to you regarding your
                purchase.
                <br />
              </>
            )}
            If you have any questions, please don&apos;t hesitate to reach out
            to us at &nbsp;
            <a href="mailto:team@impactmapper.com">team@impactmapper.com</a>
          </p>
        </Container>
      </Dialog>
      <Container className={cls.planContainer}>
        <Card border flat className={cls.planCustomizer}>
          <div className={cls.paymentIntervalContainer}>
            <h3>Plans</h3>
            <TabSegments
              dark
              compact
              border
              className={cls.paymentIntervalSelector}
              onToggle={(value) => setPaymentInterval(value)}
              segments={paymentIntervals}
            />
          </div>
          <div className={cls.planTilesContainer}>
            {!isFullSuite() && (
              <LicenseSurveyOnlyCard
                title={'Survey-Only'}
                active={activePlan === 'survey-only'}
                onActivate={() => setActivePlan('survey-only')}
                currentSubscription={surveyOnly()}
                additionalUserPrice={additionalUserPrice('survey-only')}
                aiTranslationAndInsightsPrice={aiTranslationAndInsightsPrice(
                  'survey-only'
                )}
                baseSubscriptionPrice={baseSubscriptionPrice('survey-only')}
                additionalUserCount={additionalUserCount}
                setAdditionalUserCount={setAdditionalUserCount}
                aiTranslationAndInsightsActive={aiTranslationAndInsightsActive}
                setAiTranslationAndInsightsActive={
                  setAiTranslationAndInsightsActive
                }
              />
            )}
            <LicenseFullSuiteCard
              title={'Full Suite'}
              subtitle={'For foundations < $5M'}
              active={activePlan === 'full-suite'}
              onActivate={() => setActivePlan('full-suite')}
              currentSubscription={isFullSuite()}
              additionalUserPrice={additionalUserPrice('full-suite')}
              aiTranslationAndInsightsPrice={aiTranslationAndInsightsPrice(
                'full-suite'
              )}
              aiTaggingPrice={aiTaggingPrice('full-suite')}
              baseSubscriptionPrice={baseSubscriptionPrice('full-suite')}
              additionalUserCount={additionalUserCount}
              setAdditionalUserCount={setAdditionalUserCount}
              aiTranslationAndInsightsActive={aiTranslationAndInsightsActive}
              setAiTranslationAndInsightsActive={
                setAiTranslationAndInsightsActive
              }
              aiTaggingActive={aiTaggingActive}
              setAiTaggingActive={setAiTaggingActive}
            />

            <LicenseFullSuiteCard
              title={'Full Suite for Large Organizations'}
              subtitle={'For foundations >= $5M'}
              active={activePlan === 'full-suite-for-large-organizations'}
              onActivate={() =>
                setActivePlan('full-suite-for-large-organizations')
              }
              currentSubscription={isFullSuite()}
              additionalUserPrice={additionalUserPrice(
                'full-suite-for-large-organizations'
              )}
              aiTranslationAndInsightsPrice={aiTranslationAndInsightsPrice(
                'full-suite-for-large-organizations'
              )}
              aiTaggingPrice={aiTaggingPrice(
                'full-suite-for-large-organizations'
              )}
              baseSubscriptionPrice={baseSubscriptionPrice(
                'full-suite-for-large-organizations'
              )}
              additionalUserCount={additionalUserCount}
              setAdditionalUserCount={setAdditionalUserCount}
              aiTranslationAndInsightsActive={aiTranslationAndInsightsActive}
              setAiTranslationAndInsightsActive={
                setAiTranslationAndInsightsActive
              }
              aiTaggingActive={aiTaggingActive}
              setAiTaggingActive={setAiTaggingActive}
            />
          </div>
        </Card>
        <Card flat className={cls.totalContainer}>
          <div className={cls.totalBanner}>
            <div>
              <h3>
                {activePlan === 'survey-only' ? 'Survey Only' : 'Full Suite'}
              </h3>
              <p>Billed {capitalize(paymentInterval)}</p>
              <h3>{calculateTotal()}</h3>
              {calculateBaseSubscriptionSavings() > 0 ? (
                <p>
                  You save{' '}
                  <span style={{ fontSize: 14 }}>
                    ${calculateBaseSubscriptionSavings()}
                  </span>{' '}
                  on the base subscription by selecting the yearly plan
                </p>
              ) : (
                <p>Get access now.</p>
              )}
              <Button
                label="Upgrade"
                size="l"
                disabled={totalAmount() === 0}
                primary
                onClick={() => onUpgradeLicense()}
              />
            </div>
          </div>
          <div>
            <br />
            <TextField
              value={couponCode}
              error={couponCodeError}
              placeholder="Enter Coupon Code"
              icon={
                couponCode && !couponCodeError ? (
                  <Icon
                    name="check"
                    color={'green'}
                    tip="Coupon code applied"
                  />
                ) : (
                  <Icon name="tag" color={'gray'} tip="Enter a coupon code" />
                )
              }
              onChange={onCouponCodeChange}
            />
            <p>
              We&apos;ve estimated your {paymentInterval} cost based on the
              options you&apos;ve chosen. Any applicable taxes are not included,
              and your fees are subject to increase with additional purchases.
            </p>
            <p>
              Cannot find the right plan and need a sliding scale?
              <Button
                text
                underlined
                target="_blank"
                href={LICENSE_UPGRADE_URL}
                label="Contact Us"
              />
            </p>
          </div>
        </Card>
      </Container>
      <h3>Trainings</h3>
      <Container className={cls.trainingContainer}>
        <Card className={cls.trainingCard}>
          <div className={cls.trainingCardContent}>
            <div>
              <h2>Survey Training</h2>
              <h3>${stripeData['survey-training'].amount}</h3>
              <p>
                Learn how to design surveys in order to increase rate of
                responses
              </p>
              <Button
                label="Purchase"
                size="l"
                primary
                onClick={() =>
                  onPurchaseTraining(stripeData['survey-training'].priceId)
                }
              />
            </div>
          </div>
        </Card>
        <Card className={cls.trainingCard}>
          <div className={cls.trainingCardContent}>
            <div>
              <h2>Coding Training</h2>
              <h3>${stripeData['coding-training'].amount}</h3>
              <p>
                Learn how to effectively conduct detailed analysis by Tagging
                your data
              </p>
              <Button
                label="Purchase"
                size="l"
                primary
                onClick={() =>
                  onPurchaseTraining(stripeData['coding-training'].priceId)
                }
              />
            </div>
          </div>
        </Card>
        <Card className={cls.trainingCard}>
          <div className={cls.trainingCardContent}>
            <div>
              <h2>Custom Training</h2>
              <p>Can&apos;t find the right training?</p>
              <Button
                label="Contact Us"
                target="_blank"
                size="l"
                primary
                href={LICENSE_UPGRADE_URL}
              />
            </div>
          </div>
        </Card>
        {isStaff() && (
          <Card className={cls.trainingCard}>
            <div className={cls.trainingCardContent}>
              <div>
                <h2>ImpactMapper Internal Testing</h2>
                <h3>${stripeData['internal-testing'].amount}</h3>
                <p>ImpactMapper - Internal Testing</p>
                <Button
                  label="Purchase"
                  size="l"
                  primary
                  onClick={() =>
                    onPurchaseTraining(stripeData['internal-testing'].priceId)
                  }
                />
              </div>
            </div>
          </Card>
        )}
      </Container>
    </Section>
  );
};

UserOrgEditLicensePlan.propTypes = {
  location: PropTypes.object,
};

export default UserOrgEditLicensePlan;
