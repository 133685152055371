import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import React, { createRef, Component } from 'react';
import { Field } from 'redux-form';

import {
  Divider,
  TextField,
  FormField,
  PopoverLight,
} from 'src/components/IMUI';

import { Icon } from 'im/ui/Icon';

import MergeTagForm from './MergeTagForm';
import TagItemReview from './TagItemReview';

import cls from './TagDetailedItem.module.css';

export default class TagDetailedItem extends Component {
  static propTypes = {
    name: PropTypes.string,
    tag: PropTypes.object,
    onEdit: PropTypes.func.isRequired,
    onMergeStart: PropTypes.func,
  };

  state = {
    mergeTagsEnable: false,
    anchorEl: null,
  };

  constructor(props) {
    super(props);
    this.formRef = createRef();
  }

  handleEdit = () => {
    const { tag } = this.props;
    this.props.onEdit({ ...tag, remitted: false });
  };

  handlePopoverOpenClick = (ev) => {
    ev?.preventDefault();
    this.setState({ anchorEl: ev.currentTarget });
  };

  handlePopoverCloseClick = () => {
    this.setState({ anchorEl: null });
  };

  handlePopoverMenuClick = () => {
    this.setState(({ mergeTagsEnable }) => ({
      mergeTagsEnable: !mergeTagsEnable,
      anchorEl: null,
    }));
  };

  renderEditable = (tag) => {
    return <TagItemReview tag={tag} onEdit={this.handleEdit} />;
  };

  renderInputs = (name) => {
    const { anchorEl } = this.state;
    return (
      <div>
        <Divider className={cls.tagDetailItemDivider} />

        <div className={cls.tagDetailItemTagNameWrapper} ref={this.formRef}>
          <FormField className={cls.tagDetailItemTagNameField}>
            <Field
              flat
              borderDark
              fullWidth
              required
              name={`${name}.title`}
              component={TextField}
              label="Tag name"
              hintText="Type in a tag name..."
            />
          </FormField>
          {this.props.onMergeStart && [
            <Icon
              key="icon"
              name="dot-dot-dot"
              onClick={this.handlePopoverOpenClick}
              style={{ fontSize: '24px', color: '#979797' }}
            />,
            <PopoverLight
              key="popover"
              open={!!anchorEl}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              onRequestClose={this.handlePopoverCloseClick}
              className={cls.tagDetailItemPopover}
            >
              <MenuItem
                primaryText="Merge duplicated tags..."
                onClick={this.handlePopoverMenuClick}
                style={{ fontFamily: 'averta, Arial', fontSize: '14px' }}
              />
            </PopoverLight>,
          ]}
          {this.props.onMergeStart && this.state.mergeTagsEnable && (
            <MergeTagForm
              anchorEl={this.formRef.current}
              onMergeClick={this.props.onMergeStart}
              onCancel={this.handlePopoverMenuClick}
              tag={this.props.tag}
            />
          )}
        </div>

        <FormField>
          <Field
            flat
            borderDark
            name={`${name}.description`}
            component={TextField}
            fullWidth
            label="Description"
            hintText="Type in a description..."
          />
        </FormField>

        <FormField>
          <Field
            flat
            borderDark
            name={`${name}.code`}
            component={TextField}
            label="Shortcode"
            style={{ maxWidth: 242 }}
            hintText="Type in shortcode..."
          />
        </FormField>
      </div>
    );
  };

  render() {
    const { tag, name } = this.props;

    return tag.remitted ? this.renderEditable(tag) : this.renderInputs(name);
  }
}
