import PropTypes from 'prop-types';
import React from 'react';
import { Actions, Container, Section, Table } from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import { Link } from 'react-router-dom';
import {
  exportAnswersTable,
  respondentLabelFromResponse,
} from '../surveyAnalysisUtils';
import AnswersTableTags from './AnswersTableTags';
import AnswersTableValue, { AnswerTableQuote } from './AnswersTableValue';
import colors from 'src/css/constants.json';
import cls from './AnswersTable.module.css';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';

export default class AnswersTable extends React.PureComponent {
  static propTypes = {
    projectId: PropTypes.string,
    surveyId: PropTypes.string,
    question: PropTypes.object,
    responses: PropTypes.array,
    answers: PropTypes.array,
  };

  handleDownloadAnswers = () => {
    const data = exportAnswersTable(
      this.props.question,
      this.props.answers,
      this.props.responses
    );

    const csv_data = [['Respondent', 'Answer\n'], data];

    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const blob = new Blob(csv_data, {
      type: 'tex/csv',
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${new Date().toISOString()}-${
      this.props.question.question
    }-responses-impactmapper-export.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const hasMultiSeries =
      [
        ...new Set(
          this.props.responses?.map((i) => i.batch).filter((i) => i?.length > 0)
        ),
      ]?.length > 1;
    const getAnswer = (gsId) =>
      this.props.answers.find((a) => a.grantee_survey.id == gsId) ?? {};
    return (
      <div className={cls.tableWrapper}>
        <Container horizontal>
          <Actions />
          <Actions>
            <Icon
              name="download"
              onClick={this.handleDownloadAnswers}
              tip="Download responses"
              placeTip="left"
            />
          </Actions>
        </Container>
        <br />
        <TableComposition stickyHeader>
          <Table.Head>
            <Table.Cell />
            {hasMultiSeries && <Table.HCell text="Series" />}
            <Table.HCell text="Respondent" className={cls.fixedWidthCell} />
            <Table.HCell className={cls.tagsListAnswer} text="Answer" />
            <Table.HCell width={10} />
            <Table.HCell className={cls.tagsListTag} text="Tags" />
          </Table.Head>
          <Table.Body>
            {this.props.responses.map((gs, itemIndex) => (
              <Table.Row key={`response-${itemIndex}`} id={`response-${gs.id}`}>
                <Table.HCell text={`${itemIndex + 1}.`} />
                <Table.Cell>
                  <Link
                    style={{ color: colors.dark }}
                    to={`/analysis/${this.props.projectId}/surveys/${this.props.surveyId}/responses/${gs.id}`}
                  >
                    {respondentLabelFromResponse(gs)}
                  </Link>
                </Table.Cell>
                {hasMultiSeries && <Table.Cell text={gs.batch} />}
                <Table.Cell
                  style={{ padding: 4 }}
                  className={cls.tagCellAnswer}
                >
                  <AnswersTableValue
                    question={this.props.question}
                    answer={getAnswer(gs.id)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <AnswerTableQuote
                    question={this.props.question}
                    answer={getAnswer(gs.id)}
                  />
                </Table.Cell>
                <Table.Cell className={cls.tagCellTag}>
                  <AnswersTableTags
                    question={this.props.question}
                    answer={getAnswer(gs.id)}
                    granteeSurveyId={gs.id}
                    isReview={false}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </TableComposition>
      </div>
    );
  }
}
