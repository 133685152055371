import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import taggingsApi from 'src/api/Taggings';
import { Tile, Section, Container } from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import history from 'src/historyInstance';
import CHARTS__ICON from 'src/static/charts-gray.png';
import REPORTS_ICON from 'src/static/reports-gray.png';
import SURVEYS_ICON from 'src/static/surveys-gray.png';
import TAGGING_ICON from 'src/static/tags-gray.png';
import UPGRADE_ICON from 'src/static/stories.png';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import Quotes from '../components/Quotes';
import cls from './SummaryStandalone.module.css';
import {
  canManageChart,
  canManageReport,
  canManageSurvey,
  canManageTag,
  surveyOnly,
  isSubscribed,
  userCurrentOrgId,
} from 'src/userStorage';
const cx = classNames.bind(cls);

@connect((state) => ({ projectQuotes: state.quotes }), {
  getProjectQuotes: taggingsApi.quotes.findAllPerProject,
})
export default class SummaryStandalone extends React.PureComponent {
  static propTypes = {
    project: PropTypes.object,
    projectQuotes: PropTypes.object,
    getProjectQuotes: PropTypes.func.isRequired,
  };
  static defaultProps = { project: {} };
  state = { quotes: [] };
  componentDidMount() {
    this.getQuotes();
  }

  getQuotes = () => {
    this.props
      .getProjectQuotes(
        where({ project_id: this.props.project.uid })
          .include(
            'report',
            'survey_answer',
            'survey_answer.survey_question',
            'grantee',
            'grantee_survey',
            'project_reports'
          )
          .paginate({ size: 150 })
      )
      .then((res) => {
        const quotes = res?.data?.map((i) => ({
          ...i,
          content: i.content,
          position: i.position,
          project_report_id:
            i.report?.project_reports?.find(
              (p) => p.project_id == this.props.project.id
            )?.id || i.report?.project_reports?.[0]?.id,
          report_id: i.report?.id,
          grantee_survey_id: i.grantee_survey?.id,
          grantee_name: i.grantee_survey?.grantee_name,
          survey_id: i.grantee_survey?.survey_id,
        }));
        this.setState({ quotes });
      });
  };

  getCounter = (title) => {
    const counter =
      this.props.project[
        {
          reports: 'reports_count',
          charts: 'charts_count',
          surveys: 'surveys_count',
          taggings: 'taggings_count',
        }[title.toLowerCase()]
      ];
    const counterParsed = counter > 100 ? `${counter}+` : counter;
    const titleParsed = counter > 1 || !counter ? title : title.slice(0, -1);
    return counter ? `${counterParsed} ${titleParsed}` : titleParsed;
  };

  onRedirect(ev, path) {
    ev.stopPropagation();
    history.push(`/analysis/${this.props.project.id}${path}`);
  }
  onUpgrade(ev) {
    ev.stopPropagation();
    const licenseUrl = `/user/organizations/edit/${userCurrentOrgId()}/license`;
    history.push(licenseUrl);
  }
  render() {
    const modules = [
      surveyOnly() || !isSubscribed()
        ? null
        : {
            icon: REPORTS_ICON,
            title: 'Reports',
            text: 'Analyze reports, publications and surveys',
            goToPath: '/reports',
            addPath: canManageReport() ? '/reports' : null,
          },
      surveyOnly() || !isSubscribed()
        ? null
        : {
            icon: CHARTS__ICON,
            title: 'Charts',
            text: 'Visualize data in a variety of formats',
            goToPath: '/charts',
            addPath: canManageChart() ? '/charts/new' : null,
          },
      surveyOnly() || !isSubscribed()
        ? null
        : {
            icon: TAGGING_ICON,
            title: 'Taggings',
            text: 'Tag reports and edit custom tag lists',
            goToPath: '/tags',
            addPath: canManageTag() ? '/tags/edit' : null,
          },
      !isSubscribed()
        ? null
        : {
            icon: SURVEYS_ICON,
            title: 'Surveys',
            text: 'Create surveys and analyze responses',
            goToPath: '/surveys',
            addPath: canManageSurvey() ? '/surveys/new' : null,
          },
    ].filter(Boolean);

    return (
      <Page title="Summary">
        <Section type="sub-header" collapsed>
          <h1>{this.props.project.name}</h1>
        </Section>
        <Section>
          <Container horizontal className={cx('summary-standalone-container')}>
            {!isSubscribed() && (
              <Tile className={cx('summary-standalone-tile')}>
                <Tile.Icon
                  imgUrl={UPGRADE_ICON}
                  style={{ height: 175, width: 175, margin: '0 auto' }}
                />
                <Tile.Text className={cx('summary-standalone-title')}>
                  Upgrade to get access to ImpactMapper Chart, Reports, Survey,
                  and Tagging modules
                </Tile.Text>

                <Container
                  horizontal
                  centerHorizontal
                  className={cx('summary-standalone-actions')}
                >
                  <Tile.Button
                    label={'Upgrade'}
                    onClick={(ev) => this.onUpgrade(ev)}
                    style={{ marginBottom: 0 }}
                  />
                </Container>
              </Tile>
            )}

            {modules.map(({ icon, title, text, goToPath, addPath }) => (
              <Tile
                key={title}
                onClick={(ev) => this.onRedirect(ev, goToPath)}
                className={cx('summary-standalone-tile')}
              >
                <Tile.Icon
                  imgUrl={icon}
                  style={{ height: 175, width: 175, margin: '0 auto' }}
                />
                <Tile.Title className={cx('summary-standalone-title')}>
                  {this.getCounter(title)}
                </Tile.Title>
                <Tile.Text className={cx('summary-standalone-title')}>
                  {text}
                </Tile.Text>

                <Container
                  horizontal
                  centerHorizontal
                  className={cx('summary-standalone-actions')}
                >
                  <Tile.Button
                    label={`View ${title}`}
                    onClick={(ev) => this.onRedirect(ev, goToPath)}
                    style={{ marginBottom: 0 }}
                  />
                  {addPath && (
                    <Icon
                      name="plus"
                      className={cx('summary-standalone-btn-icon')}
                      onClick={(ev) => this.onRedirect(ev, addPath)}
                    />
                  )}
                </Container>
              </Tile>
            ))}

            {this.state.quotes?.length > 0 && (
              <Tile
                size="l"
                className={cx(
                  'summary-standalone-tile',
                  'summary-standalone-quotes'
                )}
              >
                <Quotes
                  flat
                  quotes={this.state.quotes}
                  projectId={this.props.project.id}
                  className={cx('quotes')}
                />
              </Tile>
            )}
          </Container>
        </Section>
      </Page>
    );
  }
}
