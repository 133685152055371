import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import RDropzone from 'react-dropzone';
import { InputReduxForm, Label, Error } from 'src/components/IMUI/Forms/base';
import { Icon } from 'im/ui/Icon';
import cls from './Dropzone.module.css';
const cx = classNames.bind(cls);

export const MAX_UPLOAD_SIZE = 10 * 1000 * 1000; // 100 MB

export class Dropzone extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    noValidation: PropTypes.bool,
    label: PropTypes.node,
    error: PropTypes.string,
    onDrop: PropTypes.func,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    activeClassName: PropTypes.string,
    rejectedClassName: PropTypes.string,
    onClear: PropTypes.func,
    children: PropTypes.node,
    accepted: PropTypes.array,
    rejected: PropTypes.array,
    iconInsteadOfText: PropTypes.bool,
    disablePreview: PropTypes.bool,
    style: PropTypes.object,
    ...InputReduxForm.propTypes,
  };

  static defaultProps = {
    accepted: [],
    onClear: () => void 0,
    noValidation: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      accepted: this.toFileArray(
        props.input ? props.input.value : props.accepted,
        props.disablePreview
      ),
    };
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.input && nextProps.accepted !== this.state.accepted) {
      this.setState({
        accepted: this.toFileArray(
          nextProps.accepted,
          nextProps.disablePreview
        ),
      });
    }
    if (nextProps.input && nextProps.input.value !== this.state.accepted) {
      this.setState({
        accepted: this.toFileArray(
          nextProps.input.value,
          nextProps.disablePreview
        ),
      });
    }
  }
  componentWillUnmount() {
    this.revokeFilesUrl(this.state.accepted);
  }

  onDrop = async (accepted, _rejected) => {
    this.revokeFilesUrl(this.state.accepted);
    this.setState({ accepted }, () =>
      InputReduxForm.onChange(this.props.input, this.props.onDrop)(accepted)
    );
  };

  onClearFiles = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.revokeFilesUrl(this.state.accepted);
    this.setState({ accepted: [] }, () =>
      InputReduxForm.onChange(this.props.input, this.props.onClear)([])
    );
  };

  toFileArray(items, disablePreview) {
    if (!items) return [];
    const itemsArray = Array.isArray(items) ? items : [items];
    const requiredProp = disablePreview ? 'name' : 'preview';
    return itemsArray.map((i) =>
      typeof i[requiredProp] === 'string' ? i : { [requiredProp]: i }
    );
  }

  revokeFilesUrl(files) {
    (files || []).forEach(({ preview }) => window.URL.revokeObjectURL(preview));
  }

  renderDroppedFiles(files, _disablePreview) {
    const disablePreview = _disablePreview || files.length > 1;
    return (
      <div>
        <Icon
          name="close"
          className={cx('dropzone-icon-delete')}
          onClick={(ev) => this.onClearFiles(ev)}
        />
        {!disablePreview ? (
          <div
            className={cx('dropzone-preview')}
            style={{ backgroundImage: `url(${files[0].preview})` }}
          />
        ) : (
          <ul className={cx('dropzone-file-list')}>
            {files.map((el, index) => (
              <li
                key={`${el.name}_${index}`}
                className={cx('dropzone-file-list-item')}
              >
                {el.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  renderHintText(showIcon) {
    return showIcon ? (
      <Icon name="upload" />
    ) : (
      <p>{`Drag and drop your ${
        this.props.multiple ? 'files' : 'file'
      } here or click to select from your drive`}</p>
    );
  }

  render() {
    /* eslint-disable */
    const {
      className,
      containerClassName,
      activeClassName,
      rejectedClassName,
      accept,
      children,
      input,
      meta,
      noValidation,
      label,
      error,
      name,
      iconInsteadOfText,
      disablePreview,
      style,
      accepted: acceptedFiles,
      rejected,
      onClear,
      onDrop,
      rightLabel,
      ...rest
    } = this.props;
    /* eslint-enable */
    const { accepted } = this.state;

    return (
      <div className={containerClassName}>
        {!this.props.rightLabel && label && <Label label={label} />}
        <RDropzone
          {...rest}
          style={style}
          name={name}
          className={cx('dropzone', className)}
          activeClassName={cx('dropzone-active', activeClassName)}
          rejectClassName={cx('dropzone-rejected', rejectedClassName)}
          accept={accept}
          onDrop={this.onDrop}
          disablePreview={disablePreview}
          maxSize={MAX_UPLOAD_SIZE}
        >
          {accepted.length
            ? this.renderDroppedFiles(accepted, disablePreview)
            : this.renderHintText(iconInsteadOfText)}
          {children}
        </RDropzone>
        {this.props.rightLabel && label && <Label label={label} />}
        {!noValidation && <Error meta={meta} error={error} />}
      </div>
    );
  }
}
