import React, { Component } from 'react';

import Page from 'src/components/Page/Page';
import UserOrgEditLicensePlan from 'src/pages/App/User/Organizations/Edit/UserOrgEditLicensePlan';

export default class Upgrade extends Component {
  render() {
    return (
      <Page title="Upgrade">
        <UserOrgEditLicensePlan />
      </Page>
    );
  }
}
