import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import grantApi from 'src/api/Grant';
import granteesApi from 'src/api/Grantees';
import { Table, Container, Progress } from 'src/components/IMUI/index';
import history from 'src/historyInstance';
import { getOrgText } from 'src/services/DictionaryService';
import { getCountryNameByCode } from 'src/utils/countries';
import { formatMoneyIM } from 'src/utils/currency';
import { pluralize, capitalize } from 'src/utils/string';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';
import DatesIndicator from './DatesIndicator';
import DrawerDetails from './DrawerDetails';
import { toLocaleDate } from 'src/utils/date';
import cls from './EntityTable.module.css';
import { canManageGrants } from 'src/userStorage';

const combineText = (txt1, txt2) => {
  if (txt1 && txt2) return `${txt1}, ${txt2}`;
  return txt1 || txt2;
};

@connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    grant: state.grant,
    grantees: state.granteesDetailed,
  }),
  { getGrant: grantApi.find, getGrantees: granteesApi.detailed.findAll }
)
export default class GrantPane extends React.PureComponent {
  static propTypes = {
    ...DrawerDetails.propTypes,
    urlParam: PropTypes.string,
    organizationCurrent: PropTypes.object,
    grant: PropTypes.object,
    getGrant: PropTypes.func.isRequired,
    getGrantees: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onEditFunding: PropTypes.func.isRequired,
    onEditGrantee: PropTypes.func.isRequired,
    onDeleteFunding: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.urlParam) return;
    this.props.getGrant(
      where({ id: this.props.urlParam }).include(
        'contacts',
        'locations',
        'fundings'
      )
    );
    this.props.getGrantees(
      where()
        .filter('grants_id_eq_any', [this.props.urlParam])
        .include('contacts')
        .paginate({ size: 1001 })
    );
  }
  handleEditGrant = () => {
    canManageGrants() && this.props.onEdit(this.props.grant.data);
  };
  handleEditGrantee = (grantee) => {
    canManageGrants() && this.props.onEditGrantee(grantee);
  };
  handleEditFunding = (funding) => {
    canManageGrants() &&
      this.props.onEditFunding(
        funding,
        this.props.grant.data,
        this.props.grantees.data.find((g) => g?.id == funding.grantee?.id) ||
          funding.grantee
      );
  };
  handleDeleteFunding = (funding) => {
    canManageGrants() && this.props.onDeleteFunding(funding);
  };
  handleDelete = () => {
    canManageGrants() && this.props.onDelete(this.props.grant.data);
  };
  handleExport = () => {
    this.props.onExport(this.props.grant.data.id);
  };

  renderSubList(arr, name) {
    if (!arr || !arr.length) return null;
    return (
      <DrawerDetails.Item
        name={name}
        value={
          <DrawerDetails.List>
            {(arr || []).map((el, index) => (
              <DrawerDetails.Item key={index} value={el} />
            ))}
          </DrawerDetails.List>
        }
      />
    );
  }

  renderDetails = () => {
    const { grant, organizationCurrent } = this.props;
    const fundingType = (grant.data.fundings || []).length
      ? grant.data.fundings[0].type
      : null;

    return (
      <DrawerDetails.List showEmptyPlaceholder>
        {!grant.data.number ? null : (
          <DrawerDetails.Item
            name={getOrgText('Grant Number')}
            value={grant.data.number}
          />
        )}
        {!fundingType ? null : (
          <DrawerDetails.Item name={'Type of Funding'} value={fundingType} />
        )}
        {!(grant.data.locations || []).length ? null : (
          <DrawerDetails.Item
            name={'Locations'}
            value={
              <DrawerDetails.List>
                {' '}
                {(grant.data.locations || []).map((l) => (
                  <DrawerDetails.Item
                    key={l.id}
                    value={combineText(
                      getCountryNameByCode(l.country),
                      capitalize(l.city, { doNotLowerCaseRest: true })
                    )}
                  />
                ))}{' '}
              </DrawerDetails.List>
            }
          />
        )}
        {!(grant.data.contacts || []).length ? null : (
          <DrawerDetails.Item
            name={'Contacts'}
            value={
              <DrawerDetails.List>
                {' '}
                {(grant.data.contacts || []).map((c) => (
                  <DrawerDetails.Item
                    key={c.id}
                    value={combineText(c.name, c.email)}
                  />
                ))}{' '}
              </DrawerDetails.List>
            }
          />
        )}
        {!grant.data.status ? null : (
          <DrawerDetails.Item name="Status" value={grant.data.status} />
        )}
        {!grant.data.description ? null : (
          <DrawerDetails.Item
            name="Description"
            value={grant.data.description}
          />
        )}

        {this.renderSubList(grant.data.portfolio_list, 'Portfolios')}
        {this.renderSubList(grant.data.donor_list, 'Donors')}
        {this.renderSubList(grant.data.strategies_list, 'Strategies')}
        {this.renderSubList(grant.data.cohort_list, 'Cohorts')}
        {this.renderSubList(grant.data.issue_list, 'Issues')}
        {this.renderSubList(grant.data.population_list, 'Populations of Focus')}
        {this.renderSubList(grant.data.area_of_work_list, 'Areas of Work')}
        {Object.keys(grant.data.custom_fields || {}).map(
          (cf) =>
            !grant.data.custom_fields[cf] ||
            (organizationCurrent.data?.custom_grant_fields?.includes(cf) && (
              <DrawerDetails.Item
                key={cf}
                name={cf}
                value={grant.data.custom_fields[cf]}
              />
            ))
        )}
      </DrawerDetails.List>
    );
  };

  renderSubTitle = () => {
    const fundingsCount = (this.props.grant.data.fundings || []).length;
    const granteesCount = (this.props.grantees.data || []).length;
    const locationsCount = (this.props.grant.data.locations || []).length;
    const contactsCount = (this.props.grant.data.contacts || []).length;
    const startDate = !fundingsCount
      ? null
      : this.props.grant.data.fundings[0].start_date;
    const endDate = !fundingsCount
      ? null
      : this.props.grant.data.fundings[0].end_date;

    return (
      <DrawerDetails.List>
        {startDate && endDate && (
          <DrawerDetails.SubTitleItem
            icon={<DatesIndicator startDate={startDate} endDate={endDate} />}
            text={`From ${toLocaleDate(startDate)} to ${toLocaleDate(endDate)}`}
          />
        )}
        {fundingsCount > 0 && (
          <DrawerDetails.SubTitleItem
            icon={<Icon name="currency" />}
            text={`${fundingsCount} ${pluralize('funding', fundingsCount)}`}
          />
        )}
        {granteesCount > 0 && (
          <DrawerDetails.SubTitleItem
            icon={<Icon name="grantee" />}
            text={getOrgText(
              `${granteesCount} ${pluralize('grantee', granteesCount)}`
            )}
          />
        )}
        {locationsCount > 0 && (
          <DrawerDetails.SubTitleItem
            icon={<Icon name="location" />}
            text={`${locationsCount} ${pluralize('location', locationsCount)}`}
          />
        )}
        {contactsCount > 0 && (
          <DrawerDetails.SubTitleItem
            icon={<Icon name="send" />}
            text={`${contactsCount} ${pluralize('contact', contactsCount)}`}
          />
        )}
      </DrawerDetails.List>
    );
  };

  renderContent = () => {
    const fundings = this.props.grant.data.fundings ?? [];
    const getFundingGrantee = (f) =>
      this.props.grantees.data.find((g) =>
        g.fundings.some((gf) => gf.id == f.id)
      );
    const quickLink = () =>
      history.push(`/grants/fundings/add/grant/${this.props.urlParam}/quick`);
    const normalLink = () =>
      history.push(`/grants/fundings/add/grant/${this.props.urlParam}`);
    return (
      <div>
        <DrawerDetails.Table
          title={getOrgText(
            `${this.props.grantees.data.length} ${pluralize(
              'grantee',
              this.props.grantees.data.length
            )}`
          )}
          head={
            <Table.Head transparent bold>
              <Table.HCell text={getOrgText('Grantee Name')} />
              <Table.HCell text="Location" />
              <Table.HCell width={42} />
              <Table.HCell
                width={42}
                textAlign="right"
                style={{ cursor: 'pointer' }}
                onClick={quickLink}
              >
                <Icon
                  tip={getOrgText('Link a Grantee')}
                  name="plus"
                  placeTip="left"
                />
              </Table.HCell>
            </Table.Head>
          }
          body={this.props.grantees.data.map((g) => (
            <Table.Row key={g.id}>
              <Table.Cell text={g.name} />
              <Table.Cell text={getCountryNameByCode(g.country_code)} />
              <Table.CellSettings onEdit={() => this.handleEditGrantee(g)} />
              <Table.Cell>
                <Container horizontal nowrap centered>
                  <Icon
                    name="trash"
                    tip={`To unlink this ${getOrgText(
                      'grantee'
                    )}, delete the associated funding below`}
                    placeTip="left"
                  />
                </Container>
              </Table.Cell>
            </Table.Row>
          ))}
          renderEmpty={() => (
            <DrawerDetails.Empty
              title={getOrgText('No grantees')}
              description={`Quick link to a ${getOrgText('grantee')}`}
              createButtonLabel={getOrgText('Link a Grantee')}
              onCreate={quickLink}
            />
          )}
        />

        <DrawerDetails.Table
          title={
            <Container horizontal className={cls.alignedBaseline}>
              <span>
                {fundings.length} {pluralize('funding', fundings.length)}
              </span>
            </Container>
          }
          head={
            <Table.Head transparent bold>
              <Table.HCell text="Date" />
              <Table.HCell text={getOrgText('Grantee Name')} />
              <Table.HCell text="Amount" />
              <Table.HCell
                width={42}
                textAlign="right"
                style={{ cursor: 'pointer' }}
                onClick={normalLink}
              >
                <Icon tip="Add Funding" name="plus" placeTip="left" />
              </Table.HCell>
            </Table.Head>
          }
          body={fundings.map((f) => (
            <Table.Row key={f.id}>
              <Table.Cell
                text={`${toLocaleDate(f.start_date)}\u00A0- ${toLocaleDate(
                  f.end_date
                )}`}
              />
              <Table.Cell text={getFundingGrantee(f)?.name || '---'} />
              <Table.Cell text={formatMoneyIM(f.amount, f.currency)} />
              <Table.CellSettings
                onEdit={() => this.handleEditFunding(f)}
                onDelete={() => this.handleDeleteFunding(f)}
              />
            </Table.Row>
          ))}
          renderEmpty={() => (
            <DrawerDetails.Empty
              title="No fundings"
              createButtonLabel="Add Funding"
              onCreate={() =>
                history.push(
                  `/grants/fundings/add/grant/${this.props.urlParam}`
                )
              }
            />
          )}
        />
      </div>
    );
  };

  render() {
    if (
      this.props.grant.pending.init ||
      this.props.grant.pending.init ||
      this.props.grantees.pending.init ||
      this.props.grantees.pending.ui
    )
      return <Progress />;
    return (
      <DrawerDetails
        urlParam={this.props.urlParam}
        onRequestClose={this.props.onRequestClose}
        renderTitle={() => (
          <DrawerDetails.Title
            iconName="grants"
            iconLabel={getOrgText('Grant')}
            title={this.props.grant.data.name}
          />
        )}
        renderSubTitle={this.renderSubTitle}
        renderContent={this.renderContent}
        renderDetails={this.renderDetails}
        onEdit={this.handleEditGrant}
        onExport={this.handleExport}
        onDelete={this.handleDelete}
      />
    );
  }
}
