import PropTypes from 'prop-types';
import cond from 'ramda/src/cond';
import React from 'react';
import { reduxForm } from 'redux-form';

import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import { Checkbox, Container, Actions, Button } from 'src/components/IMUI';

import cls from './Search.module.css';
import { canManageTag } from 'src/userStorage';

const SearchAndTagReview = ({
  quill,
  results,
  handleSubmit,
  onRequestClose,
  onItemChange,
}) => {
  const items = results.map((el) => {
    const offset = Math.max(30, (el.end - el.start) / 2);
    const quillText = quill?.getText() ?? '';
    return {
      before: el.start > 0 ? quillText.substr(el.start - offset, offset) : '',
      selected: quillText.substr(el.start, el.end - el.start),
      after: el.end < quillText.length ? quillText.substr(el.end, offset) : '',
      start: el.start,
      end: el.end,
      disabled: el.disabled,
    };
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      {items.map((el, i) => (
        <FlexRow key={i} className={cls.reviewItem}>
          <FlexColumn size={1 / 5}>
            <Checkbox
              onCheck={(event, isChecked) => {
                onItemChange(el, isChecked);
              }}
              checked={!el.disabled}
            />
          </FlexColumn>
          <FlexColumn size={4 / 5}>
            {el.before}{' '}
            <span className={cls.reviewSelected}>{el.selected}</span> {el.after}
          </FlexColumn>
        </FlexRow>
      ))}

      <br />
      <br />
      <Container horizontal>
        <Actions>
          <Button label="Cancel" negative onClick={onRequestClose} />
        </Actions>
        <Actions>
          <Button disabled={!canManageTag()} type="submit" label="Apply tags" />
        </Actions>
      </Container>
    </form>
  );
};

SearchAndTagReview.propTypes = {
  quill: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  onItemChange: PropTypes.func.isRequired,
};

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  return {
    text: cond([[not(isDefined), () => 'Search phrase is required']])(
      values.text
    ),
  };
}

export default reduxForm({
  form: 'searchAndTagReviewForm',
  validate,
  initialValues: {
    selection: null,
    tagId: null,
  },
})(SearchAndTagReview);
