import cond from 'ramda/src/cond';
import React, { useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  getFormValues,
  getFormSyncErrors,
  registerField,
} from 'redux-form';

import tagApi from 'src/api/Tag';
import {
  TextField,
  SelectField,
  Container,
  Actions,
  Button,
} from 'src/components/IMUI';
import { Error, Label } from 'src/components/IMUI/Forms/base';
import TagSelector from 'src/components/TagSelector/TagSelector';
import {
  REPORT__EXPAND_STRATEGY_WORD,
  REPORT__EXPAND_STRATEGY_SENTENCE,
  REPORT__EXPAND_STRATEGY_PARAGRAPH,
} from 'src/constants';
import { canManageTag } from 'src/userStorage';

import cls from './SearchAndTag.module.css';
const FORM_NAME = 'searchAndTagForm';

const SearchAndTag2 = ({
  handleSubmit,
  onRequestClose,
  getTag,
  change,
  registerFormField,
  tag,
  submitFailed,
  formValues = {},
  formErrors = {},
}) => {
  const formRef = useRef();
  useEffect(() => {
    registerFormField(FORM_NAME, 'tagId', 'Field');
  }, [registerFormField]);

  const handleTagChange = useCallback(
    (tagId) => {
      change('tagId', tagId);
      getTag({ id: tagId });
    },
    [getTag, change]
  );

  return (
    <form onSubmit={handleSubmit} noValidate ref={formRef}>
      <Field
        name="text"
        label="Search for"
        component={TextField}
        hintText="enter phrase..."
      />
      <br />
      <Field
        label="When found"
        noValidation
        name="selection"
        component={SelectField}
      >
        <SelectField.Item
          key={'selection-expand-to-word'}
          value={REPORT__EXPAND_STRATEGY_WORD}
          primaryText="Expand selection to word"
        />
        <SelectField.Item
          key={'selection-expand-to-sentence'}
          value={REPORT__EXPAND_STRATEGY_SENTENCE}
          primaryText="Expand selection to sentence"
        />
        <SelectField.Item
          key={'selection-expand-to-paragraph'}
          value={REPORT__EXPAND_STRATEGY_PARAGRAPH}
          primaryText="Expand selection to paragraph"
        />
      </Field>
      <br />
      <div>
        <Label label="And tag it with" />
        <TagSelector
          popoverClassName={cls.tagSelectorPopover}
          allowCreate={false}
          anchorEl={formRef.current}
          zIndex={1501}
          noTagProps={{ label: 'Choose a tag' }}
          tagsWithTagGroups={[tag.data]}
          selected={formValues.tagId}
          onChange={(tagIds) => handleTagChange(tagIds)}
        />
        <Error error={(submitFailed && formErrors.tagId) || ''} />
      </div>

      <br />
      <Container horizontal>
        <Actions>
          <Button label="Cancel" negative onClick={onRequestClose} />
        </Actions>
        <Actions>
          <Button
            disabled={!canManageTag()}
            type="submit"
            label="Review matches"
          />
        </Actions>
      </Container>
    </form>
  );
};

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  return {
    text: cond([[not(isDefined), () => 'Search phrase is required']])(
      values.text
    ),
    tagId: cond([[not(isDefined), () => 'Tag is required']])(values.tagId),
  };
}

function mapStateToProps(state) {
  return {
    formValues: getFormValues(FORM_NAME)(state),
    formErrors: getFormSyncErrors(FORM_NAME)(state),
    tag: state.tag,
  };
}

export default connect(mapStateToProps, {
  getTag: tagApi.find,
  registerFormField: registerField,
})(
  reduxForm({
    form: FORM_NAME,
    validate,
    initialValues: {
      selection: REPORT__EXPAND_STRATEGY_WORD,
      tagId: null,
    },
  })(SearchAndTag2)
);
