import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import tagCategoriesApi from 'src/api/TagCategories';
import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import tagCategoriesSelectorExpandedApi from 'src/api/TagCategoriesSelectorExpanded';
import aiTaggingRequestsApi from 'src/api/AITaggingRequests';
import aiTaggingResponsesApi from 'src/api/AITaggingResponses';
import AITaggingSuggestions from './AITaggingSuggestions';
import CreateTag from 'src/components/CreateTag/CreateTag';
import EditTag from 'src/components/CreateTag/EditTag';
import EditTagCategory from 'src/components/EditTagCategory/EditTagCategory';
import {
  Container,
  Card,
  CardFooter,
  Divider,
  PopoverLight,
} from 'src/components/IMUI';
import TagGroupListHeader from 'src/components/TagList/TagGroupListHeader';

import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import Search2 from './Search2';
import Settings from './Settings';
import TagList from './TagList';

import cls from './TagEditorSidebar.module.css';
import classNames from 'classnames/bind';
const cx = classNames.bind(cls);

import { canAiTagging, canManageTag } from 'src/userStorage';
import { COLORING_STRATEGIES_READABLE } from './coloringStrategy';

const MAX_ATTEMPTS = 15;
const POLLING_INTERVAL = 5000;

@connect(
  (state) => ({
    tagCategories: state.tagCategories?.data || [],
  }),
  {
    showNotification,
    getTagCategories: tagCategoriesApi.findAll,
    getTagSelectorTagCategories:
      tagCategoriesSelectorApi.findAllPerProjectNested,
    getTagCategoriesExpanded:
      tagCategoriesSelectorExpandedApi.findAllPerProjectNested,
    getTaggingSuggestions: aiTaggingResponsesApi.findAll,
    createAITaggingRequest: aiTaggingRequestsApi.create,
  }
)
export default class TagEditorSidebar2 extends React.PureComponent {
  static propTypes = {
    taggings: PropTypes.object,
    coloringStrategy: PropTypes.string,
    tagsInContext: PropTypes.array,
    scrollToTagging: PropTypes.func,
    pdfHighlighterRef: PropTypes.object,
    selectedText: PropTypes.string,
    setSelection: PropTypes.func,
    project: PropTypes.object,
    report: PropTypes.object,
    addTagging: PropTypes.func.isRequired,
    removeTagging: PropTypes.func.isRequired,
    changeColoringStrategy: PropTypes.func.isRequired,
    ocrView: PropTypes.bool,
    onDoneEditing: PropTypes.func,
    onUndoneEditing: PropTypes.func,
    onTaggingHighlight: PropTypes.func,
    showTaggingSuggestions: PropTypes.bool,
    taggingSuggestions: PropTypes.array,
    onApproveTaggingSuggestion: PropTypes.func.isRequired,
    onDeclineTaggingSuggestion: PropTypes.func.isRequired,
    onEditTaggingSuggestion: PropTypes.func.isRequired,
    onToggleOcrView: PropTypes.func,
    onViewTaggingSuggestionsToggle: PropTypes.func.isRequired,

    tagCategories: PropTypes.array,
    tagsRecent: PropTypes.array,
    getTagCategories: PropTypes.func.isRequired,
    getTagSelectorTagCategories: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    getTagCategoriesExpanded: PropTypes.func.isRequired,
    createAITaggingRequest: PropTypes.func.isRequired,
    getTaggingSuggestions: PropTypes.func.isRequired,
  };

  state = {
    createTagVisible: false,
    settingsVisible: false,
    showDefinitions: false,
    tagCategoryFilterText: '',
    tagCategorySelectedId: null,
    popoverOpen: false,
    taggingSuggestionPollAttemptCount: 0,
    taggingSuggestionIntervalId: null,
    initialTaggingSuggestionsCount: this.props.taggingSuggestions.length,
  };

  componentDidMount() {
    if (
      this.props.project.id &&
      (this.props.project.enabled_tag_categories || []).length
    ) {
      this.doRequestTagCategories(this.props.project.enabled_tag_categories);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.project.enabled_tag_categories || []).length &&
      nextProps.project.enabled_tag_categories !==
        this.props.project.enabled_tag_categories
    ) {
      this.doRequestTagCategories(nextProps.project.enabled_tag_categories);
    }
  }

  checkTaggingSuggestions = (init = false) => {
    this.props
      .getTaggingSuggestions(
        where({ taggable_id: this.props.report.reportUid })
          .include('tag', 'report', 'tag.tag_categories')
          .filter('tagging_request_project_report_id_eq', this.props.report.id)
          .paginate({ size: 1000 })
      )
      .then((response) => {
        if (
          this.state.taggingSuggestionPollAttemptCount >= MAX_ATTEMPTS &&
          this.state.taggingSuggestionIntervalId
        ) {
          // Waited too long
          this.props.showNotification({
            title: 'AI Tagging - no tagging suggestions found.',
            level: 'warning',
            message:
              'The AI tool did not find any new relevant sections to highlight.',
          });
          clearInterval(this.state.taggingSuggestionIntervalId);
          this.setState({
            initialTaggingSuggestionsCount: response.data.length,
            taggingSuggestionIntervalId: null,
            taggingSuggestionPollAttemptCount: 0,
          });
        } else if (
          this.state.initialTaggingSuggestionsCount !== response.data.length &&
          this.state.taggingSuggestionIntervalId
        ) {
          // New tagging suggestions appear
          const newTaggingSuggestionCount =
            response.data.length - this.state.initialTaggingSuggestionsCount;

          this.props.showNotification({
            title: 'AI Tagging - New tagging suggestions',
            message: `${newTaggingSuggestionCount} new tagging suggestions found.`,
          });
          clearInterval(this.state.taggingSuggestionIntervalId);
          this.setState({
            initialTaggingSuggestionsCount: response.data.length,
            taggingSuggestionIntervalId: null,
            taggingSuggestionPollAttemptCount: 0,
          });
        } else {
          // Try again
          this.setState({
            taggingSuggestionPollAttemptCount:
              this.state.taggingSuggestionPollAttemptCount + 1,
          });
        }
      });
  };

  expandedTagCategories = [];

  editExpandedTagCategories = (e) => {
    this.expandedTagCategories = e;
  };
  handleTagListSearch = (tagCategoryFilterText) => {
    this.setState({ tagCategoryFilterText });
  };
  handleCategorySelect = (tagCategorySelectedId) => {
    this.setState({ tagCategorySelectedId });
  };
  handleDisplaySettings = () => {
    this.setState({ settingsVisible: true, popoverOpen: false });
  };
  handleDownloadFile = () => {
    const a = document.createElement('a');
    a.href = this.props.report.original_asset_url;
    a.download = this.props.report.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  handleDisplayTagListPopover = (ev) => {
    ev?.preventDefault();
    this.setState({ popoverOpen: true });
  };

  handleAITaggingRequestClick = () => {
    this.props.createAITaggingRequest(
      where().payload({
        project_report_id: this.props.report.id,
      })
    );
    this.props.showNotification({
      title: 'Report queued for analysis',
      message: 'Our AI tool will analyze your report for tagging suggestions.',
    });

    const intervalId = setInterval(
      this.checkTaggingSuggestions,
      POLLING_INTERVAL
    );
    if (this.state.taggingSuggestionIntervalId) {
      clearInterval(this.state.taggingSuggestionIntervalId);
    }
    this.setState({ taggingSuggestionIntervalId: intervalId });
  };

  displayCreateTag = () => {
    this.setState({ createTagVisible: true, popoverOpen: false });
  };
  closeDialogs = () => {
    this.setState({
      tagToEdit: null,
      tagGroupToEdit: null,
      createTagVisible: false,
      settingsVisible: false,
      popoverOpen: false,
    });
  };
  doRequestTagCategories = (projectTagCategoryIds, { searchText } = {}) => {
    if (!projectTagCategoryIds?.length) return;
    const query = where()
      .include('nested_child_categories')
      .filter('id_eq_any', projectTagCategoryIds)
      .fields('tag_category', 'title')
      .filter('parent_id_null', true)
      .sort('title')
      .paginate({ size: 600 });

    if (searchText) {
      query.filter('title_or_nested_categories_title_in_any', searchText);
    }
    this.props.getTagCategories(query);
  };

  handleCategoriesFilter = (searchText) => {
    this.doRequestTagCategories(this.props.project.enabled_tag_categories, {
      searchText,
    });
  };
  startEditTag = (tag) => {
    this.setState({ tagToEdit: tag });
  };
  startEditTagGroup = (tagGroup) => {
    this.setState({ tagGroupToEdit: tagGroup });
  };

  addTagging = (tag) => {
    this.props.selectedText?.length
      ? canManageTag() &&
        this.props.addTagging({
          tag,
        })
      : this.props.showNotification({
          title: 'Tagging error',
          message: 'Please select text you want to tag first',
          level: 'error',
          autoDismiss: 10,
        });
  };

  handleToggleShowDefinitions = () => {
    this.setState({ showDefinitions: !this.state.showDefinitions });
  };

  handleReloadData = (shouldReload) => {
    // We need to make sure that tagCategories change is reflected into tagSelectorState
    const query = where({
      tagCategoriesExpandedIds: this.expandedTagCategories,
    });
    this.props.getTagCategoriesExpanded(query);
    this.props.getTagSelectorTagCategories();
    shouldReload && this.props.onReloadData?.();
  };

  render() {
    const {
      taggings,
      tagsInContext,
      coloringStrategy,
      tagsRecent,
      tagCategories,
    } = this.props;
    const colorStrategyLabel =
      this.props.coloringStrategy == 'TAG_GROUPS_UNIQUE'
        ? COLORING_STRATEGIES_READABLE.SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER
        : COLORING_STRATEGIES_READABLE.TAG_GROUPS_UNIQUE;
    const handleColoringStrategy = (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      this.props.changeColoringStrategy({
        value:
          this.props.coloringStrategy == 'TAG_GROUPS_UNIQUE'
            ? 'SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER'
            : 'TAG_GROUPS_UNIQUE',
      });
      this.closeDialogs();
    };

    return (
      <Card noPadding className={cls.tagEditorSidebar}>
        <div ref={(ref) => (this.innerRef = ref)}>
          {this.state.settingsVisible && (
            <Settings
              open={this.state.settingsVisible}
              showDefinitions={this.state.showDefinitions}
              coloringStrategy={this.props.coloringStrategy}
              onChangeColoringStrategy={this.props.changeColoringStrategy}
              onRequestClose={this.closeDialogs}
              onToggleShowDefinitions={this.handleToggleShowDefinitions}
            />
          )}
          {!!this.state.tagToEdit && (
            <EditTag
              noSync
              open={!!this.state.tagToEdit}
              tagId={this.state.tagToEdit.id}
              onRequestClose={this.closeDialogs}
              onSuccessfulEdit={this.handleReloadData}
            />
          )}
          {!!this.state.tagGroupToEdit && (
            <EditTagCategory
              noSync
              open={!!this.state.tagGroupToEdit}
              categoryId={this.state.tagGroupToEdit.id}
              onRequestClose={this.closeDialogs}
              onSuccessfulEdit={this.handleReloadData}
            />
          )}
          {this.state.createTagVisible && (
            <CreateTag
              noSync
              open={this.state.createTagVisible}
              onRequestClose={this.closeDialogs}
              onSuccessfulAdd={this.handleReloadData}
            />
          )}
        </div>

        <TagGroupListHeader
          tagCategorySelectedId={this.state.tagCategorySelectedId}
          tagCategoryFilterText={this.state.tagCategoryFilterText}
          allTagCategories={tagCategories}
          onSearch={this.handleTagListSearch}
          onCategorySelect={this.handleCategorySelect}
          onCategoriesFilter={this.handleCategoriesFilter}
          onSettingsClick={this.handleDisplayTagListPopover}
          onAITaggingRequestClick={
            this.state.taggingSuggestionIntervalId ||
            !this.props.report.ocr_document_url
              ? null
              : this.handleAITaggingRequestClick
          }
          onMarkAsDoneClick={this.props.onDoneEditing}
          onMarkAsUndoneClick={this.props.onUndoneEditing}
        />

        <Container grow className={cls.sidebarTagsSection}>
          <TagList
            coloringStrategy={coloringStrategy}
            filterText={this.state.tagCategoryFilterText}
            tagCategorySelectedId={this.state.tagCategorySelectedId}
            tagCategories={tagCategories}
            taggings={taggings}
            tagsInContext={tagsInContext}
            tagsRecent={tagsRecent}
            showDefinitions={this.state.showDefinitions}
            onTaggingAdd={this.addTagging}
            onTaggingRemove={this.props.removeTagging}
            onTagEdit={this.startEditTag}
            onTagGroupEdit={this.startEditTagGroup}
            onTaggingHighlight={this.props.onTaggingHighlight}
            editExpandedTagCategories={this.editExpandedTagCategories}
          />
        </Container>

        <Divider className={cls.dividerPetite} />
        <Container className={cls.sidebarSection}>
          <Search2
            setSelection={this.props.setSelection}
            scrollToTagging={this.props.scrollToTagging}
            pdfHighlighterRef={this.props.pdfHighlighterRef}
          />
        </Container>

        {canAiTagging() && canManageTag() && (
          <CardFooter className={cls.sidebarFooter}>
            <AITaggingSuggestions
              taggingSuggestions={this.props.taggingSuggestions}
              onViewTaggingSuggestionsToggle={
                this.props.onViewTaggingSuggestionsToggle
              }
              onApproveTaggingSuggestion={this.props.onApproveTaggingSuggestion}
              onDeclineTaggingSuggestion={this.props.onDeclineTaggingSuggestion}
              onEditTaggingSuggestion={this.props.onEditTaggingSuggestion}
              showTaggingSuggestions={this.props.showTaggingSuggestions}
            />
          </CardFooter>
        )}

        <PopoverLight
          anchorEl={this.innerRef}
          open={this.state.popoverOpen}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.closeDialogs}
        >
          <PopoverLight.Menu>
            <PopoverLight.MenuItem
              leftIcon={<Icon name="settings" />}
              primaryText="Settings"
              onClick={this.handleDisplaySettings}
            />
            <PopoverLight.MenuItem
              primaryText={colorStrategyLabel}
              leftIcon={<Icon name="text-field" />}
              onClick={handleColoringStrategy}
            />
            <PopoverLight.MenuItem
              primaryText={
                this.state.showDefinitions
                  ? 'Hide Tag Definitions'
                  : 'Show Tag Definitions'
              }
              leftIcon={<Icon name="tag" />}
              onClick={this.handleToggleShowDefinitions}
            />
            <PopoverLight.Divider small compact />
            {canManageTag() && (
              <PopoverLight.MenuItem
                primaryText="Create tag"
                leftIcon={<Icon name="tag" />}
                onClick={this.displayCreateTag}
              />
            )}
            {canManageTag() && this.props.onDoneEditing && (
              <PopoverLight.MenuItem
                primaryText="Mark as tagged"
                leftIcon={<Icon name="check" />}
                onClick={this.props.onDoneEditing}
              />
            )}
            {canManageTag() && this.props.onUndoneEditing && (
              <PopoverLight.MenuItem
                primaryText="Mark as untagged"
                leftIcon={<Icon name="close" />}
                onClick={this.props.onUndoneEditing}
              />
            )}
            <PopoverLight.Divider small compact />
            <PopoverLight.MenuItem
              primaryText={
                this.props.onToggleOcrView
                  ? this.props.ocrView
                    ? 'View Original File'
                    : 'View OCR Document'
                  : 'OCR in progress...'
              }
              leftIcon={<Icon name="explore" />}
              className={cx({ ocrViewDisabled: !this.props.onToggleOcrView })}
              onClick={this.props.onToggleOcrView}
            />
            <PopoverLight.Divider small compact />
            <PopoverLight.MenuItem
              leftIcon={<Icon name="download" />}
              primaryText="Download Original File"
              onClick={this.handleDownloadFile}
            />
          </PopoverLight.Menu>
        </PopoverLight>
      </Card>
    );
  }
}
