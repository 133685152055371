import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import taggingsApi from 'src/api/Taggings';
import { where } from 'im/api/Query';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import {
  Actions,
  Button,
  Card,
  Container,
  CardFooter,
  Dialog,
  Section,
} from 'src/components/IMUI';
import EditorMinimap from 'src/pages/App/Analysis/TagEditor/components/EditorMinimap';
import QuotesList from 'src/pages/App/Analysis/TagEditor/components/Quotes';
import TaggingDetailsSelector from 'src/pages/App/Analysis/TagEditor/components/TaggingDetailsSelector';
import TaggingInContext from 'src/pages/App/Analysis/TagEditor/components/TaggingInContext';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import SurveyTagEditorSidebar from 'src/pages/App/Analysis/TagEditor/components/SurveyTagEditorSidebar';
import { NO_ANSWER } from 'src/pages/App/Analysis/Surveys/Survey/constants';
import {
  applyColoringStrategy,
  COLORING_STRATEGIES,
} from 'src/pages/App/Analysis/TagEditor/components/coloringStrategy';
import StorageService from 'src/services/Storage';

import classNames from 'classnames/bind';
import cls from 'src/pages/App/Analysis/TagEditor/SurveyTagEditor.module.css';
const cx = classNames.bind(cls);

import {
  getAutoSuggestedTaggingDetails,
  isNumeric,
  getNumberFromText,
} from 'src/components/TaggingType/taggingTypeHelper';
import { tagsToHighlights } from 'src/utils/tagsToHighlights';

import Quill from 'quill';

const BlockBlot = Quill.import('blots/block');
BlockBlot.tagName = 'div';
Quill.register(BlockBlot, true);

const SurveyTagEditor = ({
  project,
  organizationCurrent,
  user,
  createTagging,
  getTaggings,
  updateTagging,
  deleteTagging,
  answer,
  taggings,
  open,
  handleClose,
}) => {
  const quillRef = useRef();
  const selectionRef = useRef();
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const [selectedText, setSelectedText] = useState();
  const [tagsInContext, setTagsInContext] = useState([]);
  const [quill, setQuill] = useState();
  const [marks, setMarks] = useState([]);
  const [coloringStrategy, setColoringStrategy] = useState(
    COLORING_STRATEGIES.SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER
  );
  const [taggingDetailsEditing, setTaggingDetailsEditing] = useState(null);

  const retrieveAnswerValue = (currentAnswer) => {
    if (currentAnswer.response?.simple_value?.length > 0)
      return currentAnswer.response?.simple_value;
    if (currentAnswer.response?.other_value?.length > 0)
      return currentAnswer.response?.other_value;
    if (Array.isArray(currentAnswer.value))
      return currentAnswer.value.join(', ');
    if (typeof currentAnswer.value == 'string') return currentAnswer.value;
    if (typeof currentAnswer.value == 'number') return currentAnswer.value;
    if (typeof currentAnswer.value == 'object') return '-';
    return NO_ANSWER;
  };

  const applyHighlights = useCallback(
    (currentTaggings, yellowTaggings) => {
      if (!quill?.container?.textContent) {
        return;
      }

      tagsToHighlights(currentTaggings).forEach((h) =>
        quill?.formatText(h.startPosition, h.endPosition - h.startPosition, {
          background: applyColoringStrategy(
            coloringStrategy,
            h.tags,
            currentTaggings,
            yellowTaggings
          ),
        })
      );
    },
    [quill, coloringStrategy]
  );

  const setSelection = useCallback(
    ({
      startPosition,
      endPosition,
      _cursor = undefined,
      scrollToSelection = false,
    }) => {
      const a = Number(startPosition || 0);
      const z = Number(endPosition || 0);
      const canFocus = z - a > 0;
      const allSame = endPosition == z && selectionStart == a;

      if (!allSame) {
        setSelectionStart(a);
        setSelectionEnd(z);

        quill?.setSelection(startPosition, endPosition - startPosition);
        applyHighlights(taggings.data, tagsInContext?.map((i) => i.id) ?? []);
      }

      quill?.setSelection(startPosition, endPosition - startPosition);
      canFocus ? scrollToSelection && quill?.focus() : quill?.blur();
    },
    [quill, applyHighlights, selectionStart, taggings.data, tagsInContext]
  );

  const taggingAlreadyExists = (newT, currentTaggings) => {
    return currentTaggings.some(
      (curr) =>
        curr.tag?.id == newT.tag?.id &&
        curr.report_content_end === newT.end &&
        curr.report_content_start === newT.start
    );
  };

  const createSingleTagging = async (currentTagging) => {
    if (!currentTagging?.tag) {
      showNotification({
        title: 'Adding taggings',
        message: 'No tags were selected to tag the content with',
        level: 'warning',
        autoDismiss: 10,
      });
      return Promise.reject();
    }
    if (taggingAlreadyExists(currentTagging, taggings.data)) {
      showNotification({
        title: 'Adding taggings',
        message:
          "You can't apply the same tag to the same part of the content twice",
        level: 'warning',
      });

      setSelection({
        startPosition: currentTagging?.report_content_start,
        endPosition: currentTagging?.report_content_end,
        cursor: 0,
        scrollToSelection: true,
      });
      return Promise.reject();
    }

    const taggingContent = quill?.getText(
      currentTagging.start || 0,
      currentTagging.end - currentTagging.start
    );

    const baseAttributes = {
      report_content_start: currentTagging.start,
      report_content_end: currentTagging.end,
      content: taggingContent,
      type: 'taggings',
    };

    const attributes = isNumeric(taggingContent)
      ? {
          ...baseAttributes,
          ...getNumberFromText(taggingContent),
        }
      : baseAttributes;

    return createTagging(
      where().payload({
        attributes: attributes,
        relationships: [
          { relName: 'tag', type: 'tags', id: currentTagging.tag?.id },
          {
            relName: 'taggable',
            type: 'survey_answers',
            id: answer.uid,
          },
          { relName: 'user', type: 'users', id: user.data.id },
          {
            relName: 'organization',
            type: 'organizations',
            id: organizationCurrent.data.id,
          },
          {
            relName: 'project',
            type: 'projects',
            id: project.uid,
          },
        ],
      })
    );
  };

  const removeHighlights = useCallback(
    (currentTaggings) => {
      tagsToHighlights(currentTaggings).forEach((h) =>
        quill?.removeFormat(
          h.startPosition,
          h.endPosition - h.startPosition,
          'silent'
        )
      );
    },
    [quill]
  );

  const onDetailsClose = () => {
    setTaggingDetailsEditing(null);
  };

  const handleEditTagging = useCallback(
    (tagging, taggingDetails) => {
      updateTagging(
        where({ id: tagging.id }).payload({
          attributes: {
            quantity: null,
            amount: null,
            currency: null,
            year: null,
            month: null,
            day: null,
            location: null,
            content: tagging.content,
            ...taggingDetails,
          },
          relationships: [
            {
              relName: 'taggable',
              type: 'survey_answers',
              id: answer.uid,
            },
            { relName: 'project', type: 'projects', id: project.uid },
          ],
        })
      );
      onDetailsClose();
    },
    [answer.uid, updateTagging, project.uid]
  );

  const refreshQuillMarks = useCallback(() => {
    setMarks(
      Array.from(quill?.scrollingContainer?.querySelectorAll('[style]')).map(
        (el) => ({
          offsetTop: el.offsetTop,
          color: el.style.backgroundColor,
        })
      ) || []
    );
  }, [quill]);

  const updateHighlight = useCallback(
    (range) => {
      if (selectionRef.current?.length)
        quill?.removeFormat(
          selectionRef.current?.index,
          selectionRef.current?.length
        );
      const currRange = range ?? selectionRef.current;

      if (currRange?.length > 0) {
        quill?.formatText(currRange?.index, currRange?.length, {
          background: StorageService.getItem('--highlight-color') ?? '#c1fa8b',
        });
      }

      selectionRef.current = currRange;
    },
    [quill]
  );

  const handleSelectionChange = useCallback(
    (_range) => {
      updateHighlight(_range);

      const range = _range || {
        index: selectionStart || 0,
        length: selectionEnd - (selectionStart || 0),
      };

      const hasHighlight = range.length > 0;
      const currentSelectionStart = hasHighlight ? range.index : 0;
      const currentSelectionEnd = hasHighlight ? range.index + range.length : 0;
      const currentSelectedText = hasHighlight
        ? quill?.getText(currentSelectionStart, range.length)
        : null;

      const currentTagsInContext = hasHighlight
        ? taggings?.data.filter(
            (el) =>
              currentSelectionStart === el.report_content_start &&
              currentSelectionEnd === el.report_content_end
          )
        : taggings?.data.filter(
            (el) =>
              range.index >= el.report_content_start &&
              range.index <= el.report_content_end
          );

      setTagsInContext(currentTagsInContext);
      setSelectedText(currentSelectedText);
      setSelectionStart(currentSelectionStart);
      setSelectionEnd(currentSelectionEnd);

      if (!hasHighlight) {
        applyHighlights(
          taggings.data,
          currentTagsInContext.map((i) => i.id) ?? []
        );
      }
    },
    [
      quill,
      updateHighlight,
      applyHighlights,
      taggings.data,
      selectionStart,
      selectionEnd,
      tagsInContext,
    ]
  );

  const bindQuillEvents = useCallback(() => {
    if (!quill?.container?.textContent) {
      return;
    }

    quill?.on('selection-change', handleSelectionChange);

    return () => {
      quill?.off('selection-change', handleSelectionChange);
    };
  }, [quill, handleSelectionChange]);

  const refreshUi = useCallback(() => {
    if (!quill?.container?.textContent) return;

    if (tagsInContext.length == 1) {
      removeHighlights(
        taggings.data.filter(
          (ot) => !taggings.data.map(({ id }) => id).includes(ot.id)
        )
      );
    }

    applyHighlights(taggings.data);

    refreshQuillMarks();
  }, [
    quill,
    applyHighlights,
    removeHighlights,
    refreshQuillMarks,
    taggings?.data,
    tagsInContext?.length,
  ]);

  const onTaggingEdit = (tagging) => {
    setTaggingDetailsEditing(tagging);
  };

  const doRequestTaggings = useCallback(
    (isInit) => {
      if (!project.uid || !open) return;

      getTaggings(
        where({ taggable_id: answer.uid, taggable_type: 'SurveyAnswer' })
          .include('tag', 'tag.tag_categories')
          .filter('project_id_eq', project.uid)
          .filter('scope', 'content')
          .paginate({ size: 3000 })
          .pending(isInit ? 'init' : '')
      );
    },
    [getTaggings, project.uid, answer, open]
  );

  const onAddTagging = async (_newTaggings) => {
    const newTaggings = (
      Array.isArray(_newTaggings) ? _newTaggings : [_newTaggings]
    ).filter(Boolean);
    if (!newTaggings?.length) return;

    try {
      const createdTaggings = await Promise.all(
        newTaggings.map(createSingleTagging)
      );
      if (!createdTaggings?.length) return;

      const firstTagging = createdTaggings[0];
      const autoSuggestion = getAutoSuggestedTaggingDetails(
        firstTagging.data.content
      );
      if (autoSuggestion) {
        onTaggingEdit({ ...firstTagging.data, ...autoSuggestion });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onTaggingRemove = async (currentTagging) =>
    currentTagging
      ? deleteTagging(where({ id: currentTagging.id })).then(() => {
          setSelection({ startPosition: 0, endPosition: 0 });
          removeHighlights([currentTagging]);
        })
      : showNotification({
          title: 'Removing taggings',
          message:
            'You have to first find in text the tagging you want to remove',
          level: 'warning',
          autoDismiss: 10,
        });

  const handleScrollTo = (offsetTop, restore) => {
    quill?.scrollingContainer?.scrollTo(0, offsetTop - (restore ? 0 : 0));
  };

  const resetDocument = (initial = true) => {
    const contentHtml = retrieveAnswerValue(answer);

    if (quill?.root?.parentElement) {
      const parent = quill.root.parentElement;
      parent.removeChild(quill.root);

      const mappedDeltas = quill.clipboard.convert(
        contentHtml
          ?.toString()
          ?.replaceAll('<p><hr></p>', '\r\n')
          .replaceAll('\n\n\n', '\n')
      );
      quill.setContents(mappedDeltas);

      parent.appendChild(quill.root);
    }
  };

  const onChangeColoringStrategy = (payload) => {
    setColoringStrategy(payload?.value);
    resetDocument();
  };

  const onTagHover = (id) =>
    applyHighlights(
      taggings.data.filter(({ tag }) => tag?.id == id),
      [id]
    );
  const onTagHoverOut = (id) =>
    applyHighlights(taggings.data.filter(({ tag }) => tag?.id == id));

  const initQuill = () => {
    setQuill(
      new Quill(`.${cls.editor}`, {
        formats: [
          'bold',
          'background',
          'color',
          'font',
          'code',
          'italic',
          'link',
          'size',
          'strike',
          'underline',
          'blockquote',
          'header',
          'indent',
          'list',
          'direction',
        ],
        modules: { toolbar: false, history: { userOnly: true } },
        readOnly: true,
      })
    );
  };

  useEffect(() => {
    doRequestTaggings();
    setTimeout(initQuill, 400);
  }, [open, doRequestTaggings]);

  useEffect(resetDocument, [quill, answer]);
  useEffect(bindQuillEvents, [bindQuillEvents]);
  useEffect(refreshUi, [quill, refreshUi]);

  const handleReloadData = () => {
    {
      doRequestTaggings(false);
    }
  };

  const hasSelectedHighlight = selectedText?.length > 0;
  const hasSelectedTaggings = tagsInContext?.length > 0;
  const markKey = `${String(quill && Boolean(quillRef))}${marks?.length}`;

  return (
    <Dialog
      key={`h${window.innerHeight}w${window.innerWidth}`}
      extraLarge
      contentStyle={{
        width: window.innerWidth - 80,
        minWidth: window.innerWidth - 80,
        position: 'fixed',
        transform: 'unset',
        top: 40,
        left: 0,
        right: 0,
      }}
      modal={true} //prevent clos
      open={open}
      onRequestClose={handleClose}
      title=""
    >
      <Section horizontal grow className={cx(cls.tagEditorCardWrapper)}>
        <FlexColumn
          style={{
            position: 'relative',
            paddingRight: 16,
            height: 'calc(100vh - 300px)',
          }}
          size={2 / 3}
        >
          <Card className={cls.tagEditorCard} noPadding grow>
            <Container grow className={cx(cls.editorArea, cls.surveyTagging)}>
              <div className={cls.editorWrapper}>
                <pre className={cx(cls.editor)} ref={quillRef} />
                {taggingDetailsEditing?.id && (
                  <TaggingDetailsSelector
                    open
                    isEdit={true}
                    tagging={taggingDetailsEditing}
                    position={{
                      top: quill?.getBounds(selectionEnd)?.bottom,
                      left: quill?.getBounds(selectionEnd)?.left,
                    }}
                    onSubmit={taggingDetailsEditing?.id && handleEditTagging}
                    onRequestClose={onDetailsClose}
                    onTaggingRemove={onTaggingRemove}
                  />
                )}
              </div>
              <EditorMinimap
                key={markKey}
                editorScrollHeight={
                  window.quill?.scrollingContainer?.scrollHeight ?? 0
                }
                onScrollTo={(pos) => handleScrollTo(pos, false)}
                marks={marks}
              />
            </Container>

            {hasSelectedTaggings && (
              <section className={cls.tagsInContext}>
                <Container horizontal style={{ gap: 3 }}>
                  <label style={{ lineHeight: '18px' }}>Tagged with:</label>
                  {tagsInContext.map((t) => (
                    <TaggingInContext
                      key={t.id}
                      tagging={t}
                      onTaggingRemove={onTaggingRemove}
                      onTaggingEdit={onTaggingEdit}
                    />
                  ))}
                </Container>
              </section>
            )}
            {hasSelectedHighlight && (
              <section className={cls.tagsInContext}>
                <small>
                  <label style={{ fontStyle: 'normal' }}>
                    Selected text:&nbsp;
                  </label>
                  {selectedText?.length > 100
                    ? `${String(selectedText).substring(0, 100)}...`
                    : selectedText}
                </small>
              </section>
            )}
            <CardFooter key={answer.id} className={cls.tagEditorFooter}>
              <Container horizontal spaceBetween>
                {answer.id && (
                  <span>
                    <QuotesList
                      key={answer.id}
                      taggableType="survey_answers"
                      type="footer"
                      taggableUid={answer.uid}
                      selectedText={selectedText}
                      selectionStart={selectionStart}
                      selectionEnd={selectionEnd}
                      setSelection={setSelection}
                    />
                  </span>
                )}
              </Container>
            </CardFooter>
          </Card>
        </FlexColumn>
        <FlexColumn
          style={{
            padding: 0,
            position: 'relative',
            height: 'calc(100vh - 300px)',
            minWidth: 286,
          }}
          size={1 / 3}
        >
          <SurveyTagEditorSidebar
            project={project}
            quill={quill}
            selectionStart={selectionStart}
            selectionEnd={selectionEnd}
            selectedText={selectedText}
            taggings={taggings}
            tagsInContext={tagsInContext}
            setSelection={setSelection}
            addTagging={onAddTagging}
            onTagHover={onTagHover}
            onTagHoverOut={onTagHoverOut}
            removeTagging={onTaggingRemove}
            changeColoringStrategy={onChangeColoringStrategy}
            coloringStrategy={coloringStrategy}
            onReloadData={handleReloadData}
          />
        </FlexColumn>
      </Section>
      <Actions grow>
        <Button size="l" key="cancel" label="Close" onClick={handleClose} />
      </Actions>
    </Dialog>
  );
};

const connection = connect(
  (state) => ({
    taggings: state.taggings,
    project: state.project,
    organizationCurrent: state.organizationCurrent,
    user: state.user,
  }),
  {
    createTagging: taggingsApi.create,
    getTaggings: taggingsApi.findAllPerTaggable,
    updateTagging: taggingsApi.put,
    deleteTagging: taggingsApi.destroy,
  }
);

export default connection(SurveyTagEditor);
