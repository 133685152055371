import React from 'react';
import classNames from 'classnames/bind';
import MUITextField from 'material-ui/TextField';
import PropTypes from 'prop-types';
import { delimitWithCommas } from 'src/utils/number';
import { InputReduxForm, InputSticky, Label, Error } from './base';
import { INPUT_STYLE } from './TextField';
import cls from './NumberField.module.css';

const cx = classNames.bind(cls);
const deformat = (formattedNumber) => {
  const result = formattedNumber
    .replace(/[^\d.]/g, '')
    .replace(/\./, 'x')
    .replace(/\./g, '')
    .replace(/x/, '.');
  return result;
};

export class NumberField extends React.PureComponent {
  static propTypes = {
    format: PropTypes.func,
    delimitingWithCommas: PropTypes.bool,
    flat: PropTypes.bool,
    flatDark: PropTypes.bool,
    flatWhite: PropTypes.bool,
    border: PropTypes.bool,
    borderDark: PropTypes.bool,
    icon: PropTypes.node,
    ...InputReduxForm.propTypes,
    ...InputSticky.propTypes,
  };

  static defaultProps = {
    type: 'number',
    underlineShow: false,
    noValidation: false,
  };
  constructor(props) {
    super(props);
    this.state = { value: props.input?.value ?? props?.value };
  }

  componentWillReceiveProps({ input, value }) {
    if (input && input.value !== this.state.value) {
      return this.setState({ value: input.value });
    }
    if (!input && value !== this.state.value) {
      return this.setState({ value });
    }

    return false;
  }

  onChange = (ev, value) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    const valueDeformatted =
      this.props.delimitingWithCommas || this.props.format
        ? deformat(value)
        : value;
    this.setState({ value: valueDeformatted });
    InputReduxForm.onChange(
      this.props.input,
      this.props.onChange
    )(valueDeformatted.length ? Number(valueDeformatted) : null);
  };

  /* redux-form onBlur updates value with ev.target.value */
  onBlur = () => {
    this.props.input?.onBlur?.();
  };

  // Fix for a Firefox quirk
  onDragStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  formatValue = () => {
    const value = this.state.value != undefined ? this.state.value : '';
    return this.props.format
      ? this.props.format(value)
      : this.props.delimitingWithCommas
      ? delimitWithCommas(value.toString())
      : value;
  };

  render() {
    const {
      children,
      className,
      noValidation,
      meta,
      input,
      label,
      stick,
      icon,
      delimitingWithCommas,
      format,
      error,
      type,
      flat,
      flatDark,
      flatWhite,
      border,
      borderDark,
      ...rest
    } = this.props;

    return (
      <div className={cx({ 'imui-input-wrapper-fullwidth': rest.fullWidth })}>
        <Label label={label} />
        <MUITextField
          {...input}
          {...rest}
          type={delimitingWithCommas || format ? 'text' : type}
          className={cx(
            'imui-text-field',
            'imui-number-field',
            {
              'imui-text-field-has-hint': !!rest.hintText,
              'imui-text-field-flat': flat,
              'imui-text-field-flatDark': flatDark,
              'imui-text-field-flatWhite': flatWhite,
              'imui-text-field-border': border,
              'imui-text-field-border-dark': borderDark,
              [`imui-text-field-stick-${stick}`]: stick && stick.length,
            },
            className
          )}
          inputStyle={{ ...INPUT_STYLE, ...(rest.inputStyle || {}) }}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onDragStart={this.onDragStart}
          onWheel={(event) => {
            // Prevents the numeric value from being changed when scrolling
            event.target.blur();
          }}
          value={this.formatValue()}
        >
          {children}
        </MUITextField>
        {!icon ? null : (
          <div className={cx('imui-text-field-custom-icon')}>{icon}</div>
        )}
        {!noValidation && <Error meta={meta} error={error} />}
      </div>
    );
  }
}

export default NumberField;
