import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import organizationApi from 'src/api/Organization';
import Page from 'src/components/Page/Page';
import UserOrgEditDataSources from './UserOrgEditDataSources';
import UserOrgEditDictionary from './UserOrgEditDictionary';
import UserOrgEditLicensePlan from './UserOrgEditLicensePlan';
import UserOrgEditMembership from './UserOrgEditMembership';
import UserOrgEditSettings from './UserOrgEditSettings';
import {
  canManageOrganization,
  isSubscribed,
  surveyOnly,
} from 'src/userStorage';

@connect(pick(['organization']), { getOrganization: organizationApi.find })
export default class OrganizationEditIndex extends React.PureComponent {
  static propTypes = {
    ...ReactRouterPropTypes,
    organization: PropTypes.object,
    getOrganization: PropTypes.func.isRequired,
  };
  static defaultProps = { organization: {} };
  componentDidMount() {
    this.props.getOrganization({ id: this.props.match.params.id });
  }
  render() {
    const steps = [
      { url: `${this.props.match.url}/settings`, title: 'Settings' },
      isSubscribed() && {
        url: `${this.props.match.url}/membership`,
        title: 'Manage users',
      },
      !surveyOnly() &&
        isSubscribed() &&
        canManageOrganization() && {
          url: `${this.props.match.url}/dictionary`,
          title: 'Dictionary',
        },
      !surveyOnly() &&
        isSubscribed() &&
        canManageOrganization() && {
          url: `${this.props.match.url}/datasources`,
          title: 'Data Sources',
        },
      {
        url: `${this.props.match.url}/license`,
        title: 'License',
      },
    ].filter(Boolean);

    const activeStep = steps
      .map((e) => e.url)
      .indexOf(this.props.location.pathname);

    return (
      <Page
        noSeparator
        back={'/user/organizations'}
        pending={
          this.props.organization.pending.init ||
          !this.props.organization.data.id
        }
        title={`Edit - ${this.props.organization.data.title}`}
        steps={steps}
        activeStep={activeStep}
        hasFooter={activeStep !== 1}
      >
        <Switch>
          <Route
            path={`${this.props.match.path}/membership`}
            component={UserOrgEditMembership}
            exact
          />
          {!surveyOnly() && (
            <Route
              path={`${this.props.match.path}/dictionary`}
              component={UserOrgEditDictionary}
              exact
            />
          )}
          <Route
            path={`${this.props.match.path}/settings`}
            component={UserOrgEditSettings}
            exact
          />
          {!surveyOnly() && canManageOrganization() && (
            <Route
              path={`${this.props.match.path}/datasources`}
              component={UserOrgEditDataSources}
              exact
            />
          )}
          <Route
            path={`${this.props.match.path}/license`}
            component={UserOrgEditLicensePlan}
            exact
          />
          <Redirect from="*" to={`${this.props.match.url}/settings`} />
        </Switch>
      </Page>
    );
  }
}
