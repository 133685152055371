import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyAnswers } from 'src/actionCreators/surveyAnalysisActionCreators';
import taggingsApi from 'src/api/Taggings';
import { Tag } from 'src/components/IMUI';
import TagSelector from 'src/components/TagSelector/TagSelector';
import { isTaggable } from 'src/data/questionTypes';
import { toSurveyAnswersAnswerFromApi } from 'src/serializers/surveySerializer';
import EmptyValue from './EmptyValue';
import cls from './AnswersTable.module.css';
import { where } from 'im/api/Query';
import TaggingDetailsSelector from 'src/pages/App/Analysis/TagEditor/components/TaggingDetailsSelector';
import { Icon } from 'im/ui';
import { getGroupForTag } from 'src/api/Tags';
import { getSurveyReview } from 'src/actionCreators/surveyActionCreators';

import SurveyTagEditor from 'src/pages/App/Analysis/TagEditor/SurveyTagEditor';

const AnswersTableTags = ({ question, answer, granteeSurveyId, isReview }) => {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const surveyJsonapi = useSelector((state) => state.surveyJsonapi) ?? {};
  const projectTagsRecent =
    useSelector((state) => state.projectTagsRecent) ?? {};
  const [tagDialogOpen, setTagDialogOpen] = useState(
    window.tagDialogOpen?.[answer.id]
  );
  const [controlId, setControlId] = useState(0);
  const [editInProgress, setEditInProgress] = useState(null);
  const manualTaggings = answer?.manual_taggings ?? [];
  const taggingEditOff = () => setEditInProgress(null);
  const tagsWithTagGroups = [
    ...(surveyJsonapi.data.survey_question_tags?.map((t) => ({
      ...t,
      tagGroup: getGroupForTag(t),
    })) ?? []),
    ...(projectTagsRecent.data?.map((t) => ({
      ...t,
      tagGroup: getGroupForTag(t),
    })) ?? []),
    ...(manualTaggings.map((mt) => ({ ...mt.tag, tagGroup: {} })) ?? []),
  ];

  const serialAnswer = toSurveyAnswersAnswerFromApi(question, answer);
  const isEmpty =
    !serialAnswer.tags?.length &&
    !serialAnswer.manual_taggings?.length &&
    !serialAnswer.question_taggings?.length;
  const selectedTagIds = manualTaggings.map((t) => t.tag_id);

  const updateControl = () => {
    setControlId((id) => id + 1);
  };
  const reloadAnswers = () => {
    isReview
      ? dispatch(getSurveyReview(project.id, granteeSurveyId)).then(
          updateControl
        )
      : dispatch(getSurveyAnswers(question.id, { force: true })).then(
          updateControl
        );
  };
  const onCloseTagDialog = () => {
    window.tagDialogOpen ||= {};
    window.tagDialogOpen[answer.id] = false;
    window.location.hash = null;
    window.location.hash = `response-${granteeSurveyId}`;
    dispatch(
      taggingsApi.findAllPerTaggable(
        where({ taggable_id: answer.uid, taggable_type: 'SurveyAnswer' })
          .include('tag', 'tag.tag_categories')
          .filter('project_id_eq', project.uid)
          .filter('scope', 'content')
          .paginate({ size: 3000 })
          .pending('init')
      )
    ).then(reloadAnswers);
    setTagDialogOpen(false);
  };
  const onAddTagDetailed = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    window.tagDialogOpen ||= {};
    window.tagDialogOpen[answer.id] = true;
    setTagDialogOpen(true);
  };

  const onTaggingRemove = (tagId) => {
    const currentTaggings = [
      ...(answer.question_taggings || []),
      ...(answer.manual_taggings || []),
    ].filter(Boolean);
    const toRemove = currentTaggings.filter((t) => t.tag_id == tagId);
    Promise.allSettled(
      toRemove.map((t) =>
        dispatch(
          taggingsApi.destroy(
            where({ id: t.id }).actionMeta({ silent: true, noSync: true })
          )
        )
      )
    ).then(reloadAnswers);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleEditTagging = (tagging, taggingDetails) => {
    if (!editInProgress?.id) return;
    taggingEditOff();
    dispatch(
      taggingsApi.put(
        where({ id: tagging.id })
          .actionMeta({ silent: true, noSync: true })
          .payload({
            attributes: {
              content: tagging.content,
              quantity: null,
              amount: null,
              currency: null,
              year: null,
              month: null,
              day: null,
              location: null,
              ...taggingDetails,
            },
            relationships: [
              { relName: 'taggable', type: 'survey_answers', id: answer.uid },
              { relName: 'project', type: 'projects', id: project?.uid },
            ],
          })
      )
    ).then(reloadAnswers);
  };
  const onTaggingEdit = (tag) => {
    setEditInProgress(manualTaggings.find((t) => t.tag?.id == tag.id));
  };
  const hasResponse =
    isReview || answer?.response || answer?.value || answer.otherValue;

  return (
    <div className={cls.tagContainer}>
      {!hasResponse && <EmptyValue />}
      {serialAnswer.tags.map((tagId) => (
        <Tag
          dim
          key={tagId}
          label={tagsWithTagGroups.find(({ id }) => id === tagId)?.title}
        />
      ))}

      {isTaggable(question) && (
        <div className={cls.editorTags}>
          <TagSelector
            openOnTagClick={false}
            key={controlId + project.enabled_tag_categories?.join(',')}
            zIndex={1501}
            multiple
            alt
            className={cls.editorTagsSelector}
            popoverClassName={cls.popoverTagsSelector}
            onEdit={onTaggingEdit}
            noTagProps={{
              label: ' ',
              grey: false,
              square: true,
              outline: true,
              showEmptyLabel: isEmpty,
              onClick: onAddTagDetailed,
              icon: () => (
                <Icon
                  tip="add new tags"
                  name="copy"
                  style={{ fontSize: 18, float: 'right' }}
                />
              ),
            }}
            tagsWithTagGroups={tagsWithTagGroups}
            selected={selectedTagIds}
            onRemove={onTaggingRemove}
          />
        </div>
      )}

      {editInProgress?.id && (
        <TaggingDetailsSelector
          open={true}
          isEdit={true}
          tagging={editInProgress}
          position={{ top: 0, right: 0 }}
          onSubmit={handleEditTagging}
          onRequestClose={taggingEditOff}
        />
      )}

      <SurveyTagEditor
        open={tagDialogOpen}
        answer={answer}
        handleClose={onCloseTagDialog}
      />
    </div>
  );
};

export default AnswersTableTags;
