import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import clone from 'ramda/src/clone';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import {
  changeQuestionI18n,
  getSurvey,
  saveSurvey,
} from 'src/actionCreators/surveyActionCreators';
import surveyItemTranslationApi from 'src/api/SurveyItemTranslation';
import { where } from 'im/api/Query';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import { Actions, Button, Container } from 'src/components/IMUI';
import {
  COUNTRY,
  DATE,
  FINANCIAL,
  HEADER,
  LONG_TEXT,
  MULTIPLE_CHOICE,
  NUMERICAL,
  OPINION_SCALE,
  PAGELAYOUT,
  RATING,
  SECTION_BREAK,
  SHORT_TEXT,
  SINGLE_CHOICE,
  SINGLE_MATRIX,
  SUB_HEADER,
  TEXT,
} from 'src/data/questionTypes';
import { Icon } from 'im/ui/Icon';
import ChoiceQuestionProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/ChoiceQuestionProperties';
import DateQuestionProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/DateQuestionProperties';
import CountryQuestionProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/CountryQuestionProperties';
import FinancialQuestionProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/FinancialQuestionProperties';
import LayoutElementProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/LayoutElementProperties';
import NumericalQuestionProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/NumericalQuestionProperties';
import OpenQuestionProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/OpenQuestionProperties';
import OpinionScaleProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/OpinionScaleProperties';
import RatingProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/RatingProperties';
import SectionBreakProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/SectionBreakProperties';
import SingleMatrixQuestionProperties from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/SingleMatrixQuestionProperties';
import cls from 'src/pages/App/Analysis/Surveys/Survey/Builder/components/QuestionEdit.module.css';
import {
  canAiTranslate,
  canBuildSurvey,
  canManageSurvey,
} from 'src/userStorage';
const cx = classNames.bind(cls);

const getTypePropertiesComponent = (type) => {
  switch (type) {
    case SHORT_TEXT:
    case LONG_TEXT:
      return OpenQuestionProperties;
    case SINGLE_CHOICE:
      return ChoiceQuestionProperties;
    case MULTIPLE_CHOICE:
      return ChoiceQuestionProperties;
    case FINANCIAL:
      return FinancialQuestionProperties;
    case NUMERICAL:
      return NumericalQuestionProperties;
    case RATING:
      return RatingProperties;
    case OPINION_SCALE:
      return OpinionScaleProperties;
    case DATE:
      return DateQuestionProperties;
    case SINGLE_MATRIX:
      return SingleMatrixQuestionProperties;
    case COUNTRY:
      return CountryQuestionProperties;
    case SECTION_BREAK:
      return SectionBreakProperties;
    case HEADER:
    case SUB_HEADER:
    case TEXT:
      return LayoutElementProperties;
    default:
      return LayoutElementProperties;
  }
};

export const getHeaderDetailsForType = (type) => {
  switch (type) {
    case DATE:
      return { icon: 'calendar', title: 'Date value' };
    case SHORT_TEXT:
      return { icon: 'text-field', title: 'Short text' };
    case LONG_TEXT:
      return { icon: 'text-field', title: 'Long text' };
    case RATING:
      return { icon: 'star-empty', title: 'Rating' };
    case OPINION_SCALE:
      return { icon: 'number', title: 'Opinion Scale' };
    case SINGLE_CHOICE:
      return { icon: 'multiple', title: 'Single choice' };
    case MULTIPLE_CHOICE:
      return { icon: 'multiple', title: 'Multi choice' };
    case NUMERICAL:
      return { icon: 'number', title: 'Numerical value' };
    case FINANCIAL:
      return { icon: 'currency', title: 'Financial value' };
    case SINGLE_MATRIX:
      return { icon: 'list', title: 'Single matrix' };
    case COUNTRY:
      return { icon: 'globe', title: 'Country selection' };
    case HEADER:
      return { icon: 'headline-element', title: 'Header' };
    case SUB_HEADER:
      return { icon: 'subhead-element', title: 'Sub-header' };
    case TEXT:
      return { icon: 'text-element', title: 'Text' };
    case SECTION_BREAK:
      return { icon: 'break', title: 'Section break' };
    default:
      return { title: 'Question', icon: 'file' };
  }
};

@connect(pick(['survey']), {
  translateSurveyItem: surveyItemTranslationApi.create,
  updateTranslationStatus: surveyItemTranslationApi.put,
  getSurvey,
  saveSurvey,
  showNotification,
  changeQuestionI18n,
})
export default class QuestionEdit extends React.PureComponent {
  static propTypes = {
    survey: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    question: PropTypes.object,
    tagsWithTagGroups: PropTypes.array.isRequired,
    index: PropTypes.number,
    completeness: PropTypes.object,
    onClose: PropTypes.func,
    onChangeQuestionProperty: PropTypes.func,
    onUpdateQuestion: PropTypes.func,
    onDelete: PropTypes.func,
    onClone: PropTypes.func,
    saveSurvey: PropTypes.func.isRequired,
    getSurvey: PropTypes.func.isRequired,
    showNotification: PropTypes.func,
    translateSurveyItem: PropTypes.func.isRequired,
    updateTranslationStatus: PropTypes.func,
    changeQuestionI18n: PropTypes.func,
    onSaveSurvey: PropTypes.func,
    // startTranslationPolling: PropTypes.func,
    translationInProgress: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      questionInitial: clone(props.question),
      errors: {},
      submitFired: false,
    };
  }

  getDoneButtonLabel(question = {}) {
    if (question.isLayout) {
      return 'Done';
    }
    return typeof question.id === 'number' ? 'Update' : 'Create';
  }

  handleDoneClick = async () => {
    await this.setState({ submitFired: true });
    const errors = this.validate(this.props.question);
    if (errors) {
      this.props.showNotification({
        title: `${
          getHeaderDetailsForType(this.props.question.type).title
        } edit error`,
        message: Object.values(errors)
          .flat()
          .map((err) => (typeof err === 'string' ? err : Object.values(err)))
          .flat()
          .join(', '),
        level: 'error',
      });
      return;
    }
    this.props.onClose();
    if (this.props.survey.dirty) {
      this.props.onSaveSurvey();
    }
  };

  handleCancelClick = (question) => {
    if (
      (!question.isLayout && !question.server_id) ||
      (question.isLayout && !this.state.questionInitial.question)
    ) {
      this.props.onDelete(question);
      return;
    }
    this.props.onUpdateQuestion(question.id, this.state.questionInitial);
    this.props.onClose();
  };

  handleChangeI18nProperty = (value, path, altPath) => {
    this.handleUpdateI18n(value, path, altPath);
    if (path === 'question') {
      this.validate({
        ...this.props.question,
        question:
          typeof value === 'object'
            ? value[this.props.survey.activeLanguage]
            : value,
      });
    }
  };

  handleChangeQuestionProperty = (
    questionId,
    propertyName,
    propertyValue,
    meta = {}
  ) => {
    this.props.onChangeQuestionProperty(
      questionId,
      propertyName,
      propertyValue,
      meta
    );
    if (!meta.silent) {
      this.validate({ ...this.props.question, [propertyName]: propertyValue });
    }
  };

  handleCloneQuestion = () => {
    this.props.onClone(this.props.question, this.props.index);
  };

  handleDeleteRequested = () => {
    confirm(
      'Delete element',
      'Are you sure that you want to delete this element?',
      true
    ).then(
      () => this.props.onDelete(this.props.question),
      () => void 0
    );
  };

  handleUpdateI18n = (values, path, altPath) => {
    if (typeof values === 'string') {
      this.props.changeQuestionI18n?.(
        this.props.question,
        values,
        path,
        altPath
      );
    } else {
      this.props.survey.languages.forEach((langCode) => {
        this.props.changeQuestionI18n?.(
          this.props.question,
          values[langCode],
          path,
          altPath,
          langCode
        );
      });
    }
  };

  handleTranslateQuestion = () => {
    this.props.saveSurvey(this.props.survey, this.props.projectId).then(() => {
      this.props
        .translateSurveyItem(
          where({ id: this.props.question.id }).payload({
            survey_id: this.props.survey.id,
          })
        )
        .then(() => {
          this.props.getSurvey(this.props.survey.id, this.props.projectId, {
            silent: true,
          });
          this.props.onClose();
          // TODO: Disable polling for individual question for now
          // Right now, the polling terminating conditition is
          // translationProgress (for the survey) = 100%
          // this.props.startTranslationPolling();
        });
    });
  };

  handleUpdateTranslationStatus = () => {
    this.props.saveSurvey(this.props.survey, this.props.projectId).then(() => {
      this.props
        .updateTranslationStatus(
          where({ id: this.props.question.id }).payload({
            survey_id: this.props.survey.id,
            translation_status: 'approved',
          })
        )
        .then(() => {
          this.props.getSurvey(this.props.survey.id, this.props.projectId, {
            silent: true,
          });
        });
    });
  };

  validate(question, propertyName) {
    const errors = {};

    if (
      !(question?.question || '').trim() &&
      !PAGELAYOUT.includes(question?.type)
    ) {
      errors.question = `Value can't be empty`;
    }
    const TypeProperties = getTypePropertiesComponent(question.type);
    if (TypeProperties && TypeProperties.validate) {
      const typeErrors = TypeProperties.validate(question);
      if (typeErrors) Object.assign(errors, typeErrors);
    }
    Object.keys(errors).forEach((key) => {
      if (
        !errors[key] ||
        (!this.state.submitFired && propertyName && key !== propertyName)
      )
        delete errors[key];
      if (Array.isArray(errors[key]) && errors[key].length === 0)
        delete errors[key];
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : false;
  }

  render() {
    const TypeProperties = getTypePropertiesComponent(this.props.question.type);
    const questionInfo = getHeaderDetailsForType(this.props.question.type);
    return (
      <div className={cx('questionEdit')}>
        <Container className={cls.header} horizontal>
          <div className={cls.title}>
            <Icon name={questionInfo.icon} />
            {questionInfo.title}
          </div>
          <div>
            {canAiTranslate() && canManageSurvey() && (
              <Icon
                disabled={this.props.translationInProgress}
                name="translate"
                onClick={this.handleTranslateQuestion}
                tip={
                  this.props.translationInProgress
                    ? 'Translation for another question in progress'
                    : 'Auto-Translate'
                }
              />
            )}
            {canManageSurvey() && this.props.question.server_id && (
              <Icon
                name="copy"
                onClick={this.handleCloneQuestion}
                tip="Duplicate"
              />
            )}
            {canManageSurvey() && (
              <Icon
                name="trash"
                onClick={this.handleDeleteRequested}
                tip="Remove"
              />
            )}
          </div>
        </Container>

        <div
          className={cx('editQuestionContent', {
            editlayoutElementContent: this.props.question.isLayout,
          })}
        >
          <TypeProperties
            question={this.props.question}
            survey={this.props.survey}
            completeness={this.props.completeness}
            errors={this.state.errors}
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            onChangeI18nProperty={this.handleChangeI18nProperty}
            onChangeQuestionProperty={this.handleChangeQuestionProperty}
            type={this.props.question.type}
          />
        </div>

        <Container horizontal className={cls.footer}>
          <Actions>
            <Button
              negative
              label="Cancel"
              size="l"
              onClick={() => this.handleCancelClick(this.props.question)}
            />
          </Actions>
          <Actions>
            {this.props.survey.languages.length > 1 &&
              this.props.question.translation_status === 'for_review' && (
                <Button
                  label="Mark as translated"
                  secondary
                  disabled={false}
                  size="l"
                  onClick={this.handleUpdateTranslationStatus}
                />
              )}
            <Button
              disabled={!canBuildSurvey()}
              label={this.getDoneButtonLabel(this.props.question)}
              size="l"
              onClick={this.handleDoneClick}
            />
          </Actions>
        </Container>
      </div>
    );
  }
}
