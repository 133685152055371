import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import tagApi from 'src/api/Tag';
import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import tagCategoryTagsApi from 'src/api/TagCategoryTags';
import tagsApi from 'src/api/Tags';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import {
  Button,
  Container,
  Actions,
  Drawer,
  Progress,
} from 'src/components/IMUI/index';

import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import MergeTagConfirmationForm from './MergeTagConfirmationForm';
import MergeTagFinishedForm from './MergeTagFinishedForm';
import TagForm from './TagForm';

import cls from './CreateTag.module.css';
import { canManageTag } from 'src/userStorage';

@connect(
  (state) => ({
    project: state.project,
    projectTags: state.projectTags,
    tag: state.tag,
    organizationCurrent: state.organizationCurrent,
  }),
  {
    editTag: tagsApi.ofProject.put,
    deleteTag: tagsApi.ofProject.destroy,
    getTag: tagApi.find,
    addTagToCategory: tagCategoryTagsApi.create,
    removeTagFromCategory: tagCategoryTagsApi.destroy,
    submitForm: submit,
    mergeTags: tagsApi.ofProject.mergeTags,
    getTagSelectorTagCategories:
      tagCategoriesSelectorApi.findAllPerProjectNested,
  }
)
export default class EditTag extends Component {
  static propTypes = {
    project: PropTypes.object,
    open: PropTypes.bool,
    tagId: PropTypes.string,
    tag: PropTypes.object,
    urlParam: PropTypes.string,
    noSync: PropTypes.bool,

    editTag: PropTypes.func.isRequired,
    addTagToCategory: PropTypes.func.isRequired,
    removeTagFromCategory: PropTypes.func.isRequired,
    getTag: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onSuccessfulEdit: PropTypes.func,
    projectTags: PropTypes.object,

    submitForm: PropTypes.func.isRequired,
    mergeTags: PropTypes.func.isRequired,

    getTagSelectorTagCategories: PropTypes.func.isRequired,
    organizationCurrent: PropTypes.object.isRequired,
  };

  static FORM_NAME = 'editTagForm';

  static getDeleteConfirmation = (tag) => {
    const title = 'Delete a tag';
    const textAdditional = `It's applied ${tag.taggings_count} time${
      tag.taggings_count > 1 ? 's' : ''
    }`;
    const text = `Are you sure you want to delete a tag: ${tag.title}? ${
      tag.taggings_count > 0 ? textAdditional : ''
    }`;
    return confirm(title, text, true);
  };

  state = {
    addTagFailed: false,
    addTagToTagCategoryFailed: false,
    mergeMode: false,
    tagsToMerge: null,
    mergeOptionValue: null,
  };

  componentDidMount() {
    this.doRequestTag();
  }

  doRequestTag() {
    const query = where({ id: this.props.tagId })
      .include('tag_categories')
      .pending('init');
    this.props.getTag(query);
  }

  handleRequestClose = (updatedTags) => {
    this.setState({ addTagFailed: false, addTagToTagCategoryFailed: false });
    this.props.onRequestClose(updatedTags);
  };

  handleSubmit = async ({ category: newCategory, tags }) => {
    const { tag, noSync } = this.props;
    const newTag = tags[0];
    const previousTagCategory = tag.meta.group;

    const updatedTag = await this.editTag({ ...newTag, id: tag.data.id });
    if (!updatedTag) {
      this.setState({ addTagFailed: true });
      return;
    }

    if ((previousTagCategory || {}).id !== newCategory.id) {
      const tagCategoryTag =
        tag.data.tag_category_tags.length === 1
          ? tag.data.tag_category_tags[0]
          : tag.data.tag_category_tags.find(
              ({ id }) =>
                previousTagCategory.tag_category_tags.findIndex(
                  (c) => c.id === id
                ) > -1
            );

      if (tagCategoryTag) {
        const queryTC = where({ id: tagCategoryTag.id }).actionMeta({ noSync });
        await this.props.removeTagFromCategory(queryTC);
      }
      const addedToCategory = await this.addTagToCategory(
        tag.data.id,
        newCategory.id
      );
      if (!addedToCategory) {
        this.setState({ addTagFailed: false, addTagToTagCategoryFailed: true });
        // We need to make sure that tagCategories change is reflected
        this.doRequestTag();
        return;
      }
    }

    this.props.onSuccessfulEdit?.([updatedTag]);
    this.handleRequestClose([updatedTag]);
  };

  handleDeleteTag = () => {
    EditTag.getDeleteConfirmation(this.props.tag.data)
      .then(async () => {
        await this.props.deleteTag({ id: this.props.tag.data.id });
        this.props.getTagSelectorTagCategories();
        this.props.onRequestClose();
        this.props.onSuccessfulEdit?.([{ ...this.props.tag.data }]);
      })
      .catch(() => void 0);
  };

  editTag = ({ id, title, description, code }) => {
    const { noSync } = this.props;
    const query = where({ id })
      .payload({
        title,
        description: description ?? undefined,
        code: code ?? undefined,
      })
      .actionMeta({ noSync });
    return this.props.editTag(query);
  };

  addTagToCategory = (tagId, tagCategoryId) => {
    const { noSync } = this.props;

    const query = where()
      .payload({
        relationships: [
          { relName: 'tag', type: 'tags', id: tagId },
          {
            relName: 'tag_category',
            type: 'tag_categories',
            id: tagCategoryId,
          },
        ],
      })
      .actionMeta({ noSync });

    return this.props.addTagToCategory(query);
  };

  handleRequestEditTag = () => {
    this.props.submitForm(EditTag.FORM_NAME);
  };

  handleOnMergeStart = () => (selectedTagIds) => {
    const tagsToMerge = this.props.projectTags.data.reduce(
      (details, tag) => {
        if (selectedTagIds.indexOf(tag.id) > -1) {
          return {
            count: details.count + tag.taggings_count,
            titles: details.titles.concat([tag.title]),
          };
        }
        return details;
      },
      { count: 0, titles: [] }
    );

    this.setState({
      mergeMode: 1,
      tagsToMerge: {
        count: tagsToMerge.count,
        titles: tagsToMerge.titles.join(', '),
      },
      selectedTagIds,
    });
  };

  handleMergeConfirmClick = () => {
    this.setState({ mergeMode: 2 });
    const payload = {
      merge_tag_ids: this.state.selectedTagIds,
      project_id: this.props.project.uid,
    };
    const query = where({ tagIntoId: this.props.tag.data.id }).payload(payload);
    this.props.mergeTags(query);
  };

  handleMergeOptionChange = (event, value) => {
    this.setState({ mergeOptionValue: value });
  };

  renderFooter = () => {
    const buttonProps = { label: 'Save', onClick: this.handleRequestEditTag };
    if (this.state.mergeMode === 1) {
      buttonProps.label = 'Merge';
      buttonProps.onClick = this.handleMergeConfirmClick;
    }
    if (this.state.mergeMode === 2) {
      buttonProps.label = 'Done';
      buttonProps.onClick = this.handleRequestClose;
    }

    return (
      <Container horizontal>
        <Actions>
          <Button
            negative
            size="l"
            label="Cancel"
            onClick={() => this.handleRequestClose()}
          />
        </Actions>
        <Actions>
          <Button size="l" {...buttonProps} />
        </Actions>
      </Container>
    );
  };

  renderMerge = () => {
    if (this.state.mergeMode === 1) {
      return (
        <MergeTagConfirmationForm
          onChange={this.handleMergeOptionChange}
          value={this.state.mergeOptionValue}
          tagsToMerge={this.state.tagsToMerge}
          tag={this.props.tag.data}
        />
      );
    }

    return (
      <MergeTagFinishedForm
        tagsToMerge={this.state.tagsToMerge}
        tag={this.props.tag.data}
      />
    );
  };

  renderForm = () => {
    const { tag } = this.props;
    const { addTagFailed, addTagToTagCategoryFailed } = this.state;

    return tag.pending.init ? (
      <Progress />
    ) : (
      <div>
        <h3>Edit tag</h3>
        <TagForm
          mode="edit"
          initialValues={{
            category: tag.meta.group,
            metatags: (tag.meta.group || {}).metatags,
            tags: [tag.data],
          }}
          form={EditTag.FORM_NAME}
          showTagErrors={addTagFailed}
          showTagCategoryTagsErrors={addTagToTagCategoryFailed}
          onSubmit={this.handleSubmit}
          onMergeStart={this.handleOnMergeStart()}
        />

        {canManageTag() && (
          <Button
            text
            alert
            className={cls.buttonAlert}
            label="Delete tag"
            onClick={this.handleDeleteTag}
            icon={<Icon name="trash" tip="Remove" />}
          />
        )}
      </div>
    );
  };

  render() {
    const { open, urlParam } = this.props;
    const { mergeMode } = this.state;

    return (
      <Drawer
        closable
        open={open}
        width={580}
        docked={true}
        urlParam={urlParam}
        renderFooter={this.renderFooter}
        onRequestClose={() => this.handleRequestClose()}
      >
        {mergeMode ? this.renderMerge() : this.renderForm()}
      </Drawer>
    );
  }
}
