/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FullBgView } from 'src/components/FullBgView';
import { reduxForm, Form, Field } from 'redux-form';
import pick from 'ramda/src/pick';
import isEmail from 'validator/lib/isEmail';
import organizationsApi from 'src/api/Organizations';
import {
  TextField,
  FormField,
  Checkbox,
  Button,
  Progress,
} from 'src/components/IMUI/index';
import history from 'src/historyInstance';
import { getToken } from 'src/actionCreators/authActionCreators';
import createValidator from 'src/utils/validation';
import { where } from 'im/api/Query';
import cls from './Register.module.css';
const CHECKBOX_STYLE = { fill: '#221e20' };
const LABEL_STYLE = { zIndex: 3 };
const papers = [
  {
    name: 'General Terms and Conditions,',
    path: 'https://www.impactmapper.com/legal-general-terms',
  },
  { name: 'Use policy,', path: 'https://www.impactmapper.com/use-policy' },
  {
    name: 'Privacy policy,',
    path: 'https://www.impactmapper.com/privacy-policy',
  },
  {
    name: 'and SaaS Terms and Conditions',
    path: 'https://www.impactmapper.com/compliance',
  },
];

@connect(pick(['publicOrganizations']), {
  createOrganization: organizationsApi.public.create,
  getToken,
})
class RegisterOrganization extends React.PureComponent {
  static propTypes = {
    createOrganization: PropTypes.func.isRequired,
    publicOrganizations: PropTypes.object,
    getToken: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };
  state = { id: undefined, email: undefined, name: undefined, progress: false };

  onSubmit = (data) => {
    this.setState({ progress: true });
    this.props
      .createOrganization(where().payload({ ...data }))
      .then((response) => {
        if (!response?.data?.id) return this.setState({ progress: false });
        const licenseUrl = `/user/organizations/edit/${response.data.id}/license`;

        this.props
          .getToken({ email: data.email, password: data.password })
          .then(() => history.replace({ pathname: licenseUrl }))
          .catch((e) => {
            console.log(e);
          })
          .finally(() => this.setState({ progress: false }));
      });
  };

  onPaperOpen(ev, url) {
    ev.stopPropagation();
    window.open(url, '_blank');
  }

  render() {
    return (
      <FullBgView>
        {this.state.progress && <Progress large className="absolute" />}
        {!this.state.progress && (
          <Form noValidate onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <FullBgView.Box
              limitedWidth={true}
              innerLogo={true}
              errors={this.props.publicOrganizations?.errors}
              errorsTemplate={{
                422: 'Cannot register organization.\nPlease confirm if title or informed e-mail are not registered',
              }}
              rightAction={
                <Button
                  dark
                  type="submit"
                  size="l"
                  label="Create Account"
                  onClick={this.props.handleSubmit(this.onSubmit)}
                />
              }
            >
              <h3>Please provide your organization details</h3>
              <br />
              <FormField>
                <Field
                  label="Organization Name"
                  fullWidth
                  component={TextField}
                  name="title"
                  hintText="Type in..."
                />
              </FormField>
              <FormField>
                <Field
                  label="Your Full Name"
                  fullWidth
                  component={TextField}
                  value={this.state.name}
                  name="full_name"
                  hintText="Full name"
                />
              </FormField>
              <FormField>
                <Field
                  label="Your Email"
                  fullWidth
                  component={TextField}
                  value={this.state.email}
                  name="email"
                  type="email"
                  noValidate
                  autoComplete="nope"
                  hintText="Email"
                />
              </FormField>
              <FormField>
                <Field
                  label="Your Password"
                  fullWidth
                  component={TextField}
                  name="password"
                  type="password"
                  noValidate
                  autoComplete="nope"
                  hintText="Password"
                />
              </FormField>
              <br />
              <Field
                name="terms_conditions"
                noValidation={false}
                component={Checkbox}
                label={
                  <div>
                    I agree with&nbsp;
                    {papers.map(({ name, path }) => (
                      <span
                        style={{ marginLeft: 3, marginRight: 3 }}
                        key={path}
                      >
                        <Button
                          text
                          dark
                          underlined
                          className={cls.linkText}
                          onClick={(ev) => this.onPaperOpen(ev, path)}
                          label={name}
                        />
                      </span>
                    ))}
                  </div>
                }
                iconStyle={CHECKBOX_STYLE}
                labelStyle={LABEL_STYLE}
                format={(v) => !!v}
              />
            </FullBgView.Box>
          </Form>
        )}
      </FullBgView>
    );
  }
}

const not = (fn) => (v) => !fn(v);
const isDefined = (v) => Boolean(v);
const longerThan = (n) => (v) => v.length > n;
const minLength = (n) => (v) => v.length >= n;
const hasLowerCaseLetter = (v) => v && !!v.match(/\.*[a-z].*/);
const hasUpperCaseLetter = (v) => v && !!v.match(/\.*[A-Z].*/);
const validate = (values) =>
  createValidator({
    title: [
      [not(isDefined), () => 'Organization name is required'],
      [not(longerThan(1)), () => 'Title is too short'],
    ],
    full_name: [
      [not(isDefined), () => 'User Full Name is required'],
      [not(longerThan(1)), () => 'It is not a valid Name'],
    ],
    contact_email: [
      [not(isDefined), () => 'Contact Email is required'],
      [(v) => v && !isEmail(v), () => 'Invalid email'],
    ],
    email: [
      [not(isDefined), () => 'Admin Email is required'],
      [not(isEmail), () => 'It is not a valid email'],
    ],
    terms_conditions: [[not(isDefined), () => 'Accept Terms and Conditions']],
    password: [
      [not(isDefined), () => 'Password is required'],
      [not(minLength(6)), () => 'Password must have at least 6 characters'],
      [
        not(hasLowerCaseLetter),
        () => 'Password must have at least 1 lower case letter',
      ],
      [
        not(hasUpperCaseLetter),
        () => 'Password must have at least 1 upper case letter',
      ],
    ],
  })(values);
export default reduxForm({ form: 'registerOrganizationForm', validate })(
  RegisterOrganization
);
