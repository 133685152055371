import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/Resource';

const apiConfig = {
  root: '/v3/survey_item_translations',
  type: 'survey_item_translation',
  api: {
    allow: ['create', 'put'],
  },
};

const SurveyItemTranslation = new ResourceCollection(
  apiConfig,
  'SurveyItemTranslation'
);
reducerRegistry.register(SurveyItemTranslation);

export default SurveyItemTranslation.api;
