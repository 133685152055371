import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/stripe/checkout',
  type: 'stripe_checkout',
  api: {
    allow: ['create'],
  },
};

export const StripeCheckout = new ResourceCollection(
  apiConfig,
  'stripeCheckoutJsonapi'
);
reducerRegistry.register(StripeCheckout);

export default StripeCheckout.api;
