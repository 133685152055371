/* eslint-disable */
import React from 'react';
import classNames from 'classnames/bind';
import { T } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { surveyValidationFailed } from 'src/actionCreators/surveyAnswersActionCreators';
import { Card, CardFooter } from 'src/components/IMUI';
import AnswersTableTags from 'src/pages/App/Analysis/Surveys/Survey/Summary/components/AnswersTableTags';
import { SECTION_BREAK, ANSWERABLE } from 'src/data/questionTypes';
import { conditionsCheck } from 'src/utils/surveysConditionalLogic';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import Question from './Question';
import rightAlignLanguages from './rightAlignLanguages';
import SurveyFooter from './SurveyFooter';
import SurveyLegalInfo from './SurveyLegalInfo';
import cls from './Survey.module.css';
import QuotesList from 'src/pages/App/Analysis/TagEditor/components/Quotes';
import UploadAttachment from './UploadAttachment';
const cx = classNames.bind(cls);

@connect(pick(['survey', 'surveyAnswers']), { surveyValidationFailed }, null, {
  forwardRef: true,
})
export default class Survey extends React.PureComponent {
  static propTypes = {
    questions: PropTypes.array.isRequired,
    survey: PropTypes.object,
    surveyAnswers: PropTypes.object,
    layout: PropTypes.array.isRequired,
    answers: PropTypes.array,
    tagsWithTagGroups: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSaved: PropTypes.bool,
    isDirty: PropTypes.bool,
    isPending: PropTypes.bool,
    isReview: PropTypes.bool,
    isEditing: PropTypes.bool,
    isPreview: PropTypes.bool,
    isPrintable: PropTypes.bool,
    isPrinting: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    skipConditionalLogic: PropTypes.bool,
    hideAnswers: PropTypes.bool,
    forceMobileStyling: PropTypes.bool,
    onAnswerChange: PropTypes.func,
    onTagsChange: PropTypes.func,
    surveyValidationFailed: PropTypes.func,
    changeAnswerOtherChoiceValue: PropTypes.func,
    onRefresh: PropTypes.func,
    organization: PropTypes.shape({
      title: PropTypes.string,
      email: PropTypes.string,
    }),
    projectId: PropTypes.any,
    granteeSurveyId: PropTypes.any,
  };

  static defaultProps = {
    onAnswerChange: () => void 0,
    onTagsChange: () => void 0,
    onSubmit: () => void 0,
    organization: {},
  };

  static getPagesInfo = (questions, layout, surveyComponent) => {
    const hiddenPages = [];
    const pagesInfo = {
      pageStarters: {},
      pages: [],
      allPages: [],
      allItems: [],
    };
    let terminated = false;
    const showTerminationError = {};
    const source = layout || questions;
    const pagesObj = source.filter(
      (q) => q.settings && q.settings.sectionNewPage
    );
    for (let index = 0; index < source.length; index += 1) {
      const question = source[index];
      if (
        index === 0 ||
        (question.type === SECTION_BREAK &&
          question.settings &&
          question.settings.sectionNewPage &&
          index > 0)
      ) {
        if (!terminated) {
          pagesInfo.pages.push([]);
        }
        pagesInfo.allPages.push([]);
        pagesInfo.pageStarters[question.id || question.question_id] =
          pagesInfo.pages.length;

        if (
          question.type === SECTION_BREAK &&
          surveyComponent &&
          question.settings
        ) {
          if (
            question.settings.sectionNewPage &&
            !surveyComponent.isVisible(question, { ignoreHidden: true })
          ) {
            const pageNumber = pagesObj.findIndex((p) => p.id === question.id);
            hiddenPages.push(pageNumber + 1);
          }
        }
      }
      let questionModel = question;
      if (layout && !question.isLayout) {
        questionModel = questions.find((q) => q.id === question.question_id);
      }
      // LEGACY for old surveys it was possible that layout had reference
      // to client side ID and could not find the related question model
      if (questionModel) {
        if (!terminated) {
          pagesInfo.pages[pagesInfo.pages.length - 1]?.push(questionModel);
        }
        pagesInfo.allPages[pagesInfo.pages.length - 1]?.push(questionModel);
        pagesInfo.allItems.push(questionModel);
        if (surveyComponent && question.settings) {
          if (
            question.settings.clEarlyTerminate === 'NOT_MET' &&
            !surveyComponent.isVisible(question, { ignoreHidden: true })
          ) {
            terminated = true;
            showTerminationError[question.id] = surveyComponent.isQuestionDirty(
              question,
              surveyComponent
            );
          }
        }
      }
    }

    if (hiddenPages.length) {
      hiddenPages.forEach((index) => {
        pagesInfo.pages.splice(index, 1, null);
      });
      pagesInfo.pages = pagesInfo.pages.filter((page) => page !== null);
    }
    pagesInfo.terminated = terminated;
    pagesInfo.showTerminationError = showTerminationError;
    return pagesInfo;
  };

  static SCROLL_CONTAINER = 'surveyWrapperScroll';
  state = { errors: {}, currentPage: 0, terminated: false };

  onSubmit = (isFinished) => {
    if (!isFinished) this.setState({ errors: {} });
    if (!isFinished || this.isValid()) this.props.onSubmit(isFinished);
  };

  getI18nString = (path, altPath) =>
    getSurveyI18n(
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      this.props.question,
      path,
      altPath
    );

  getCurrentItems = () => {
    const pageInfo = Survey.getPagesInfo(
      this.props.questions,
      this.props.layout,
      this
    );
    if (this.props.isPrinting || this.props.isReview)
      return pageInfo.allItems || [];
    if (this.props.skipConditionalLogic)
      return pageInfo.allPages[this.state.currentPage] || [];
    return pageInfo.pages[this.state.currentPage] || [];
  };

  isQuestionDirty(question, surveyComponent) {
    return question.conditional_logic.some(
      (condition) => surveyComponent.dirty[condition.question_id]
    );
  }
  dirty = {};

  isValid(questionIds = null) {
    const ids =
      questionIds !== null
        ? (Array.isArray(questionIds) && questionIds) || [questionIds]
        : null;
    const { questions, layout, answers } = this.props;
    const errors = {};
    const pageInfo = Survey.getPagesInfo(questions, layout);

    questions
      .filter((question) => !ids || ids.indexOf(question.id) !== -1)
      .forEach((question) => {
        if (!this.isVisible(question)) return;
        const answer = (answers || []).find((a) => a.id === question.id);
        const error = Question.validate(
          question,
          answer?.value,
          answer?.otherValue
        );
        if (error) {
          errors[question.id] = error;
        }
      });

    const isValid = Object.keys(errors).length === 0;

    let firstErrorPage = null;
    if (!isValid && pageInfo.pages.length > 1) {
      for (let page = 0; page < pageInfo.pages.length; page += 1) {
        for (
          let question = 0;
          question < pageInfo.pages[page].length;
          question += 1
        ) {
          if (errors[`${pageInfo.pages[page][question].id}`]) {
            firstErrorPage = page;
            break;
          }
        }
        if (typeof firstErrorPage === 'number') break;
      }
    }

    this.setState({ errors, currentPage: firstErrorPage || 0 }, () => {
      if (!isValid) {
        const errorEl = document.querySelectorAll("[class*='--error']");
        if (errorEl && errorEl.length) {
          errorEl[0]?.parentNode?.scrollIntoView();
        }
        this.props.surveyValidationFailed({
          title: <T>Survey validation failure</T>,
          statusText: (
            <T>To submit the survey please answer all the required questions</T>
          ),
        });
      }
    });

    return isValid;
  }

  findSection(question) {
    if (question.type === SECTION_BREAK) return null;
    let section;
    for (let i = 0; i < this.props.layout.length; i += 1) {
      if (this.props.layout[i].type === SECTION_BREAK) {
        section = this.props.layout[i];
      }
      if (
        question.id === this.props.layout[i].question_id ||
        question.id === this.props.layout[i].id
      ) {
        break;
      }
    }
    return section;
  }

  isVisible(question, { ignoreHidden = false, ignoreConditions = false } = {}) {
    if (this.props.isReview) return true;
    if (!ignoreHidden && question.settings && question.settings.sectionHidden) {
      return false;
    }
    const section = this.findSection(question);
    if (!!section && !this.isVisible(section, { ignoreHidden: true })) {
      return false;
    }
    const conditionsPassed = conditionsCheck(
      question,
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      this.props.answers
    );
    return (
      ignoreConditions || conditionsPassed || conditionsPassed === null || false
    );
  }

  handleChangePage = (page, relative) => {
    this.setState({
      currentPage: !relative ? page : this.state.currentPage + page,
    });
    document
      .querySelector(`[class*='${Survey.SCROLL_CONTAINER}']`)
      ?.parentNode?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      });
  };

  renderQuestion(question, i) {
    if (!question) return null;
    if (
      !this.props.isReview &&
      !this.isVisible(question, {
        ignoreConditions: this.props.skipConditionalLogic,
      })
    )
      return null;
    if (question.isLayout) {
      const LayoutElement = Question.getQuestionComponent(question);
      return (
        <LayoutElement
          question={question}
          key={i}
          survey={
            this.props.isAnswerMode && !this.props.isPreview
              ? this.props.surveyAnswers
              : this.props.survey
          }
        />
      );
    }

    const answer =
      (this.props.answers || []).find((a) => a.id == question.id) || {};
    const showTagSelectorReview =
      this.props.isReview && this.props.isEditing && ANSWERABLE;
    const isReadonly =
      this.props.isPreview ||
      (this.props.isReview && !this.props.isEditing) ||
      (this.props.isPrinting && !this.props.hideAnswers);
    const showReviewAttachment =
      this.props.isReview &&
      question.has_attachment &&
      !this.props.isAnswerMode &&
      answer.uid &&
      (this.props.isEditing ? true : !!answer.import_upload);
    const quoteAnswer = answer.value || answer.otherValue || '';
    const showQuoteSelectorReview =
      this.props.isReview &&
      this.props.isEditing &&
      quoteAnswer &&
      typeof quoteAnswer === 'string';
    const selectedQuoteAnswer = showQuoteSelectorReview ? quoteAnswer : '';

    const onAnswerChange = (value, tagIds) => {
      this.dirty[question.id] = true;
      this.props.onAnswerChange?.(question, value, tagIds ?? answer.tags);
    };
    const onTagsChange = (tagIds, tags) => {
      this.dirty[question.id] = true;
      this.props.onTagsChange?.(question, tagIds, tags);
    };
    const onAnswerChangeOther = (value) => {
      this.dirty[question.id] = true;
      this.props.changeAnswerOtherChoiceValue?.(question, value, answer.tags);
    };

    const getAnswerI18nString = (answer, path, altPath) =>
      getSurveyI18n(this.props.survey, answer, path, altPath);

    return (
      <div className={cx('printNonBreakable')} key={question.id || i}>
        <Card
          anchor
          flat={this.props.isAnswerMode}
          border={this.props.isAnswerMode}
          className={cx('printNonBreakable')}
        >
          <Question
            index={i}
            key={`question-${question.id}${answer.id}`}
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            question={question}
            answer={answer}
            error={this.state.errors[question.id]}
            value={
              this.props.isPrinting && this.props.hideAnswers
                ? undefined
                : this.props.survey.activeLanguage ===
                  this.props.survey.default_language
                ? answer.value
                : getAnswerI18nString(answer, 'value', 'value') || answer.value
            }
            onChange={(value, tagIds) => onAnswerChange(value, tagIds)}
            onTagsChange={(tagIds, tags) => onTagsChange(tagIds, tags)}
            changeAnswerOtherChoiceValue={(_, value) =>
              onAnswerChangeOther(value)
            }
            readOnly={isReadonly}
            isEditing={this.props.isEditing}
            isReview={this.props.isReview}
            isAnswerMode={this.props.isAnswerMode}
            forceMobileStyling={this.props.forceMobileStyling}
            onRefresh={this.props.onRefresh}
          />

          {this.props.isEditing && <br />}

          {(showTagSelectorReview || showQuoteSelectorReview) && (
            <CardFooter>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
                className={cx(
                  this.props.isEditing ? '' : 'editQuestionAttachmentsDisabled'
                )}
              >
                <AnswersTableTags
                  key={`tag-${question.id}${answer.id}`}
                  question={question}
                  answer={answer}
                  granteeSurveyId={this.props.granteeSurveyId}
                  isReview={true}
                />
                <span style={{ marginRight: 8, float: 'right' }}>
                  <QuotesList
                    key={selectedQuoteAnswer}
                    type="footer"
                    taggableType="survey_answers"
                    taggableUid={answer.uid}
                    selectedText={selectedQuoteAnswer}
                    selectionStart={i}
                    selectionEnd={i}
                  />
                </span>
              </div>
            </CardFooter>
          )}

          {showReviewAttachment && (
            <CardFooter>
              <UploadAttachment
                isReview={this.props.isReview || false}
                surveyAnswerId={answer.uid}
                importUpload={answer.import_upload}
                projectId={this.props.projectId}
                granteeSurveyId={this.props.granteeSurveyId}
              />
            </CardFooter>
          )}
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div
        className={cx('wrapper', Survey.SCROLL_CONTAINER, {
          'wrapper-mobile-styling': this.props.forceMobileStyling,
        })}
        style={{
          direction: rightAlignLanguages.includes(
            this.props.survey.activeLanguage
          )
            ? 'rtl'
            : 'initial',
        }}
      >
        <div>
          <div className={cx('surveyItemsList')}>
            {this.props.layout.length > 0 &&
              this.getCurrentItems()
                .filter((q) => Boolean(q?.id))
                .map((question, i) => this.renderQuestion(question, i))}
          </div>

          {!this.props.isReview && (
            <SurveyLegalInfo
              hasAddress={false}
              spaceTop={false}
              className={cls.surveyLegalInfo}
              organizationTitle={this.props.organization.title}
              organizationEmail={this.props.organization.email}
            />
          )}

          {!this.props.isReview && (
            <SurveyFooter
              isPending={this.props.isPending}
              isPreview={this.props.isPreview}
              isPrintable={this.props.isPrintable}
              isFixed={this.props.isAnswerMode && !this.props.isPreview}
              answersSavedSinceLastChange={
                this.props.isSaved && !this.props.isDirty
              }
              forceMobileStyling={this.props.forceMobileStyling}
              onSubmit={this.onSubmit}
              pageCount={
                Survey.getPagesInfo(
                  this.props.questions,
                  this.props.layout,
                  this
                ).pages.length
              }
              currentPage={this.state.currentPage}
              onChangePage={this.handleChangePage}
              terminated={
                Survey.getPagesInfo(
                  this.props.questions,
                  this.props.layout,
                  this
                ).terminated
              }
              showTerminationError={
                Survey.getPagesInfo(
                  this.props.questions,
                  this.props.layout,
                  this
                ).showTerminationError
              }
              layout={this.props.layout}
            />
          )}
        </div>
      </div>
    );
  }
}
