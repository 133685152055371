import PropTypes from 'prop-types';
import cond from 'ramda/src/cond';
import React from 'react';
import { reduxForm } from 'redux-form';

import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import FlexRow from 'src/components/FlexRow/FlexRow';
import { Checkbox, Container, Actions, Button } from 'src/components/IMUI';

import cls from './Search.module.css';
import { canManageTag } from 'src/userStorage';

const SearchAndTagReview = ({
  results,
  handleSubmit,
  onRequestClose,
  onItemChange,
}) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      {results.map((el, i) => (
        <FlexRow key={i} className={cls.reviewItem}>
          <FlexColumn size={1 / 5}>
            <Checkbox
              onCheck={(_event, isChecked) => {
                onItemChange(el, isChecked);
              }}
              checked={!el.disabled}
            />
          </FlexColumn>
          <FlexColumn size={4 / 5}>
            {el.before_match}{' '}
            <span className={cls.reviewSelected}>{el.full_content}</span>{' '}
            {el.after_match}
          </FlexColumn>
        </FlexRow>
      ))}

      <br />
      <br />
      <Container horizontal>
        <Actions>
          <Button label="Cancel" negative onClick={onRequestClose} />
        </Actions>
        <Actions>
          <Button disabled={!canManageTag()} type="submit" label="Apply tags" />
        </Actions>
      </Container>
    </form>
  );
};

SearchAndTagReview.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  onItemChange: PropTypes.func.isRequired,
};

function validate(values) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);

  return {
    text: cond([[not(isDefined), () => 'Search phrase is required']])(
      values.text
    ),
  };
}

export default reduxForm({
  form: 'searchAndTagReviewForm',
  validate,
  initialValues: {
    selection: null,
    tagId: null,
  },
})(SearchAndTagReview);
