import React from 'react';
import { T, withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import { CardFooter, ChipInput, Container, Tag } from 'src/components/IMUI';
import {
  KEYCODE_ENTER,
  KEYCODE_COMMA,
} from 'src/components/IMUI/Forms/ChipInput';
import createQuestion from './createQuestion';
import { TagNotProvided } from './TextQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
import COUNTRIES from 'src/data/countries.json';
import { translateCountryName } from 'src/utils/countries';
import { retrieveAnswerValue } from 'src/serializers/surveySerializer';
import { isArrayOfString } from 'src/pages/App/Analysis/Surveys/Survey/Summary/surveyAnalysisUtils';

@createQuestion
class CountryQuestion extends React.PureComponent {
  static propTypes = {
    answer: PropTypes.object,
    survey: PropTypes.object,
    surveyAnswers: PropTypes.object,
    onChange: PropTypes.func,
    onRefresh: PropTypes.func,
    question: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.any,
    ]),
    isReview: PropTypes.bool,
    isEditing: PropTypes.bool,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
  };

  static validate(question, answers = [], otherValue) {
    if (answers && !Array.isArray(answers))
      return 'Invalid answers type, should be array of strings';
    const distinctCount = retrieveAnswerValue(question, answers)?.length ?? 0;
    const otherCount = Number(
      Boolean(question.hasOther && otherValue?.length > 0)
    );
    const answersCount = distinctCount + otherCount;

    if (question.required && !answersCount) {
      return <T>Please select at least one answer</T>;
    }
    if (!question.settings || !question.settings.restrictAnswers) return null;
    if (answersCount > question.settings.maxAnswers) {
      return (
        <T
          message="Please do not select more than {{ maxAnswers }} option"
          messagePlural="Please do not select more than {{ maxAnswers }} options"
          count={question.settings.maxAnswers}
          maxAnswers={question.settings.maxAnswers}
        />
      );
    }
    if (answersCount < question.settings.minAnswers) {
      return (
        <T
          message="Please select at least {{ minAnswers }} option"
          messagePlural="Please select at least {{ minAnswers }} options"
          count={question.settings.minAnswers}
          minAnswers={question.settings.minAnswers}
        />
      );
    }
    return null;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onChange(value) {
    this.props.onChange?.(value);
  }

  // only in review mode, always read-only
  renderTags() {
    if (!this.props.isReview) return null;
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          {!this.props.answer.question_taggings.length &&
            this.props.isEditing && <TagNotProvided />}
          {this.props.answer.question_taggings?.map((tagging) => (
            <Tag label={tagging.tag.title} key={tagging.id} />
          ))}
        </Container>
      </CardFooter>
    );
  }

  onChangeCountries = (countries) => {
    if (!Array.isArray(countries)) return;

    const intersect = this.props.question.options
      ?.sort((a, b) => a.title?.localeCompare(b.title))
      ?.filter((o) => countries.includes(o.title));

    this.props.onChange?.(intersect.map((i) => i.title));
  };

  allCountryOptions = () => {
    return COUNTRIES.map(({ name }) => ({
      id: name,
      text: translateCountryName(name, this.props.survey.activeLanguage),
    }));
  };

  render() {
    const parsedValues = isArrayOfString(this.props.value)
      ? this.props.value
      : [];
    const dataSource =
      this.props.question.options?.length > 0
        ? this.props.question.options.map((o) => ({
            id: o.title,
            text: translateCountryName(
              o.title,
              this.props.survey.activeLanguage
            ),
          }))
        : this.allCountryOptions();
    return (
      <>
        <ChipInput
          key={`country-chip-input-${this.props.answer.answerId}`}
          borderDark
          newChipKeyCodes={[KEYCODE_COMMA]}
          chipProps={{ square: true }}
          type="array"
          name="items"
          component={ChipInput}
          dataSource={dataSource}
          dataSourceConfig={{ text: 'text', value: 'id' }}
          hintText="Type in Country..."
          onChange={this.onChangeCountries}
          value={parsedValues}
          canAddNewValues={false}
          readOnly={this.props.readOnly}
        />

        {this.renderTags()}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </>
    );
  }
}

const enhancedComponent = withTranslators(CountryQuestion);
enhancedComponent.validate = CountryQuestion.validate;
export default enhancedComponent;
