import React, { useEffect, useState } from 'react';
import {
  Actions,
  Button,
  Card,
  CardEmpty,
  Container,
  Progress,
  Section,
  Toggle,
} from 'src/components/IMUI';
import FinishDialog from './components/FinishDialog';
import grantsImporterApi from 'src/api/GrantsImport';
import { getOrgText } from 'src/services/DictionaryService';
import store from 'src/store';
import { where } from 'im/api/Query';
import cls from './UploadCSV.module.css';
import PreviewDataTable from './components/PreviewDataTable';

const ValidateCSV = ({ history, match }) => {
  const [invalidOnly, setInvalidOnly] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [results, setResults] = useState({});
  const [downloadUrl, setDownloadUrl] = useState();
  const [finishDialogOpen, setFinishDialogOpen] = useState(false);

  useEffect(() => {
    if (!isFetching || Object.keys(results).length !== 0) {
      return;
    }

    store
      .dispatch(grantsImporterApi.find(where({ id: match.params.fileId })))
      .then((response) => {
        setResults(response.results);
        setDownloadUrl(response.results.download_url);
        setIsFetching(false);
      });
  }, [match.params.fileId, downloadUrl, isFetching, results]);

  if (!isFetching && Object.keys(results).length === 0) {
    return (
      <CardEmpty
        large
        title="No valid data"
        description="Please go back to the previous step and upload your CSV."
        style={{ height: 80, marginTop: 40, marginBottom: 40 }}
      >
        <Button
          negative
          size="l"
          label="Back"
          onClick={() => history.push('/grants/import2')}
        />
      </CardEmpty>
    );
  }

  const onDownloadCsv = () => {
    const link = document.createElement('a');

    link.setAttribute('href', downloadUrl);
    link.click();
  };

  const filterNotes = (notes) => notes.filter((note) => Boolean(note.changes));

  const onDownloadChangesCsv = () => {
    const link = document.createElement('a');

    const headers = ['Import Status', 'Has Error?', ...results.headers];
    const csvContent = [
      headers.join(','),
      ...(results?.rows.map((row) =>
        [
          `"${JSON.stringify(filterNotes(row.notes)).replace(/"/g, "'")}"`,
          row.has_error,
          ...row.row.map((col) => `\"${col}\"`),
        ].join(',')
      ) || []),
    ].join('\n');

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'Grant Changes');
    // Append link to body, trigger download, and remove link
    document.body.appendChild(link);
    link.click();
  };

  const onImport = () => {
    store
      .dispatch(grantsImporterApi.put(where({ id: match.params.fileId })))
      .then((response) => {
        setFinishDialogOpen(true);
      });
  };

  return (
    <Container>
      <FinishDialog
        open={finishDialogOpen}
        uploadId={match.params.fileId}
        onClose={() => {
          setFinishDialogOpen(false);
        }}
        onFinishClick={() => {
          history.push({
            pathname: `/grants/list/grant`,
          });
        }}
      />
      <Section collapsed noBorder>
        {isFetching ? (
          <Container horizontal centered>
            <Section>
              <h3> Validating your data... </h3>
            </Section>
          </Container>
        ) : (
          <Container>
            <h3 className={cls.title}>
              {getOrgText('Grants')} review (
              {`${results.valid_csv_rows} / ${results.total_rows}`})
            </h3>
            <div className={cls.subtitle}>
              <p>
                The following {getOrgText('grants')} will be imported to
                ImpactMapper.
              </p>
              <Toggle
                small
                compact
                label={<span>Show rows with errors only</span>}
                labelPosition="right"
                toggled={invalidOnly}
                onToggle={() => setInvalidOnly(!invalidOnly)}
              />
            </div>
          </Container>
        )}
      </Section>
      {isFetching ? (
        <Container centerHorizontal>
          <Progress large />
        </Container>
      ) : (
        <Section collapsed noBorder>
          <Card>
            <PreviewDataTable invalidOnly={invalidOnly} results={results} />
          </Card>
        </Section>
      )}
      <Section type="sticky-footer">
        <Actions>
          <Button
            negative
            size="l"
            label="Back"
            onClick={() => history.push('/grants/import2')}
          />
        </Actions>
        <Actions>
          <Button
            disabled={!downloadUrl}
            secondary
            size="l"
            label="Download Changes CSV"
            onClick={onDownloadChangesCsv}
          />
          <Button
            disabled={!downloadUrl}
            secondary
            size="l"
            label="Download CSV"
            onClick={onDownloadCsv}
          />
          <Button size="l" label="Import" onClick={onImport} />
        </Actions>
      </Section>
    </Container>
  );
};

export default ValidateCSV;
