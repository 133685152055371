/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import uniq from 'ramda/src/uniq';
import {
  Dialog,
  TextField,
  Button,
  Container,
  Actions,
} from 'src/components/IMUI';
import { escapeForRegExp } from 'src/utils/string';

import { Icon } from 'im/ui/Icon';

import SearchAndTag from './SearchAndTag';
import SearchAndTagReview from './SearchAndTagReview';
import { applyExpansionStrategy } from './searchExpansionStrategy';

import cls from './Search.module.css';
import { canManageTag } from 'src/userStorage';

const findString = (text, term) =>
  term?.length > 0
    ? Array.from(text.matchAll(new RegExp(escapeForRegExp(term), 'gi'))).map(
        (match) => match.index + match[0].length
      )
    : [];

const getNextSearch = (quill, direction, searchText, stateEnd) => {
  const quillText = () => quill?.getText() ?? '';
  const occurences = findString(quillText(), searchText);
  if (occurences.length === 0) return;
  const current = occurences.indexOf(stateEnd);
  const end =
    occurences[(current + direction) % occurences.length] ||
    occurences[occurences.length - 1];
  if (end == undefined) return;
  if (!occurences?.includes(end)) return;
  return { start: end - searchText.length, end: end };
};
const getNextTag = (direction, taggings, startTag, endTag) => {
  const occurences = taggings.data.map((c) => ({
    content: c.content,
    report_content_end: c.report_content_end,
    report_content_start: c.report_content_start,
  }));
  if (occurences.length === 0) return;
  const areSimilar = (a, b) =>
    a.report_content_end == b.report_content_end &&
    a.report_content_start == b.report_content_start;
  const uniqueTags = occurences.reduce(
    (acc, cur) =>
      !acc.some((item) => areSimilar(item, cur)) ? [...acc, cur] : acc,
    []
  );
  const currentIndex = uniqueTags.findIndex(
    (i) => i.report_content_end == endTag && i.report_content_start == startTag
  );
  return (
    uniqueTags[(currentIndex + direction) % uniqueTags.length] ||
    uniqueTags[uniqueTags.length - 1]
  );
};

export default class Search extends React.PureComponent {
  static propTypes = {
    quill: PropTypes.object.isRequired,
    setSelection: PropTypes.func.isRequired,
    taggings: PropTypes.object,
    tagAll: PropTypes.func.isRequired,
  };

  state = {
    start: undefined,
    end: undefined,
    searchText: '',
    searchAndTagVisible: false,
    reviewVisible: false,
    searchAndTagResults: [],
    startTag: undefined,
    endTag: undefined,
  };

  onChangeSearch = (searchText) => {
    this.setState({ searchText });
  };

  onClickPrevious = () => {
    this.moveSelection(-1);
  };
  onClickTagPrevious = () => {
    this.moveTagSelection(-1);
  };

  onClickNext = () => {
    this.moveSelection(+1);
  };
  onClickTagNext = () => {
    this.moveTagSelection(+1);
  };

  quillText = () => {
    return this.props.quill?.getText() ?? '';
  };

  onSearchAndTagProceed = (values) => {
    const occurences = findString(this.quillText(), values.text).map(
      (found) => ({ start: found - values.text.length, end: found })
    );
    let expanded = [];
    try {
      expanded = occurences.map((el) =>
        applyExpansionStrategy(
          values.selection,
          this.quillText(),
          el.start,
          el.end
        )
      );
    } catch (e) {
      console.error(e);
    }
    this.setState({
      searchAndTagVisible: false,
      searchAndTagResults: uniq(expanded),
      reviewVisible: true,
      searchAndTagSelectedTag: values.tagId,
    });
  };

  onSearchAndTagReviewProceed = (values) => {
    const checkedItems = values.items.filter((el) => !el.disabled);
    if (!checkedItems.length) return;

    this.moveSelection();
    const formattedItems = checkedItems.map((el) => ({
      ...el,
      tag: { id: this.state.searchAndTagSelectedTag },
    }));
    this.props.tagAll(formattedItems);
  };

  onSearchAndTagItemChange = (item, isSelected) => {
    const rest = this.state.searchAndTagResults.filter(
      (el) => el.start !== item.start && el.end !== item.end
    );
    const needle = this.state.searchAndTagResults.find(
      (el) => el.start === item.start && el.end === item.end
    );
    needle.disabled = !isSelected;
    this.setState({ searchAndTagResults: [...rest, needle] });
  };

  displaySearchAndTag = () => {
    this.setState({ searchAndTagVisible: true });
  };

  hideSearchAndTag = () => {
    this.setState({ searchAndTagVisible: false });
  };

  hideReview = () => {
    this.setState({ reviewVisible: false });
  };

  moveSelection = (direction = 1) => {
    const current = getNextSearch(
      this.props.quill,
      direction,
      this.state.searchText,
      this.state.end
    );
    if (!current) return;
    this.setState({ start: current.start, end: current.end });
    this.props.setSelection({
      startPosition: current.start,
      endPosition: current.end,
      cursorPosition: current.start,
    });
  };
  moveTagSelection = (direction = 1) => {
    const current = getNextTag(
      direction,
      this.props.taggings,
      this.state.startTag,
      this.state.endTag
    );
    if (!current) return;
    this.setState({
      startTag: current.report_content_start,
      endTag: current.report_content_end,
    });
    this.props.setSelection({
      startPosition: current.report_content_start,
      endPosition: current.report_content_end,
      cursorPosition: current.report_content_start,
    });
  };

  render() {
    const occurences = findString(this.quillText(), this.state.searchText);
    return (
      <div>
        <TextField
          flatDark
          noValidation
          wrapperClassName={cls.searchField}
          hintText={
            <span className={cls.searchHintText}>
              <Icon name="search" /> Type in to search document
            </span>
          }
          value={this.state.searchText}
          onChange={this.onChangeSearch}
        />

        <Container horizontal className={cls.searchContainerBottom}>
          <Actions>
            <Container horizontal nowrap className={cls.searchActions}>
              <Button
                border
                secondary
                className={cls.searchTaggingsButton}
                icon={<Icon name="chevron-up" tip="Last Tag" />}
                onClick={this.onClickTagPrevious}
              />
              <span>&nbsp;&nbsp;</span>
              <Button
                border
                secondary
                className={cls.searchTaggingsButton}
                icon={<Icon name="chevron-down" tip="Next Tag" />}
                onClick={this.onClickTagNext}
              />
            </Container>
          </Actions>

          <Actions>
            {this.state.searchText?.length > 0 && (
              <Container horizontal nowrap className={cls.searchActions}>
                {!!this.state.searchText && (
                  <small className={cls.searchStats}>
                    {!occurences?.length
                      ? 'No matches found'
                      : `Matches: ${
                          Math.max(occurences.indexOf(this.state.end), 0) + 1
                        }/${occurences.length}`}
                  </small>
                )}
                <span>&emsp;</span>
                <Button
                  border
                  secondary
                  className={cls.searchActionsButton}
                  icon={<Icon name="chevron-up" />}
                  onClick={this.onClickPrevious}
                />
                <span>&nbsp;&nbsp;</span>
                <Button
                  border
                  secondary
                  className={cls.searchActionsButton}
                  icon={<Icon name="chevron-down" />}
                  onClick={this.onClickNext}
                />
                <span>&emsp;</span>
                {canManageTag() && (
                  <Button
                    secondary
                    onClick={this.displaySearchAndTag}
                    label="Search and Tag"
                    size="s"
                    icon={<Icon name="tag" />}
                  />
                )}
              </Container>
            )}
          </Actions>
        </Container>

        <Dialog
          title="Search and Tag"
          titleStyle={{ fontSize: 16 }}
          contentStyle={{ width: '55%', maxWidth: '576px' }}
          modal={false}
          open={this.state.searchAndTagVisible}
          onRequestClose={this.hideSearchAndTag}
        >
          <SearchAndTag
            onSubmit={(v) => this.onSearchAndTagProceed(v)}
            initialValues={{ text: this.state.searchText }}
            onRequestClose={this.hideSearchAndTag}
            isReview={this.state.searchAndTagResults.length}
            results={this.state.searchAndTagResults}
          />
        </Dialog>

        {this.state.reviewVisible && (
          <Dialog
            title="Search and Tag"
            titleStyle={{ fontSize: 16 }}
            contentStyle={{ width: '55%', maxWidth: '576px' }}
            modal={false}
            open={this.state.reviewVisible}
            onRequestClose={this.hideReview}
            autoScrollBodyContent
          >
            <SearchAndTagReview
              quill={this.props.quill}
              results={this.state.searchAndTagResults.sort(
                (a, b) => a.start - b.start
              )}
              onSubmit={(values) => {
                this.onSearchAndTagReviewProceed(values);
                this.hideReview();
              }}
              initialValues={{ items: this.state.searchAndTagResults }}
              onItemChange={this.onSearchAndTagItemChange}
              onRequestClose={this.hideReview}
            />
          </Dialog>
        )}
      </div>
    );
  }
}
