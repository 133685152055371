import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Progress,
  TextField,
  Tooltip,
} from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import { where } from 'im/api/Query';
import aiChartInsightsApi from 'src/api/AIChartInsights';
import aiInsightsPng from 'src/static/empty_chart.png';
import aiInsightsPng2 from 'src/static/empty_responses.png';
import waiting from 'src/static/waiting.png';
import cls from './AIChartInsights.module.css';

const AIChartInsights = ({
  onFetchInsights,
  chartInsights,
  chartId,
  projectId,
  updateChartInsights,
}) => {
  const [insights, setInsights] = useState(chartInsights);
  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(insights);
  const [showInsightsTip, setShowInsightsTip] = useState(false);

  const fetchInsights = () => {
    setIsFetching(true);
    onFetchInsights().then((response) => {
      setInsights(response.insights);
      setIsFetching(false);
    });
  };

  const onEditInsights = () => {
    setIsEditing(true);
    setInputValue(insights);
  };

  const onCancelEditInsights = () => {
    setIsEditing(false);
  };

  const onInsightsChange = (editedInput) => {
    setInputValue(editedInput);
  };

  const onSaveInsights = () => {
    updateChartInsights(
      where({ chart_id: chartId }).payload({
        project_id: projectId,
        insights: inputValue,
      })
    ).then(() => {
      setInsights(inputValue);
      setIsEditing(false);
    });
  };

  return (
    <Container>
      <div className={cls.aiInsightsContainer}>
        <h3 className={cls.insightsHeader}>Insights</h3>
        {!isFetching && (
          <div
            data-tip
            data-for={'insights-tooltip'}
            className={cls.insightsTipContainer}
          >
            <div
              className={cls.aiEyeIconContainer}
              onMouseEnter={() => setShowInsightsTip(true)}
              onMouseLeave={() => setShowInsightsTip(false)}
            >
              <Icon name="view" className={cls.aiEyeIcon} color="#626264" />
            </div>
            {showInsightsTip && (
              <Tooltip
                id={'insights-tooltip'}
                className={cls.insightsTip}
                place="right"
                multiline={true}
              >
                {
                  'Chart insights are generated using Artificial Intelligence (A.I.) and may vary in quality and accuracy. Please review the content carefully and make any necessary edits before using it.'
                }
              </Tooltip>
            )}
          </div>
        )}

        {isFetching && <Progress />}
      </div>
      {!insights && !isFetching && (
        <div className={cls.insightsButtonContainer}>
          <img src={aiInsightsPng2} alt="AI insights Female" width={200} />
          <Button
            secondary
            className={cls.fetchInsightsButton}
            label={'Fetch AI Insights'}
            onClick={fetchInsights}
          />
          <img src={aiInsightsPng} alt="AI insights Male" width={200} />
        </div>
      )}
      {insights && !isFetching && (
        <div>
          {isEditing ? (
            <>
              <div className={cls.insightsContainer}>
                <img src={waiting} alt="AI insights" width={200} />
                <TextField
                  multiLine
                  rows={8}
                  rowsMax={8}
                  fullWidth
                  borderDark
                  flat
                  value={inputValue}
                  onChange={onInsightsChange}
                />
              </div>

              <div className={cls.insightsActionButtonsContainer}>
                <Button
                  secondary
                  label={'CANCEL'}
                  onClick={onCancelEditInsights}
                />
                <div>
                  <Button
                    className={cls.fetchInsightsButton}
                    label={'SAVE'}
                    onClick={onSaveInsights}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={cls.insightsContainer}>
                <img src={waiting} alt="AI insights" width={200} />
                <i className={cls.insights}>{insights}</i>
              </div>

              <div className={cls.insightsActionButtonsContainer}>
                <Button
                  secondary
                  icon={<Icon name="edit" />}
                  label={'EDIT'}
                  onClick={onEditInsights}
                />
                <div>
                  <Button
                    className={cls.fetchInsightsButton}
                    icon={<Icon name="rotate" />}
                    label={'REGENERATE'}
                    onClick={fetchInsights}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  );
};

AIChartInsights.proptype = {
  onFetchInsights: PropTypes.func.isRequired,
  chartInsights: PropTypes.string,
  multiLine: PropTypes.bool,
  chartId: PropTypes.number,
  projectId: PropTypes.number,
  updateChartInsights: PropTypes.func,
};

const connection = connect(() => ({}), {
  updateChartInsights: aiChartInsightsApi.put,
});
export default connection(AIChartInsights);
