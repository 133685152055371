import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Highlight, PdfHighlighter, PdfLoader } from 'react-pdf-highlighter';
import {
  TAGGING_OPTIONS,
  getAutoSuggestedTaggingType,
  getCurrencyFromText,
  getNumberFromText,
} from 'src/components/TaggingType/taggingTypeHelper';

import { Icon } from 'im/ui/Icon/Icon';
import {
  Button,
  Container,
  Dialog,
  Progress,
  SelectField,
  Tag,
} from 'src/components/IMUI';
import colors from 'src/css/constants.json';
import cls from './AITaggingSuggestionEditor2.module.css';

const AITaggingSuggestionEditor2 = ({
  pdfUrl,
  pdfScaleValue,
  scrollToTagging,
  taggingSuggestion,
  onRequestClose,
  onRequestSaveAndAccept,
  onRequestDecline,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [selection, setSelection] = useState({});
  const [highlights, setHighlights] = useState([]);
  const pdfHighlighterRef = useRef();

  const onPageClick = useCallback(
    (event) => {
      setSelection({});
    },
    [setSelection]
  );

  useEffect(() => {
    if (pdfUrl) {
      setLoaded(true); // Only load PDF once when pdfUrl is available
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (isDocumentLoaded) {
      scrollToTagging(taggingSuggestion, pdfHighlighterRef);
    }
  }, [taggingSuggestion, isDocumentLoaded, pdfHighlighterRef, scrollToTagging]);

  useEffect(() => {
    if (!isDocumentLoaded || !pdfHighlighterRef?.current?.containerNode) return;

    const containerNode = pdfHighlighterRef?.current?.containerNode;
    const allPages = containerNode.querySelectorAll('.page');

    allPages.forEach((page) => {
      page.addEventListener('click', onPageClick);
    });

    return () => {
      allPages.forEach((page) => {
        page.removeEventListener('click', onPageClick);
      });
    };
  }, [isDocumentLoaded, onPageClick, pdfHighlighterRef]);

  const onSelectionFinished = useCallback(
    (position, content) => {
      setSelection({
        misc: { position: position },
        content: content.text,
        type: 'taggings',
      });

      return null;
    },
    [setSelection]
  );
  const [selectedTextType, setSelectedTextType] = useState('default');
  const [taggingTypeParams, setTaggingTypeParams] = useState({});

  const prepareParams = useCallback(
    (suggestedType) => {
      if (suggestedType === 'currency') {
        setTaggingTypeParams(getCurrencyFromText(selection.content) || {});
      } else if (suggestedType === 'location') {
        setTaggingTypeParams({ location: selection.content });
      } else if (suggestedType === 'number') {
        setTaggingTypeParams(getNumberFromText(selection.content) || {});
      } else if (suggestedType === 'default') {
        setTaggingTypeParams({});
      }

      setSelectedTextType(suggestedType);
    },
    [setSelectedTextType, selection?.content]
  );

  useEffect(() => {
    const suggestedType = getAutoSuggestedTaggingType(selection.content);
    prepareParams(suggestedType);
    setSelectedTextType(suggestedType);
  }, [selection, prepareParams]);

  const onSelectedTextTypeChange = ({ value }) => {
    prepareParams(value);
  };

  useEffect(() => {
    const backgroundStyle = {
      backgroundImage: 'linear-gradient(to right, #34aecf, #15cf6d)',
    };
    setHighlights([
      {
        style: backgroundStyle,
        isScrolledTo: true,
        content: { text: taggingSuggestion?.content },
        comment: { emoji: '', text: taggingSuggestion.tag?.title },
        ...(taggingSuggestion?.misc || {}),
      },
    ]);
  }, [taggingSuggestion, selection]);

  const renderHighlighter = useCallback(
    (pdfDocument) => {
      if (pdfDocument) {
        return (
          <PdfHighlighter
            key={(pdfScaleValue / 100).toString()}
            ref={pdfHighlighterRef}
            pdfScaleValue={(pdfScaleValue / 100).toString()}
            pdfDocument={pdfDocument}
            onSelectionFinished={onSelectionFinished}
            highlights={highlights}
            highlightTransform={(highlight) => {
              return (
                <div
                  style={highlight.style}
                  className={highlight.style ? 'imHighlight' : ''}
                >
                  <Highlight
                    isScrolledTo={highlight.isScrolledTo}
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                </div>
              );
            }}
          />
        );
      }

      return <Progress />;
    },
    [pdfScaleValue, highlights, onSelectionFinished, pdfHighlighterRef]
  );

  const loadPdf = useCallback(() => {
    if (!loaded) return;

    return (
      <PdfLoader url={pdfUrl} beforeLoad={<Progress />}>
        {(pdfDocument) => {
          if (!isDocumentLoaded)
            setTimeout(() => {
              setIsDocumentLoaded(true);
            }, 500);
          return renderHighlighter(pdfDocument);
        }}
      </PdfLoader>
    );
  }, [loaded, pdfUrl, isDocumentLoaded, renderHighlighter]);

  return (
    <Dialog
      title="Edit Tagging Suggestion"
      forceTop
      extraLarge
      open={true}
      onRequestClose={onRequestClose}
      bodyStyle={{
        width: '100%',
      }}
      contentStyle={{
        width: '100%',
      }}
      leftActions={
        <Button size="l" label="Close" negative onClick={onRequestClose} />
      }
      rightActions={
        <div className={cls.taggingSuggestionEditorActions}>
          <Button
            size="l"
            alert
            secondary
            label="Delete Suggestion"
            onClick={() => {
              onRequestDecline(taggingSuggestion);
              onRequestClose();
            }}
          />
          <Button
            size="l"
            label="Save and Accept"
            onClick={() => {
              onRequestSaveAndAccept(taggingSuggestion, {
                ...selection,
                ...taggingTypeParams,
              });
              onRequestClose();
            }}
          />
        </div>
      }
    >
      <Container>
        <div className={cls.taggingSuggestionEditorContent}>
          <section className={cls.reportPreviewContainer}>
            <label style={{ fontSize: 15 }}>Report preview:</label>
            <div className={cls.grandContainer}>{loadPdf()}</div>
          </section>

          <section className={cls.suggestedTaggingContainer}>
            <div className={cls.suggestedTaggingHeader}>
              Suggested Tagging:
              <Tag
                label={
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {taggingSuggestion.tag.title}
                  </span>
                }
                grey
                square
              />
              <SelectField
                flat
                outline
                noValidation
                className={cls.suggestedTaggingTypeDropdown}
                value={selectedTextType}
                selectedMenuItemStyle={{
                  fontWeight: 'bold',
                  color: colors.dark,
                }}
                onChange={onSelectedTextTypeChange}
              >
                {TAGGING_OPTIONS.map((option) => (
                  <SelectField.Item
                    className={cls.detailsSelectorDropdownItem}
                    key={option.value}
                    value={option.value}
                    primaryText={option.label}
                    leftIcon={
                      <Icon {...option.leftIconProps} color="##666666" />
                    }
                    rightIcon={
                      <Icon {...option.rightIconProps} color={colors.green} />
                    }
                  />
                ))}
              </SelectField>
            </div>
            <div className={cls.suggestedTaggingSelectedText}>
              {selection.content || taggingSuggestion.content}
            </div>
          </section>
        </div>
      </Container>
    </Dialog>
  );
};

AITaggingSuggestionEditor2.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  pdfScaleValue: PropTypes.number,
  scrollToTagging: PropTypes.func,
  taggingSuggestion: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onRequestSaveAndAccept: PropTypes.func.isRequired,
  onRequestDecline: PropTypes.func.isRequired,
};

export default AITaggingSuggestionEditor2;
