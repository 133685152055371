import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import {
  getSurveyReview,
  changeSurveyProperty,
  updateSurveyAnswer,
  createSurveyAnswer,
  changeReviewAnswerTags,
  changeReviewAnswerValue,
  changeReviewAnswerOtherChoiceValue,
  changeReviewAnswerTaggings,
  changeReviewAnswerTaggingsRemove,
  updateGranteeSurvey,
} from 'src/actionCreators/surveyActionCreators';
import surveyRespondentsApi from 'src/api/SurveyRespondents';
import {
  Actions,
  Container,
  Section,
  Toggle,
  DownloadAsPdfWidget,
  Progress,
  Card,
  SelectPopoverLight,
  Button,
  Dialog,
} from 'src/components/IMUI';
import { ProgressBar } from 'src/components/IMUI/ProgressBar/ProgressBar';
import Survey from 'src/components/Survey/Survey';
import SurveyEditFooter from '../components/SurveyEditFooter';
import { toApiAnswerFromSurveyAnswers } from 'src/serializers/surveySerializer';
import { getText } from 'src/services/DictionaryService';
import { toLocaleDateTime } from 'src/utils/date';
import { where } from 'im/api/Query';
import { Icon } from 'im/ui/Icon';

import EditContactModal from '../components/EditContactModal';
import cls from './Response.module.css';
import {
  canBuildSurvey,
  canManageSurvey,
  canAiTranslate,
} from 'src/userStorage';
import { DEFAULT_SERIES } from '../Share/components/SettingBatch';
import granteeSurveyApi from 'src/api/GranteeSurvey';
import granteeSurveysApi from 'src/api/GranteeSurveys';
import colors from 'src/css/constants.json';
import { containsText, dedummy } from 'src/utils/string';
import history from 'src/historyInstance';

const TRANSLATION_MAX_ATTEMPTS = 20;
const TRANSLATION_POLLING_INTERVAL = 5000;

@connect(
  (state) => ({
    surveyReview: state.surveyReview,
    survey: state.survey,
  }),
  {
    findRespondents: granteeSurveysApi.responses.findRespondents,
    updateRespondent: surveyRespondentsApi.put,
    translateSurveyAnswers: granteeSurveyApi.translate,
    getSurveyReview,
    updateSurveyAnswer,
    createSurveyAnswer,
    changeReviewAnswerTags,
    changeReviewAnswerValue,
    changeReviewAnswerOtherChoiceValue,
    changeReviewAnswerTaggings,
    changeReviewAnswerTaggingsRemove,
    changeSurveyProperty,
    updateGranteeSurvey,
    showNotification,
  }
)
export default class SurveyResponse extends React.PureComponent {
  static propTypes = {
    match: ReactRouterPropTypes.match,
    findRespondents: PropTypes.func.isRequired,
    getSurveyReview: PropTypes.func.isRequired,
    updateRespondent: PropTypes.func.isRequired,
    updateSurveyAnswer: PropTypes.func.isRequired,
    createSurveyAnswer: PropTypes.func.isRequired,
    changeReviewAnswerTags: PropTypes.func.isRequired,
    changeReviewAnswerValue: PropTypes.func.isRequired,
    updateGranteeSurvey: PropTypes.func.isRequired,
    changeReviewAnswerOtherChoiceValue: PropTypes.func.isRequired,
    changeSurveyProperty: PropTypes.func,

    surveyReview: PropTypes.object.isRequired,
    tagsWithTagGroups: PropTypes.array,
    survey: PropTypes.object,
    activeStepId: PropTypes.number,
    showNotification: PropTypes.func,
    translateSurveyAnswers: PropTypes.func,
  };

  state = {
    isEditing: false,
    isShowingStaticElements: false,
    respondents: [],
    translationIntervalId: null,
    translationPollAttemptCount: 0,
  };

  componentDidMount() {
    this.getReview(true);
    this.props
      .findRespondents({ surveyId: Number(this.props.match.params.surveyId) })
      .then((a) =>
        this.setState({ respondents: this.filterRespondents(a?.data ?? []) })
      );
  }

  getReview(isInit) {
    this.props.getSurveyReview(
      this.props.match.params.projectId,
      this.props.match.params.granteeSurveyId,
      isInit
    );
  }

  filterRespondents = (respondents) =>
    [
      {
        id: this.props.match.params.granteeSurveyId,
        name: `Respondent ${this.props.match.params.granteeSurveyId}`,
      },
    ]
      .concat(
        respondents.map((u) => ({
          id: u.id,
          name: this.getRespondentName(u),
          is_finished: u.is_finished,
        }))
      )
      .filter((v, i, arr) => arr.findLastIndex((w) => w.id == v.id) === i);

  handleTranslateSurveyResponses = () => {
    if (!canAiTranslate()) {
      return;
    }

    this.props
      .translateSurveyAnswers(
        where({ id: this.props.match.params.granteeSurveyId })
      )
      .then(() => {
        this.props.getSurveyReview(
          this.props.match.params.projectId,
          this.props.match.params.granteeSurveyId,
          {
            silent: true,
          }
        );
        this.startTranslationPolling();
      });
  };

  onSaveSurveyEdits = () => {
    const { surveyReview } = this.props;

    surveyReview.answers
      .filter((answer) => surveyReview.dirtyIds.includes(answer.id))
      .forEach((answer) => {
        const question = surveyReview.questions.find((q) => q.id === answer.id);
        const response = toApiAnswerFromSurveyAnswers(question, [answer]);
        // update dirty answers 1 by 1
        answer.answerId
          ? this.props.updateSurveyAnswer(answer.answerId, {
              survey_answer: { id: answer.answerId, ...response },
            })
          : this.props.createSurveyAnswer({
              grantee_survey_id: this.props.match.params.granteeSurveyId,
              ...response,
            });
      });

    this.setState({ isEditing: false });
  };

  setLanguageToDefault = () => {
    const defaultLanguage = this.props.survey.languages[0];
    this.props.changeSurveyProperty('activeLanguage', defaultLanguage, {
      silent: true,
    });
  };

  onToggleIsEditing = (ev, isEditing) => {
    if (isEditing) {
      this.setLanguageToDefault();
    }
    this.setState({ isEditing });
  };

  onToggleShowingStaticElements = (ev, isShowingStaticElements) => {
    this.setState({ isShowingStaticElements });
  };

  onAnswerChange = (question, value, tagIds) => {
    this.props.changeReviewAnswerValue(question.id, value, tagIds);
  };

  onAnswerOtherChange = (question, value, tagIds) => {
    this.props.changeReviewAnswerOtherChoiceValue(question.id, value, tagIds);
  };

  onTagsChange = (question, tagIds, tags) => {
    this.props.changeReviewAnswerTags(question.id, tagIds, tags);
  };

  handleEditContact = () => {
    this.setState({ editContactOpen: true });
  };

  handleCancelEditContact = () => {
    this.setState({ editContactOpen: false });
  };

  handleSubmitEditContact = async ({
    name,
    email,
    batch,
    grant_id: grantId,
    grantee_id: granteeId,
  } = {}) => {
    const {
      surveyReview,
      match: { params },
    } = this.props;

    this.handleCancelEditContact();

    const respondentId = surveyReview.info.respondent_id;
    const surveyId = surveyReview.info.survey_id;
    const query = where({ id: respondentId, survey_id: surveyId })
      .payload({ name, email, batch, grant_id: grantId, grantee_id: granteeId })
      .actionMeta({ noSync: true });

    await this.props.updateRespondent(query);
    this.props.getSurveyReview(params.projectId, params.granteeSurveyId, true);
  };

  onNavigateRespondent = (gsId) => {
    if (this.props.match.params.granteeSurveyId != gsId)
      history.push(
        `/analysis/${this.props.match.params.projectId}/surveys/${this.props.match.params.surveyId}/responses/${gsId}`
      );
  };
  handlePrevRespondent = (ev) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    this.handleNavigateRespondent(-1);
  };
  handleNextRespondent = (ev) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    this.handleNavigateRespondent(1);
  };
  handleNavigateRespondent = (addIndex) => {
    const isForward = addIndex === 1;
    const isRewind = addIndex === -1;
    const currIndex = this.state.respondents.findIndex(
      (r) => r.id == this.props.match.params.granteeSurveyId
    );
    const isLast = currIndex == this.state.respondents.length - 1;
    if (isLast && isForward && this.state.respondents[0])
      return this.onNavigateRespondent(this.state.respondents[0]?.id);
    if (
      currIndex == 0 &&
      isRewind &&
      this.state.respondents[this.state.respondents.length - 1]
    )
      return this.onNavigateRespondent(
        this.state.respondents[this.state.respondents.length - 1]?.id
      );
    const nextIndex = currIndex + addIndex;
    return (
      nextIndex >= 0 &&
      nextIndex < this.state.respondents.length &&
      this.onNavigateRespondent(this.state.respondents[nextIndex]?.id)
    );
  };

  getRespondentName = (r) =>
    [
      r?.respondent_name ?? `Respondent ${r?.id}`,
      r?.batch && r?.batch !== DEFAULT_SERIES
        ? `Series: ${r?.batch}`
        : undefined,
      dedummy(r?.grantee_name).length
        ? `${getText('Grant')}: ${dedummy(r?.grantee_name) || ''}`
        : undefined,
      dedummy(r?.grant_name).length
        ? `${getText('Grantee')}: ${dedummy(r?.grant_name) || ''}`
        : undefined,
    ]
      .filter(Boolean)
      .join(' | ');

  renderInfo() {
    const {
      surveyReview: { info },
    } = this.props;
    const series =
      this.props.surveyReview.info?.batch &&
      this.props.surveyReview.info?.batch !== DEFAULT_SERIES
        ? `Series: ${this.props.surveyReview.info?.batch}`
        : undefined;
    const grant = dedummy(this.props.surveyReview.info?.grantee_name).length
      ? `${getText('Grant')}: ${
          dedummy(this.props.surveyReview.info?.grantee_name) || ''
        }`
      : undefined;
    const grantee = dedummy(this.props.surveyReview.info?.grant_name).length
      ? `${getText('Grantee')}: ${
          dedummy(this.props.surveyReview.info?.grant_name) || ''
        }`
      : undefined;
    const isNotified = info.is_notified && info.notified_at;
    return (
      <div className={cls.reviewHead}>
        <Container className={cls.surveyTitle} style={{ textAlign: 'center' }}>
          {info.survey_name}
        </Container>
        <Container horizontal>
          <Actions className={cls.reviewHeadingLabels}>
            <span>
              <Icon
                name="account"
                tip="Respondent"
                onClick={this.handleEditContact}
              />
              &ensp;
              {this.props.surveyReview.info?.respondent_name ??
                `Respondent ${this.props.match.params.granteeSurveyId}`}
            </span>
          </Actions>
          <Actions className={cls.reviewHeadingLabels}>
            {isNotified && (
              <span>
                <Icon name="send" tip="Notified at" />
                &ensp;{toLocaleDateTime(info.notified_at)}
              </span>
            )}
            {info.is_finished && (
              <span>
                &ensp;
                <Icon name="check" tip="Responded at" />
                &ensp;{toLocaleDateTime(info.finished_at)}
              </span>
            )}
            {!info.is_finished && (
              <span>
                <Icon name="wait" />
                &ensp;unfinished
              </span>
            )}
          </Actions>
        </Container>
        <Container horizontal>
          <Actions className={cls.reviewHeadingLabels}>
            {grant && (
              <span>
                <Icon name="grantee" tip="Grantee" />
                &ensp;{grant}
              </span>
            )}
            {grant && grantee && <span>&ensp;•&ensp;</span>}
            {grantee && (
              <span>
                <Icon name="grants" tip="Grant" />
                &ensp;{grantee}
              </span>
            )}
          </Actions>
          <Actions className={cls.reviewHeadingLabels}>
            {series && (
              <span>
                &emsp;
                <Icon name="calendar" tip="Series" />
                &emsp;{series}
              </span>
            )}
          </Actions>
        </Container>
      </div>
    );
  }

  renderSurvey() {
    const {
      surveyReview: { answers, questions },
      survey: { layout },
      tagsWithTagGroups,
    } = this.props;
    const { isEditing, isShowingStaticElements } = this.state;

    return (
      <Survey
        isReview
        isEditing={isEditing}
        ref={(ref) => (this.innerRef = ref)}
        tagsWithTagGroups={tagsWithTagGroups}
        questions={questions.filter((q) => !q.isLayout)}
        layout={
          layout
            ? layout.filter((q) => isShowingStaticElements || !q.isLayout)
            : []
        }
        answers={answers}
        onSubmit={() => void 0}
        onAnswerChange={this.onAnswerChange}
        changeAnswerOtherChoiceValue={this.onAnswerOtherChange}
        onEvaluatorsTagsChanged={this.onEvaluatorsTagsChanged}
        onTagsChange={this.onTagsChange}
        projectId={this.props.match.params.projectId}
        granteeSurveyId={this.props.match.params.granteeSurveyId}
      />
    );
  }

  renderArrowSelectRespondent() {
    return (
      <>
        {this.state.respondents.length > 1 && (
          <Icon name="arrow-left" onClick={this.handlePrevRespondent} />
        )}
        {this.state.respondents.length > 1 && (
          <SelectPopoverLight
            label={
              this.state.respondents.find(
                (r) => r.id == this.props.match.params.granteeSurveyId
              )?.name ?? `Respondent ${this.props.match.params.granteeSurveyId}`
            }
            labelHint="Find respondent"
            popoverProps={{
              searchHint: 'Find respondent',
              largeWidth: true,
              large: false,
              customWidth: 768,
              style: { width: 768, maxWidth: 768 },
            }}
            renderItems={(searchText) => [
              ...this.state.respondents
                .filter(
                  (q) => q.is_finished && containsText(q.name, searchText)
                )
                .map((u) => (
                  <SelectPopoverLight.MenuItem
                    key={u.id}
                    leftIcon={<Icon name="check" color={colors.seafoam} />}
                    primaryText={u.name}
                    onClick={() => this.onNavigateRespondent(u.id)}
                    style={{ fontSize: 13 }}
                  />
                )),
              <SelectPopoverLight.Divider key="divider" />,
              ...this.state.respondents
                .filter(
                  (q) => !q.is_finished && containsText(q.name, searchText)
                )
                .map((u) => (
                  <SelectPopoverLight.MenuItem
                    key={u.id}
                    leftIcon={<Icon name="wait" color={colors['dark-grey']} />}
                    primaryText={u.name}
                    onClick={() => this.onNavigateRespondent(u.id)}
                    style={{ fontSize: 13 }}
                  />
                )),
            ]}
          />
        )}
        {this.state.respondents.length > 1 && (
          <Icon name="arrow-right" onClick={this.handleNextRespondent} />
        )}
      </>
    );
  }

  navigateSurveyUrl = (ev, url) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    window.open(url, '_blank');
  };
  onSetIsFinished = (ev) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    this.setState({ confirmIsFinished: true });
  };
  handleConfirmIsFinished = () => {
    this.setState({ confirmIsFinished: false });
    this.props
      .updateGranteeSurvey({
        id: this.props.surveyReview.info?.survey_id,
        isFinished: !this.props.surveyReview.info?.is_finished,
        permalink: this.props.surveyReview.info?.permalink,
      })
      .then((a) => this.getReview(false));
  };

  handleLanguageChanged = ({ value }) => {
    if (!canBuildSurvey()) return;

    this.props.changeSurveyProperty('activeLanguage', value, { silent: true });
  };

  translationProgress = () => {
    const totalAnswerCount = this.props.surveyReview.answers.length;
    const translatedAnswerCount = this.props.surveyReview.answers.filter(
      (answer) => answer.translation_status === 'for_review'
    ).length;
    return Math.round((translatedAnswerCount / totalAnswerCount) * 100);
  };

  checkResponsesTranslations = () => {
    this.props
      .getSurveyReview(
        this.props.match.params.projectId,
        this.props.match.params.granteeSurveyId,
        {
          silent: true,
        }
      )
      .then(() => {
        if (
          this.state.translationPollAttemptCount >= TRANSLATION_MAX_ATTEMPTS &&
          this.state.translationIntervalId
        ) {
          // Waited too long
          this.props.showNotification({
            title: 'Auto-Translation failed',
            level: 'warning',
            message: 'Some answers were not translated',
          });
          clearInterval(this.state.translationIntervalId);
          this.setState({
            translationIntervalId: null,
            translationPollAttemptCount: 0,
          });
        } else if (
          this.translationProgress() === 100 &&
          this.state.translationIntervalId
        ) {
          this.props.showNotification({
            title: 'Auto-Translation finished',
            message: 'You may now view the translated answers by language',
          });
          clearInterval(this.state.translationIntervalId);
          this.setState({
            translationIntervalId: null,
            translationPollAttemptCount: 0,
          });
        } else {
          // Try again
          this.setState({
            translationPollAttemptCount:
              this.state.translationPollAttemptCount + 1,
          });
        }
      });
  };

  startTranslationPolling = () => {
    if (this.state.translationIntervalId === null) {
      const intervalId = setInterval(
        this.checkResponsesTranslations,
        TRANSLATION_POLLING_INTERVAL
      );

      this.setState({
        translationIntervalId: intervalId,
        translationPollAttemptCount: 0,
      });
    } else {
      this.setState({
        translationPollAttemptCount: 0,
      });
    }
  };

  render() {
    const { surveyReview, survey } = this.props;
    const { surveyId } = this.props.match.params;
    const { isEditing, isShowingStaticElements } = this.state;
    const pending =
      surveyReview.pending || surveyReview.info.survey_id !== +surveyId;
    const currentUrl =
      !surveyReview.info?.is_finished &&
      !surveyReview.info.finished_at &&
      surveyReview.info?.permalink
        ? `/answer-survey/${surveyReview.info.permalink}`
        : null;
    const isFinished = this.props.surveyReview.info?.is_finished;

    if (pending) {
      return (
        <div>
          <Section type="context-header">
            <Container horizontal />
          </Section>
          <Section>
            <Card>
              <Progress large />
            </Card>
          </Section>
        </div>
      );
    }

    return (
      <div>
        <Section
          type="context-header"
          className={cls.responseHeaderMediaDisplayNone}
        >
          <Container horizontal>
            <Actions>
              {!isFinished && canManageSurvey() && (
                <span>
                  <Button
                    size="m"
                    style={{ height: 34, maxWidth: 120 }}
                    onClick={this.onSetIsFinished}
                    label="Set as Finished"
                  />
                </span>
              )}
              <span>
                <Toggle
                  label="Show static elements"
                  labelPosition="right"
                  toggled={isShowingStaticElements}
                  onToggle={this.onToggleShowingStaticElements}
                  inputStyle={{ fontSize: 11 }}
                />
              </span>
              {canManageSurvey() && (
                <span>
                  <Toggle
                    label="Edit survey answers"
                    labelPosition="right"
                    toggled={isEditing}
                    onToggle={this.onToggleIsEditing}
                    inputStyle={{ fontSize: 11 }}
                  />
                </span>
              )}
            </Actions>

            <Actions>
              <DownloadAsPdfWidget />
              &ensp;
              {currentUrl && (
                <span>
                  &ensp;
                  <Icon
                    name="globe"
                    tip="Permalink access to unfinished surveys"
                    onClick={(ev) => this.navigateSurveyUrl(ev, currentUrl)}
                  />
                  &emsp;
                </span>
              )}
              {this.renderArrowSelectRespondent()}
            </Actions>
          </Container>
        </Section>

        <Section surveyWidth noBorder>
          {this.renderInfo()}
          {this.props.survey.languages.length > 1 &&
            this.translationProgress() !== 100 &&
            this.translationProgress() !== 0 && (
              <ProgressBar
                progress={this.translationProgress()}
                tip={`Translation in progress - ${this.translationProgress()}%`}
              />
            )}
          {this.renderSurvey()}
          <SurveyEditFooter
            survey={this.props.survey}
            onSaveSurvey={isEditing && this.onSaveSurveyEdits}
            onLanguageChanged={
              canAiTranslate() ? this.handleLanguageChanged : null
            }
            isEditing={isEditing}
            projectId={this.props.match.params.projectId}
            granteeSurveyId={this.props.match.params.granteeSurveyId}
            activeStepId={this.props.activeStepId}
            translateSurveyResponses={this.handleTranslateSurveyResponses}
            startTranslationPolling={this.startTranslationPolling}
            translationInProgress={this.state.translationIntervalId !== null}
            translationProgress={this.translationProgress()}
          />
        </Section>
        <EditContactModal
          isResponse
          key={(surveyReview.info || {}).id}
          batches={survey.batches}
          public={survey.public}
          open={this.state.editContactOpen}
          src={surveyReview.info}
          onCancel={this.handleCancelEditContact}
          onSubmit={this.handleSubmitEditContact}
        />
        <Dialog
          small
          light
          open={this.state.confirmIsFinished}
          leftActions={
            <Button
              size="l"
              secondary
              onClick={() => this.setState({ confirmIsFinished: false })}
              label="Cancel"
            />
          }
          rightActions={
            <Button
              size="l"
              onClick={this.handleConfirmIsFinished}
              label="Confirm"
            />
          }
        >
          <strong>
            Caution: Marking this questionnaire as Finished is irreversible
          </strong>
          <br />
          <br />
          Responses will remain editable but the answers will be used viewable
          in Analysis page.
        </Dialog>
      </div>
    );
  }
}
