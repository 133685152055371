import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/v3/stripe/session',
  type: 'stripe_session',
  api: {
    allow: ['find'],
  },
};

export const StripeSession = new Resource(apiConfig, 'stripeSessionoutJsonapi');
reducerRegistry.register(StripeSession);

export default StripeSession.api;
