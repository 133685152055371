import reducerRegistry from 'im/api/registry';
import Resource from 'im/api/Resource';

const apiConfig = {
  root: '/v3/grantee_surveys',
  type: 'grantee_survey',
  api: {
    custom: {
      translate: {
        path: ':id/translate',
        method: 'PUT',
      },
    },
  },
};

const GranteeSurvey = new Resource(apiConfig, 'granteeSurveyJsonapi');
reducerRegistry.register(GranteeSurvey);

export default GranteeSurvey.api;
