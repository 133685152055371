/* eslint-disable react/no-unused-state */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  TextField,
  Button,
  Container,
  Actions,
} from 'src/components/IMUI';
import { Icon } from 'im/ui/Icon';
import { connect } from 'react-redux';
import debounce from 'src/utils/debounce';
import SearchAndTag2 from './SearchAndTag2';
import SearchAndTagReview2 from './SearchAndTagReview2';

import { where } from 'im/api/Query';
import reportsApi from 'src/api/Reports';
import taggingsApi from 'src/api/Taggings';

import {
  isNumeric,
  getNumberFromText,
} from 'src/components/TaggingType/taggingTypeHelper';

import cls from './Search.module.css';
import { canManageTag } from 'src/userStorage';

const Search2 = ({
  setSelection,
  scrollToTagging,
  pdfHighlighterRef,
  report,
  project,
  organizationCurrent,
  user,
  reportSearch,
  createTagging,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchAndTagVisible, setSearchAndTagVisible] = useState(false);
  const [reviewVisible, setReviewVisible] = useState(false);
  const [searchAndTagResults, setSearchAndTagResults] = useState([]);
  const [occurrences, setOccurrences] = useState([]);
  const [searchAndTagSelectedTagId, setSearchAndTagSelectedTagId] = useState();
  const [focusIndex, setFocusIndex] = useState(0);

  const moveSelection = useCallback(
    (index) => {
      const focusedOccurrence = occurrences?.[index];

      if (!focusedOccurrence) {
        return;
      }

      scrollToTagging({ misc: focusedOccurrence }, pdfHighlighterRef);
      setSelection({
        misc: { position: focusedOccurrence.position },
        content: focusedOccurrence.full_content,
        force: true,
      });
      setFocusIndex(index);
    },
    [occurrences, pdfHighlighterRef, scrollToTagging, setSelection]
  );

  const onChangeSearch = useCallback(
    debounce((query) => {
      setSearchText(query);

      reportSearch(
        where(
          {
            id: report.report_id,
          },
          { query: query }
        )
      ).then((response) => {
        setOccurrences(response.occurrences);
        if (response.occurrences.length > 0) {
          moveSelection(0);
        }
      });
    }, 500),
    [moveSelection, report.report_id, reportSearch]
  );

  const onClickPrevious = useCallback(() => {
    const max = occurrences.length;
    const newIndex =
      (focusIndex - 1) % max == -1 ? max - 1 : (focusIndex - 1) % max;
    moveSelection(newIndex);
  }, [moveSelection, focusIndex, occurrences.length]);

  const onClickNext = useCallback(() => {
    moveSelection((focusIndex + 1) % occurrences.length);
  }, [moveSelection, focusIndex, occurrences.length]);

  const displaySearchAndTag = () => {
    setSearchAndTagVisible(true);
  };

  const hideSearchAndTag = () => {
    setSearchAndTagVisible(false);
  };

  const hideReview = () => {
    setReviewVisible(false);
  };

  const onSearchAndTagProceed = useCallback(
    (values) => {
      reportSearch(
        where(
          {
            id: report.report_id,
          },
          {
            query: values.text,
            selection_strategy: values.selection,
          }
        )
      ).then((response) => {
        setSearchText(values.text);
        setSearchAndTagVisible(false);
        setReviewVisible(true);
        setSearchAndTagResults(
          response.occurrences.map((occurrence) => ({
            ...occurrence,
            disabled: false,
          }))
        );
        setSearchAndTagSelectedTagId(values.tagId);
      });
    },
    [report.report_id, reportSearch]
  );

  const createSingleTagging = useCallback(
    async (item) => {
      const content = isNumeric(item.full_content)
        ? getNumberFromText(item.full_content)
        : item.full_content;

      const attributes = {
        misc: { position: item.position },
        content: content,
        type: 'taggings',
      };

      return createTagging(
        where().payload({
          attributes: attributes,
          relationships: [
            {
              relName: 'tag',
              type: 'tags',
              id: searchAndTagSelectedTagId,
            },
            {
              relName: 'taggable',
              type: 'reports',
              id: report.reportUid,
            },
            { relName: 'user', type: 'users', id: user.data.id },
            {
              relName: 'organization',
              type: 'organizations',
              id: organizationCurrent.data.id,
            },
            {
              relName: 'project',
              type: 'projects',
              id: project.uid,
            },
          ],
        })
      );
    },
    [
      createTagging,
      organizationCurrent,
      project.uid,
      report.reportUid,
      user.data.id,
      searchAndTagSelectedTagId,
    ]
  );

  const onSearchAndTagReviewProceed = useCallback(async () => {
    const checkedItems = searchAndTagResults.filter((el) => !el.disabled);
    if (!checkedItems.length) return;

    try {
      await Promise.all(checkedItems.map(createSingleTagging));

      hideReview();
    } catch (e) {
      console.error(e);
    }
  }, [createSingleTagging, searchAndTagResults]);

  const onSearchAndTagItemChange = useCallback(
    (item, isSelected) => {
      const newSearchAndTagResults = searchAndTagResults.map((el) =>
        el.position === item.position ? { ...el, disabled: !isSelected } : el
      );

      setSearchAndTagResults(newSearchAndTagResults);
    },
    [searchAndTagResults]
  );

  return (
    <div>
      <TextField
        flatDark
        noValidation
        wrapperClassName={cls.searchField}
        hintText={
          <span className={cls.searchHintText}>
            <Icon name="search" /> Type in to search document
          </span>
        }
        value={searchText}
        onChange={onChangeSearch}
      />

      <Container horizontal className={cls.searchContainerBottom}>
        <Actions>
          {searchText?.length > 0 && (
            <Container horizontal nowrap className={cls.searchActions}>
              {!!searchText && (
                <small className={cls.searchStats}>
                  {!occurrences?.length
                    ? 'No matches found'
                    : `Matches: ${Math.abs(focusIndex) + 1}/${
                        occurrences.length
                      }`}
                </small>
              )}
              <span>&emsp;</span>
              <Button
                border
                secondary
                className={cls.searchActionsButton}
                icon={<Icon name="chevron-up" />}
                onClick={onClickPrevious}
              />
              <span>&nbsp;&nbsp;</span>
              <Button
                border
                secondary
                className={cls.searchActionsButton}
                icon={<Icon name="chevron-down" />}
                onClick={onClickNext}
              />
              <span>&emsp;</span>
              {canManageTag() && (
                <Button
                  secondary
                  onClick={displaySearchAndTag}
                  label="Search and Tag"
                  size="s"
                  icon={<Icon name="tag" />}
                />
              )}
            </Container>
          )}
        </Actions>
      </Container>

      <Dialog
        title="Search and Tag"
        titleStyle={{ fontSize: 16 }}
        contentStyle={{ width: '55%', maxWidth: '576px' }}
        modal={false}
        open={searchAndTagVisible}
        onRequestClose={hideSearchAndTag}
      >
        <SearchAndTag2
          onSubmit={(v) => onSearchAndTagProceed(v)}
          initialValues={{ text: searchText }}
          onRequestClose={hideSearchAndTag}
        />
      </Dialog>

      {reviewVisible && (
        <Dialog
          title="Search and Tag"
          titleStyle={{ fontSize: 16 }}
          contentStyle={{ width: '55%', maxWidth: '576px' }}
          modal={false}
          open={reviewVisible}
          onRequestClose={hideReview}
          autoScrollBodyContent
        >
          <SearchAndTagReview2
            results={searchAndTagResults}
            onSubmit={onSearchAndTagReviewProceed}
            initialValues={{ items: searchAndTagResults }}
            onItemChange={onSearchAndTagItemChange}
            onRequestClose={hideReview}
          />
        </Dialog>
      )}
    </div>
  );
};

const connection = connect(
  (state) => ({
    report: state.report,
    project: state.project,
    organizationCurrent: state.organizationCurrent,
    user: state.user,
  }),
  {
    createTagging: taggingsApi.create,
    reportSearch: reportsApi.search,
  }
);

Search2.propTypes = {
  setSelection: PropTypes.func.isRequired,
  scrollToTagging: PropTypes.func.isRequired,
  pdfHighlighterRef: PropTypes.object,
  report: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  organizationCurrent: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  reportSearch: PropTypes.func.isRequired,
  createTagging: PropTypes.func.isRequired,
};

export default connection(Search2);
