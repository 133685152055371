import React from 'react';
import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import isEmpty from 'ramda/src/isEmpty';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import isInt from 'validator/lib/isInt';
import {
  CardEmpty,
  Checkbox,
  Tag,
  TextField,
  NumberField,
} from 'src/components/IMUI';
import {
  SHORT_TEXT,
  FINANCIAL,
  NUMERICAL,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
} from 'src/data/questionTypes';
import { getSurveyI18n } from 'src/utils/surveysI18n';

import createQuestion from './createQuestion';
import FinancialQuestion from './FinancialQuestion';
import NumericalQuestion from './NumericalQuestion';
import TextQuestion from './TextQuestion';

import cls from './SingleMatrix.module.css';

const cx = classNames.bind(cls);

const MAX_YEAR = 2100;
const MIN_YEAR = 1900;

const validYear = (value) =>
  isInt(value, { allow_leading_zeroes: false, min: MIN_YEAR, max: MAX_YEAR });

@createQuestion
@connect(pick(['survey', 'surveyAnswers']))
class SingleMatrix extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    answer: PropTypes.object,
    question: PropTypes.object,
    survey: PropTypes.object,
    surveyAnswers: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    index: PropTypes.number.isRequired,
    isReview: PropTypes.bool,
    readOnly: PropTypes.bool,
    forceMobileStyling: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    t: PropTypes.func,
  };

  static validate(question, answers) {
    if (!answers) answers = {};
    for (let i = 0; i < question.matrixValues.length; i += 1) {
      const value = answers[question.matrixValues[i].title];
      const shouldValidate = question.required || value;
      if (
        shouldValidate &&
        question.matrixQuestionType === SHORT_TEXT &&
        TextQuestion.validate(question, value)
      ) {
        return <T>Please provide valid answers</T>;
      }
      if (
        shouldValidate &&
        question.matrixQuestionType === FINANCIAL &&
        FinancialQuestion.validate(question, value)
      ) {
        return (
          <T>Please provide valid currency values (without currency sign)</T>
        );
      }
      if (
        shouldValidate &&
        question.matrixReachType === 'year' &&
        !validYear(`${value}`)
      ) {
        return <T>Please provide valid year values</T>;
      }
      if (
        shouldValidate &&
        question.matrixReachType === 'percent' &&
        NumericalQuestion.validate(question, value)
      ) {
        return <T>Please provide valid percentage values (without % sign)</T>;
      }
      if (
        shouldValidate &&
        question.matrixReachType === 'unit' &&
        NumericalQuestion.validate(question, value)
      ) {
        return <T>Please provide valid numerical values</T>;
      }
    }
    if (!question.settings || !question.settings.restrictAnswers) return null;
    const answersOptions = question.matrixValues.filter((value) =>
      ['financial', 'numerical'].includes(question.matrixQuestionType)
        ? Number.isFinite(answers[value.title])
        : answers[value.title]
    );
    if (answersOptions.length > question.settings.maxAnswers) {
      return (
        <T
          message="Please do not answer more than {{ maxAnswers }} question"
          messagePlural="Please do not answer more than {{ maxAnswers }} questions"
          count={question.settings.maxAnswers}
          maxAnswers={question.settings.maxAnswers}
        />
      );
    }
    if (answersOptions.length < question.settings.minAnswers) {
      return (
        <T
          message="Please answer at least {{ minAnswers }} question"
          messagePlural="Please answer at least {{ minAnswers }} questions"
          count={question.settings.minAnswers}
          minAnswers={question.settings.minAnswers}
        />
      );
    }
    return null;
  }

  onChange = (rowIndex, rowValue, columnId = null) => {
    let { value } = this.props;
    value ||= {};
    this.props.question.matrixValues[rowIndex] ||= {};
    const row = columnId
      ? `${this.props.question.matrixValues[rowIndex].title}_${columnId}`
      : this.props.question.matrixValues[rowIndex].title;

    this.props.onChange?.({ ...value, [row]: rowValue });
  };

  onMultiChoiceChange = (rowIndex, rowValue, columnId = null) => {
    let { value } = this.props;
    value ||= {};
    this.props.question.matrixValues[rowIndex] ||= {};
    const row = columnId
      ? `${this.props.question.matrixValues[rowIndex].title}_${columnId}`
      : this.props.question.matrixValues[rowIndex].title;

    const newValues = this.toggleMultiChoiceValue(value[row], rowValue);

    this.props.onChange?.({ ...value, [row]: newValues });
  };

  toggleMultiChoiceValue = (values, newValue) => {
    // Force data structure to be array since
    // SINGLE MATRIX Multiple Choice types
    // accept multiple values
    const rowValues = typeof values === 'number' ? [values] : values || [];
    const index = rowValues?.indexOf(newValue);

    if (index !== -1) {
      return rowValues.filter((_item, idx) => idx !== index);
    }

    return [...rowValues, newValue];
  };

  getI18nString = (path, altPath) =>
    getSurveyI18n(
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      this.props.question,
      path,
      altPath
    );

  getAnswerI18nString = (answer, path, altPath) =>
    getSurveyI18n(this.props.survey, answer, path, altPath);

  renderHeader = () => (
    <div
      className={cx('row', 'header', 'hasOptions', {
        hasManyOptions: this.props.question.settings.columns?.length >= 4,
        hasTooManyOptions: this.props.question.settings.columns?.length >= 6,
      })}
    >
      <div>
        <label />
        {(this.props.question.settings.columns || []).map((column, i) => (
          <div key={`header-${i}`}>
            <b>{this.getI18nString(column.id, ['matrixValues', i, 'title'])}</b>
          </div>
        ))}
      </div>
    </div>
  );

  renderSingleChoiceInput = (row, rowIndex) => {
    return (
      <>
        {this.props.question.settings.columns.map((column) => (
          <div key={column.id} className={cx('column', 'option')}>
            <input
              type="radio"
              checked={
                this.props.value && this.props.value[row.title] === column.title
              }
              onChange={() =>
                this.onChange(
                  rowIndex,
                  this.props.question.settings.columns
                    .find((col) => col.title === column.title)
                    ?.title.toString()
                )
              }
            />
          </div>
        ))}
        <select
          className={classNames({
            [cls.selectEmpty]:
              (this.props.value?.[row.title] ?? 'SELECT_ONE') === 'SELECT_ONE',
          })}
          value={this.props.value?.[row.title]}
          onChange={(ev) =>
            this.onChange(
              rowIndex,
              this.props.question.settings.columns
                .find((col) => col.title === ev.target.value)
                ?.title.toString()
            )
          }
        >
          <option disabled value="SELECT_ONE">
            {this.props.t('Select one')}
          </option>
          {this.props.question.settings.columns.map((column) => (
            <option key={column.id} value={column.title}>
              {column.title}
            </option>
          ))}
        </select>
      </>
    );
  };

  renderMultipleChoiceInput = (row, rowIndex) => (
    <>
      {this.props.question.settings.columns.map((column) => (
        <div key={column.id} className={cx('column', 'option')}>
          <Checkbox
            className={cx('option', {
              'option-readonly': this.props.readOnly,
            })}
            readOnly={this.props.readOnly}
            type="checkbox"
            checked={(this.props.value?.[row.title] || []).includes(
              column.title
            )}
            onCheck={() => this.onMultiChoiceChange(rowIndex, column.title)}
          />
        </div>
      ))}
    </>
  );

  renderMultipleInput = (row, rowIndex) => {
    const max =
      this.props.question.matrixQuestionType === 'year' ? MAX_YEAR : undefined;
    const min =
      this.props.question.matrixQuestionType === 'year' ? MIN_YEAR : undefined;
    return this.props.question.settings.columns.map((column) => (
      <div key={column.id} className={cx('multiField')}>
        {this.props.question.matrixQuestionType === SHORT_TEXT && (
          <>
            <TextField
              value={
                this.props.survey.activeLanguage ===
                this.props.survey.default_language
                  ? this.props.value?.[`${row.title}_${column.id}`] ?? ''
                  : this.getAnswerI18nString(
                      this.props.answer?.value?.[`${row.title}_${column.id}`],
                      'value',
                      'value'
                    ) ||
                    (this.props.value?.[`${row.title}_${column.id}`] ?? '')
              }
              noValidation
              fullWidth
              flat={!this.props.isAnswerMode}
              flatDark={this.props.isAnswerMode}
              border={true}
              id={`row_${rowIndex}_${this.props.index}_`}
              onChange={(v) => this.onChange(rowIndex, v, column.id)}
              wrapperClassName={'printHidden'}
            />

            <div
              className={cx(cls.printVisible, cls.printTextField, {
                [cls.printTextFieldEmpty]:
                  !this.props.value?.[`${row.title}_${column.id}`],
              })}
            >
              {this.props.value?.[`${row.title}_${column.id}`] ?? ''}
            </div>
          </>
        )}
        {this.props.question.matrixQuestionType === NUMERICAL && (
          <NumberField
            value={this.props.value?.[`${row.title}_${column.id}`]}
            noValidation
            fullWidth
            flat={!this.props.isAnswerMode}
            flatDark={this.props.isAnswerMode}
            border={true}
            id={`row_${rowIndex}_${this.props.index}_`}
            onChange={(v) => this.onChange(rowIndex, v, column.id)}
            delimitingWithCommas={false}
            min={min}
            max={max}
          />
        )}
        {this.props.question.matrixQuestionType === FINANCIAL && (
          <NumberField
            value={this.props.value?.[`${row.title}_${column.id}`]}
            noValidation
            fullWidth
            flat={!this.props.isAnswerMode}
            flatDark={this.props.isAnswerMode}
            border={true}
            id={`row_${rowIndex}_${this.props.index}_`}
            onChange={(v) => this.onChange(rowIndex, v, column.id)}
            delimitingWithCommas={true}
            min={min}
            max={max}
          />
        )}
        {this.props.question.matrixQuestionType === FINANCIAL && (
          <span key={column.id} className={cls.spaceLeft}>
            {this.props.question.currency}
          </span>
        )}
      </div>
    ));
  };

  renderSingleInput = (row, rowIndex) => {
    const max =
      this.props.question.matrixQuestionType === 'year' ? MAX_YEAR : undefined;
    const min =
      this.props.question.matrixQuestionType === 'year' ? MIN_YEAR : undefined;
    return (
      <div className={cx('field')}>
        {this.props.question.matrixQuestionType === SHORT_TEXT && (
          <TextField
            value={this.props.value?.[row.title] ?? ''}
            noValidation
            fullWidth
            flat={!this.props.isAnswerMode}
            flatDark={this.props.isAnswerMode}
            border={!this.props.isAnswerMode}
            id={`row_${rowIndex}_${this.props.index}`}
            onChange={(v) => this.onChange(rowIndex, v)}
          />
        )}
        {this.props.question.matrixQuestionType === NUMERICAL && (
          <NumberField
            value={this.props.value?.[row.title]}
            noValidation
            fullWidth
            flat={!this.props.isAnswerMode}
            flatDark={this.props.isAnswerMode}
            border={!this.props.isAnswerMode}
            id={`row_${rowIndex}_${this.props.index}`}
            onChange={(v) => this.onChange(rowIndex, v)}
            delimitingWithCommas={false}
            min={min}
            max={max}
          />
        )}
        {this.props.question.matrixQuestionType === FINANCIAL && (
          <NumberField
            value={this.props.value?.[row.title]}
            noValidation
            fullWidth
            flat={!this.props.isAnswerMode}
            flatDark={this.props.isAnswerMode}
            border={!this.props.isAnswerMode}
            id={`row_${rowIndex}_${this.props.index}`}
            onChange={(v) => this.onChange(rowIndex, v)}
            delimitingWithCommas={true}
            min={min}
            max={max}
          />
        )}
        {this.props.question.matrixQuestionType === FINANCIAL && (
          <span className={cls.spaceLeft}>
            {this.props.question.currency || '$'}
          </span>
        )}
      </div>
    );
  };

  renderRow = (row, rowIndex) => {
    const { readOnly, question, index, forceMobileStyling, tagsWithTagGroups } =
      this.props;
    const tag = this.props.isReview
      ? {
          ...this.props.answer.question_taggings.find(
            (qt) => qt.tag_id === row.tag_id
          )?.tag,
          ...tagsWithTagGroups.find(
            (st) =>
              st.id ===
              this.props.answer.question_taggings.find(
                (qt) => qt.tag_id === row.tag_id
              )?.tag.id
          ),
        }
      : {};
    const isMultipleChoiceField =
      this.props.question.matrixQuestionType === MULTIPLE_CHOICE;
    const isSingleChoiceField =
      this.props.question.matrixQuestionType === SINGLE_CHOICE;
    const isMultipleField =
      !isMultipleChoiceField &&
      !isSingleChoiceField &&
      this.props.question.settings.singleMatrixColumns;
    const isSingleField = !this.props.question.settings.singleMatrixColumns;
    const headerClass = {
      hasOptions:
        isMultipleField || isSingleChoiceField || isMultipleChoiceField,
      hasManyOptions: question.settings.columns?.length >= 4,
      hasTooManyOptions: question.settings.columns?.length >= 6,
      rowMobileStyling: forceMobileStyling,
    };

    return (
      <section key={`row_${rowIndex}_${index}`}>
        {isMultipleField && rowIndex === 0 && this.renderHeader()}
        {(isSingleChoiceField || isMultipleChoiceField) &&
          rowIndex === 0 &&
          this.renderHeader()}

        <div className={cx('row', headerClass)}>
          <div>
            <label htmlFor={`row_${rowIndex}_${index}`} className={cls.title}>
              <b>
                {this.getI18nString(row.id, [
                  'matrixValues',
                  rowIndex,
                  'title',
                ])}
              </b>
            </label>
            {isMultipleChoiceField &&
              this.renderMultipleChoiceInput(row, rowIndex)}
            {isSingleChoiceField && this.renderSingleChoiceInput(row, rowIndex)}
            {isMultipleField && this.renderMultipleInput(row, rowIndex)}
            {isSingleField && this.renderSingleInput(row, rowIndex)}
          </div>
          {tag.title && (
            <Tag
              inline
              reach={question.matrixQuestionType === NUMERICAL}
              label={tag.title}
              tip={(tag.tagGroup || {}).title}
            />
          )}
        </div>
      </section>
    );
  };

  render() {
    if (!this.props.question.matrixValues?.length) {
      return (
        <CardEmpty>
          Please use `Quick add` to add multiple responses to your questions or
          `Add row` to add question responses one by one
        </CardEmpty>
      );
    }
    if (
      this.props.readOnly &&
      !this.props.isReview &&
      isEmpty(this.props.value)
    ) {
      return <p className={cls.printVisible}>No answers</p>;
    }
    return <div>{this.props.question.matrixValues?.map(this.renderRow)}</div>;
  }
}

const enhancedComponent = withTranslators(SingleMatrix);
enhancedComponent.validate = SingleMatrix.validate;
export default enhancedComponent;
