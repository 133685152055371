import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  reduxForm,
  Field,
  destroy,
  formValueSelector,
  getFormSyncErrors,
  FormSection,
  getFormValues,
  submit,
} from 'redux-form';
import {
  createGrantee,
  updateGrantee,
  deleteGranteeContact,
  createGranteeContact,
} from 'src/actionCreators/granteesActionCreators';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  Actions,
  TextField,
  SelectField,
  ChipInput,
  Divider,
  FormField,
  DatePicker,
  Button,
  ReduxFormField,
} from 'src/components/IMUI/index';
import LocationInputs from 'src/components/Location/LocationInputs';
import COUNTRIES from 'src/data/countries.json';
import { getOrgText } from 'src/services/DictionaryService';
import createValidator, { handleSubmitFail } from 'src/utils/validation';
import AddEditContact, { validateContacts } from './AddEditContact';
import cls from './GranteeForm.module.css';

const GENDER_IDENTITIES = [
  'Woman',
  'Man',
  'Transgender',
  'Non-gender identifying',
];
const RACES = [
  'Asian',
  'American Indian',
  'Black/African American',
  'Hawaiian or Pacific Islander',
  'Hispanic (white)',
  'White (non-hispanic)',
  'South Asian',
  'Other',
];
const RELIGIONS = [
  'Christian',
  'Muslim',
  'Jewish',
  'Buddhist',
  'Hindu',
  'Sikh',
  'Tao',
  'Confucian',
  'Other',
];
const SEXUAL_ORIENTATIONS = [
  'Lesbian',
  'Gay',
  'Bisexual',
  'Trans',
  'Queer',
  'Heterosexual',
  'Other',
];
const EDUCATION_LEVELS = [
  'No Formal Education',
  'Primary',
  'Secondary School',
  'Technical Education',
  'Some College',
  'University Degree',
  'Advanced Degree (Masters or PhD)',
];
const MARTIAL_STATUSES = [
  'Single',
  'Married',
  'Divorced',
  'Partnered',
  'Widowed',
];
const FIELDS_ALWAYS = [
  'name',
  'start_date',
  'end_date',
  'city',
  'state',
  'country_code',
  'region',
  'type',
  'status',
  'description',
  'website',
  'partner_list',
  'contacts',
];
const FIELDS = {
  organization: [...FIELDS_ALWAYS],
  individual: [
    ...FIELDS_ALWAYS,
    'date_of_birth',
    'gender_identity',
    'race',
    'nationality',
    'ethnicity',
    'annual_income',
    'religion',
    'sexual_orientation',
    'education_level',
    'marital_status',
  ],
};

const isTheSameAsExistingGrantee = (allGrantees, name) =>
  allGrantees.map(prop('name')).indexOf(name) >= 0;
const toCountryItems = () => [
  <MenuItem key={'null'} value={null}>
    -
  </MenuItem>,
  ...COUNTRIES.map(({ code, name }) => (
    <MenuItem value={code} key={code} primaryText={name} />
  )),
];
const toMenuItems = (arr) => [
  <MenuItem key={'null'} value={null}>
    -
  </MenuItem>,
  ...arr.map((_) => <MenuItem key={_} primaryText={_} value={_ || ''} />),
];
export const FORM_NAME = 'newGrantee';
const FORM_LOCATION = 'newGranteeLocation';
const LOCATION_FIELDS = {
  countryCode: { name: 'country_code' },
  state: { name: 'state' },
  city: { name: 'city' },
  region: { name: 'region' },
};

const SmartField = ({ types, name, options, ...rest }) => {
  if (!types?.includes(name)) return null;
  if (name?.includes('date'))
    return (
      <FormField fullWidth anchorScrollName={name}>
        <ReduxFormField name={name} {...rest} />
      </FormField>
    );
  return (
    <FormField fullWidth anchorScrollName={name}>
      <Field name={name} {...rest}>
        {options}
      </Field>
    </FormField>
  );
};

@connect(
  (state) => ({
    organizationCurrent: state.organizationCurrent,
    contacts: formValueSelector(FORM_NAME)(state, 'contacts'),
    formErrors: getFormSyncErrors(FORM_NAME)(state),
    formValues: getFormValues(FORM_NAME)(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        createGrantee,
        updateGrantee,
        deleteGranteeContact,
        createGranteeContact,
        destroyForm: destroy,
        submitForm: submit,
      },
      dispatch
    )
)
class GranteeForm extends React.PureComponent {
  static propTypes = {
    createGrantee: PropTypes.func.isRequired,
    organizationCurrent: PropTypes.object,
    updateGrantee: PropTypes.func.isRequired,
    deleteGranteeContact: PropTypes.func.isRequired,
    createGranteeContact: PropTypes.func.isRequired,
    contacts: PropTypes.array,
    formErrors: PropTypes.object,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
    isEdit: PropTypes.bool,
    allGrantees: PropTypes.array,
    customFields: PropTypes.object,

    regions: PropTypes.array,
    type: PropTypes.string,

    destroyForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onAssignExistingGrantee: PropTypes.func,
    onSubmitSuccessful: PropTypes.func,
  };

  static defaultProps = {
    contacts: [],
    initialValues: {},
    isEdit: false,
    customFields: {},
    onCancel: () => void 0,
    onSubmitSuccessful: () => void 0,
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialValues.contacts !== this.props.initialValues.contacts &&
      (nextProps.initialValues.contacts || []).length >=
        (this.props.initialValues.contacts || []).length
    ) {
      this.props.initialize({
        ...this.props.formValues,
        contacts: nextProps.initialValues.contacts,
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyForm(FORM_NAME);
  }
  onRequestFormSubmit = (ev) => {
    ev.preventDefault();
    this.props.submitForm(FORM_LOCATION);
  };

  onFormSubmit = (location) => {
    const { type, isEdit, regions } = this.props;

    /* TODO: Remove region when region/region_id unified */
    this.props.handleSubmit((data) => {
      const grantee = {
        ...data,
        ...location,
        region: location.region,
        type,
      };
      const contactNotCreated = (data.contacts || []).find(({ id }) => !id);
      if (isEdit && contactNotCreated) {
        this.onContactAdd(contactNotCreated);
      }

      const granteeAction = isEdit
        ? this.props.updateGrantee(grantee)
        : this.props.createGrantee(grantee);
      granteeAction.then((payload) => {
        this.props.onSubmitSuccessful(payload);
        this.props.onCancel();
      });
    })();
  };

  onContactAdd = (contact) => {
    const { initialValues } = this.props;
    this.props.createGranteeContact(initialValues.id, contact);
  };
  onContactDelete = (contactId) => {
    const { initialValues } = this.props;
    this.props.deleteGranteeContact(initialValues.id, contactId);
  };
  onSelectExistingGrantee = (name) => {
    const { allGrantees } = this.props;
    const grantee = allGrantees.find(({ name: gName }) => gName === name);
    this.props.onAssignExistingGrantee(grantee.id);
    this.props.onCancel();
  };
  onLocationSubmit = (data) => {
    Object.keys(data).forEach((key) => this.props.change(key, data[key]));
    this.onFormSubmit(data);
  };

  render() {
    const {
      type,
      organizationCurrent,
      contacts,
      formErrors,
      initialValues,
      allGrantees,
      formValues,
      isEdit,
      customFields,
    } = this.props;
    const editLocationValues = !isEdit ? {} : initialValues;
    return (
      <form
        id={FORM_NAME}
        onSubmit={this.onRequestFormSubmit}
        noValidate
        autoComplete="off"
      >
        <SmartField
          types={FIELDS[type]}
          component={TextField}
          label="Name *"
          name="name"
          hintText="Type in..."
        />
        {isEdit ||
        !isTheSameAsExistingGrantee(
          allGrantees,
          (formValues || {}).name
        ) ? null : (
          <Button
            className={cls.buttonText}
            text
            label="- Click here to select it"
            onClick={() => this.onSelectExistingGrantee(formValues.name)}
          />
        )}
        <Divider style={{ marginBottom: 20 }} />
        <LocationInputs
          isInDialog
          form={FORM_LOCATION}
          fields={LOCATION_FIELDS}
          editValues={editLocationValues}
          onSubmitAndValid={this.onLocationSubmit}
        />
        <AddEditContact
          formName={FORM_NAME}
          contacts={contacts}
          formErrors={formErrors}
          isEdit={!!initialValues.id}
          onAddContact={this.onContactAdd}
          onDeleteContact={this.onContactDelete}
        />
        <SmartField
          types={FIELDS[type]}
          component={TextField}
          label="Website"
          name="website"
          hintText="Type in..."
        />
        <SmartField
          types={FIELDS[type]}
          component={DatePicker}
          name="date_of_birth"
          hintText="Choose"
          label="Date of birth"
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          hintText="Choose"
          label="Race"
          name="race"
          options={toMenuItems(RACES)}
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          label="Gender Identity"
          hintText="Choose"
          name="gender_identity"
          options={toMenuItems(GENDER_IDENTITIES)}
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          label="Sexual Orientation"
          hintText="Choose"
          name="sexual_orientation"
          options={toMenuItems(SEXUAL_ORIENTATIONS)}
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          label="Nationality"
          name="nationality"
          hintText="Type in..."
          options={toCountryItems()}
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          label="Ethnicity"
          name="ethnicity"
          hintText="Type in..."
          options={toCountryItems()}
        />
        <SmartField
          types={FIELDS[type]}
          component={TextField}
          label="Annual Income"
          name="annual_income"
          hintText="Type in..."
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          label="Education"
          name="education_level"
          hintText="Choose"
          options={toMenuItems(EDUCATION_LEVELS)}
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          label="Marital Status"
          name="marital_status"
          hintText="Choose"
          options={toMenuItems(MARTIAL_STATUSES)}
        />
        <SmartField
          types={FIELDS[type]}
          component={SelectField}
          hintText="Choose"
          label="Religion"
          name="religion"
          options={toMenuItems(RELIGIONS)}
        />
        <SmartField
          types={FIELDS[type]}
          component={ChipInput}
          format={(v) => (isNil(v) ? [] : v)}
          label={
            <FlexRow>
              <Actions>
                <span>Partners</span>
              </Actions>
              <Actions>
                <span style={{ opacity: 0.75, textAlign: 'right' }}>
                  <small>
                    Press <code>enter</code> or <code>,</code> to confirm
                    creating a partner.
                  </small>
                </span>
              </Actions>
            </FlexRow>
          }
          name="partner_list"
          hintText="Type in..."
        />
        <SmartField
          types={FIELDS[type]}
          component={TextField}
          rows={2}
          multiLine
          label="Description"
          name="description"
          hintText="Type in description..."
        />
        <FormSection name="custom_fields">
          {Object.keys(customFields).map(
            (fieldKey) =>
              organizationCurrent.data.custom_grantee_fields.includes(
                fieldKey
              ) && (
                <FormField key={fieldKey}>
                  <Field
                    component={TextField}
                    label={fieldKey}
                    hintText="Type in..."
                    name={fieldKey}
                  />
                </FormField>
              )
          )}
        </FormSection>
      </form>
    );
  }
}

function validate(values, props) {
  const not = (fn) => (v) => !fn(v);
  const isDefined = (v) => Boolean(v);
  const longerThan = (n) => (v) => v.length > n;
  const allAvailableGrantees = !props.isEdit
    ? props.allGrantees
    : props.allGrantees.filter((g) => g.id !== +props.initialValues.id);
  const newGranteeValidator = createValidator({
    name: [
      [not(isDefined), () => 'Name is required'],
      [not(longerThan(1)), () => 'Name is too short'],
      [
        (v) =>
          allAvailableGrantees
            ? isTheSameAsExistingGrantee(allAvailableGrantees, v)
            : false,
        () => getOrgText('Grantee with that name already exist'),
      ],
    ],
  });
  return { ...newGranteeValidator(values), ...validateContacts(values) };
}
export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  onSubmitFail: (errors) => handleSubmitFail(errors, true),
})(GranteeForm);
