import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Slider, Toggle, Tile } from 'src/components/IMUI';
import cls from '../Edit/UserOrgEditLicensePlan.module.css';
import classNames from 'classnames/bind';
const cx = classNames.bind(cls);
import store from 'src/store';

const LicenseSurveyOnlyCard = ({
  title,
  active,
  additionalUserPrice,
  additionalUserCount,
  aiTranslationAndInsightsActive,
  aiTranslationAndInsightsPrice,
  baseSubscriptionPrice,
  currentSubscription,
  onActivate,
  setAdditionalUserCount,
  setAiTranslationAndInsightsActive,
}) => {
  const aiWriterEnabled = store
    .getState()
    ?.organizationCurrent?.data?.flags?.includes('ai-writer');
  const aiTranslateEnabled = store
    .getState()
    ?.organizationCurrent?.data?.flags?.includes('ai-translation');

  return (
    <Tile
      cardProps={{
        className: cx({
          'planTile-inactive': !active,
          'planTile-active': active,
        }),
        flat: true,
        border: true,
        style: {
          width: 350,
          borderWidth: 2,
          borderRadius: 0,
          height: '100%',
        },
      }}
      containerProps={{
        style: {
          justifyContent: 'space-between',
        },
      }}
      onClick={onActivate}
    >
      {currentSubscription && (
        <div className={cls.currentSubscriptionBadge}>Current Subscription</div>
      )}
      <div>
        <h3>{title}</h3>
        <div className={cls.planSection}>
          <div>
            <h5>Base Subscription</h5>
            <div className={cls.baseSubscriptionDescription}>
              <p>Inclusions:</p>
              <p> - 1 user</p>
              <p> - Access to Surveys only</p>
            </div>
          </div>
          <div className={cls.planSectionPrice}>${baseSubscriptionPrice}</div>
        </div>
      </div>
      <div className={cls.addOnsContainer}>
        <div>
          <Divider compact />
        </div>
        <div className={cls.addOns}>
          <div>
            <h4>Add-ons</h4>
            <div className={cls.aiTranslationAndInsightsAddOn}>
              <div>
                <div>
                  <h5>AI Survey Translation and Chart Insights </h5>
                </div>
                <Toggle
                  mini
                  disabled={aiWriterEnabled && aiTranslateEnabled}
                  toggled={aiTranslationAndInsightsActive}
                  onToggle={(_, toggled) =>
                    setAiTranslationAndInsightsActive(toggled)
                  }
                />
              </div>
              <div>${aiTranslationAndInsightsPrice}</div>
            </div>
            <div className={cls.userAddOn}>
              <Slider
                style={{ width: 150 }}
                label={
                  <h5 style={{ width: 100, margin: 0, marginTop: 20 }}>
                    Additional User ({additionalUserCount})
                  </h5>
                }
                value={additionalUserCount}
                onChange={(_event, value) => {
                  setAdditionalUserCount(value);
                }}
                min={0}
                max={10}
                step={1}
              />
              <div>${additionalUserPrice}</div>
            </div>
          </div>
        </div>
      </div>
    </Tile>
  );
};

LicenseSurveyOnlyCard.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  additionalUserCount: PropTypes.number.isRequired,
  additionalUserPrice: PropTypes.number.isRequired,
  aiTranslationAndInsightsActive: PropTypes.bool.isRequired,
  aiTranslationAndInsightsPrice: PropTypes.number.isRequired,
  baseSubscriptionPrice: PropTypes.number.isRequired,
  currentSubscription: PropTypes.bool.isRequired,
  onActivate: PropTypes.func.isRequired,
  setAdditionalUserCount: PropTypes.func.isRequired,
  setAiTranslationAndInsightsActive: PropTypes.func.isRequired,
};

export default LicenseSurveyOnlyCard;
