import React from 'react';
import { formatMoney } from 'accounting';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import InjectableContent from 'src/components/Survey/InjectableContent';
import Chart from 'src/components/Chart/Chart';
import {
  Actions,
  Card,
  CardEmpty,
  Container,
  TabSegments,
  Tag,
} from 'src/components/IMUI';
import {
  SHORT_TEXT,
  FINANCIAL,
  NUMERICAL,
  LONG_TEXT,
  OPINION_SCALE,
  RATING,
  SINGLE_MATRIX,
  COUNTRY,
} from 'src/data/questionTypes';
import { canAiWriter } from 'src/userStorage';
import { getText } from 'src/services/DictionaryService';
import { abbreviateNumber } from 'src/utils/number';
import { capitalize } from 'src/utils/string';
import AIChartInsights from 'src/pages/App/Analysis/AI/AIChartInsights';
import aiSurveyQuestionInsightsApi from 'src/api/AISurveyQuestionInsights';
import surveyCalculationsApi from 'src/api/SurveyCalculations';
import { where } from 'im/api/Query';
import AnswersTable from './components/AnswersTable';
import DataTable from './components/DataTable';
import { Icon } from 'im/ui/Icon';
import ChartTypeIcon from '../../../Charts/components/ChartTypeIcon';
import SummaryItemSettings from './SummaryItemSettings';
import {
  dataPointsToTabularData,
  matrixCompareData,
  groupBySeries,
  aggregateRows,
  areAllAnswersUnique,
  chartDataFactory,
  chartDataTagFactory,
  getSurveyConfiguration,
} from './surveyAnalysisUtils';
import cls from './SummaryItem.module.css';
const cx = classNames.bind(cls);

const CHART_SETTINGS = [
  {
    name: 'groupBySeries',
    label: 'Group by survey series',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'aggregateColumns',
    label: 'Aggregate columns',
    default: true,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'switchRowsAndColumns',
    label: 'Switch Rows and Columns',
    default: false,
    chartTypes: ['column', 'line', 'bar'],
  },
  {
    name: 'hideEmpty',
    label: 'Hide empty',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'hideNoAnswer',
    label: "Hide 'No Answer'",
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'showDataLabels',
    label: 'Show data labels',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'pie'],
  },
  {
    name: 'dataLabelShowPercent',
    label: 'Show % contribution',
    default: true,
    chartTypes: ['pie'],
  },
  {
    name: 'dataLabelShowAbsolute',
    label: 'Show absolute count',
    default: false,
    chartTypes: ['pie'],
  },
  {
    name: 'useCompactNotation',
    label: 'Use Compact Number Notation',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'pie'],
  },
  {
    name: 'colorByPoint',
    label: 'Multicolor datapoints',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'hideLegend',
    label: 'Hide Legend',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'legendOnTop',
    label: 'Place Legend on top',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'hideXAxis',
    label: 'Hide x axis',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
  {
    name: 'hideYAxis',
    label: 'Hide y axis',
    default: false,
    chartTypes: ['column', 'line', 'bar', 'responseList'],
  },
];
const DEFAULT_SETTINGS = Object.fromEntries(
  CHART_SETTINGS.map((i) => [i.name, i.default])
);

const formatNumber = (num) => abbreviateNumber(num, 2);

const getAvailableCharts = (rows, state) => {
  const hasCategories = rows.length > 2;
  const isSingleDimension = rows[0]?.length === 2;
  const isTagGrouped = Boolean(state.chartTags && state.settings.groupBySeries);
  return [
    ...new Set([
      'column',
      'bar',
      ...(hasCategories ? ['line'] : []),
      ...(isTagGrouped ? ['line'] : []),
      ...(isSingleDimension ? ['pie'] : []),
      'responseList',
    ]),
  ];
};
const getSegments = (fullRows, state) =>
  [
    {
      id: 'column',
      style: { minWidth: 42 },
      text: (
        <ChartTypeIcon
          style={{ padding: 4, border: 0, maxHeight: 32 }}
          type="column"
        />
      ),
      active: state.chartType === 'column',
      tooltipText: getText('Data visualisation: Column'),
    },
    {
      id: 'bar',
      style: { minWidth: 42 },
      text: (
        <ChartTypeIcon
          style={{ padding: 4, border: 0, maxHeight: 32 }}
          type="bar"
        />
      ),
      active: state.chartType === 'bar',
      tooltipText: getText('Data visualisation: Bar'),
    },
    {
      id: 'line',
      style: { minWidth: 42 },
      text: (
        <ChartTypeIcon
          style={{ padding: 4, border: 0, maxHeight: 32 }}
          type="line"
        />
      ),
      active: state.chartType === 'line',
      tooltipText: getText('Data visualisation: Line'),
    },
    {
      id: 'pie',
      style: { minWidth: 42 },
      text: <Icon name="pie" className={cls.actionIcon} />,
      active: state.chartType === 'pie',
      tooltipText: getText('Data visualisation: Pie'),
    },
    {
      id: 'responseList',
      style: { minWidth: 42 },
      text: <Icon name="table" className={cls.actionIcon} />,
      active: state.chartType === 'responseList',
      tooltipText: getText('Responses list'),
    },
  ].filter((s) => getAvailableCharts(fullRows, state).includes(s.id));

const getChartSegments = (chartTags) => [
  { id: 'answers', text: 'Answers', active: !chartTags },
  { id: 'tags', text: 'Tags', active: chartTags },
];

@connect(pick(['survey', 'surveyCalculations']), {
  getSurveyQuestionInsights: aiSurveyQuestionInsightsApi.create,
  getTagsForQuestion: surveyCalculationsApi.tagsForQuestion,
  getTagsForQuestionBySeries: surveyCalculationsApi.tagsForQuestionBySeries,
})
export default class SummaryItem extends React.PureComponent {
  static propTypes = {
    responses: PropTypes.array,
    question: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    answers: PropTypes.array,
    answersToCompare: PropTypes.array,
    questionToCompare: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    projectId: PropTypes.string,
    survey: PropTypes.object,
    surveyCalculations: PropTypes.object,
    getSurveyQuestionInsights: aiSurveyQuestionInsightsApi.create,
    getTagsForQuestion: PropTypes.func,
    getTagsForQuestionBySeries: PropTypes.func,
  };
  static defaultProps = { answers: [], answersToCompare: [] };
  state = {
    chartType: 'responseList',
    settings: DEFAULT_SETTINGS,
    ignoredColumns: [],
    ignoredRows: [],
    chartTags: false,
  };

  componentDidMount() {
    this.updateChartType();
    this.updateChartSeries();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.questionToCompare?.id != this.props?.questionToCompare &&
      prevState.settings.aggregateColumns != !this.props?.questionToCompare
    ) {
      this.handleSettingChanged({
        aggregateColumns: !this.props.questionToCompare,
      });
    }
  }

  updateChartSeries = () => {
    const hasSeries = this.props.survey?.batches?.length > 1;
    const isSingleMatrix = [SINGLE_MATRIX].includes(this.props.question.type);

    if (isSingleMatrix) {
      this.handleSettingChanged({
        groupBySeries: false,
        aggregateColumns: false,
      });
    }

    if (this.state.settings.groupBySeries != hasSeries) {
      this.handleSettingChanged({
        groupBySeries: hasSeries,
        aggregateColumns: !hasSeries,
      });
    }
  };

  getInitialChartType() {
    if (this.props.question.has_attachment) return 'responseList'; // if attachment => response list

    const isOpenLongEnded = [LONG_TEXT].includes(this.props.question.type);
    if (isOpenLongEnded) return 'responseList';

    const allEmpty = this.props.answers.every(
      (ans) => !ans.response.object_value && !ans.response.simple_value
    );
    if (allEmpty) return 'responseList';

    const isShortText = [SHORT_TEXT].includes(this.props.question.type);
    const isNumeric = [RATING, OPINION_SCALE, FINANCIAL, NUMERICAL].includes(
      this.props.question.type
    );
    const longAnswers = this.props.answers.map(
      (ans) => ans.response.simple_value?.length > 20
    );
    const uniqAnswersCount = [
      ...new Set(
        this.props.answers.map((ans) => ans.response.simple_value ?? '')
      ),
    ].length;

    if (isShortText && longAnswers > 5) return 'responseList'; // if more than 5 long answers (more than 20 chars) => response list
    if (!isNumeric && uniqAnswersCount > 25) return 'responseList'; // if is not numeric (can be short text, matrix or whatever) and 20+ answers => response list
    if (DEFAULT_SETTINGS.groupBySeries) return 'line';

    if (this.props.question.type === COUNTRY)
      this.handleSettingChanged({
        hideEmpty: true,
      });

    return 'column';
  }

  updateChartType = () => {
    if (this.getInitialChartType() !== this.state.chartType)
      this.setState({ chartType: this.getInitialChartType() });
  };

  handleTagVsAnswerToggle = () => {
    if (!this.state.chartTags) {
      this.props.getTagsForQuestion({
        surveyQuestionId: this.props.question.id,
        surveyTitle: this.props.survey.name,
      });
      this.props.getTagsForQuestionBySeries({
        surveyQuestionTitle: this.props.question.question,
        surveyTitle: this.props.survey.name,
      });
    }

    this.setState({ chartTags: !this.state.chartTags });
  };
  handleToggleChartType = (chartType) => {
    if (chartType !== this.state.chartType) this.setState({ chartType });
    if (chartType === 'pie')
      this.handleSettingChanged({ showDataLabels: true });
  };
  handleIgnored = (ignoredColumns, ignoredRows) => {
    this.setState({ ignoredColumns, ignoredRows });
  };
  handleSettingChanged = (newSettings) => {
    this.setState((prevState) => ({
      settings: { ...prevState.settings, ...newSettings },
    }));
  };
  renderActions = () => (
    <div style={{ display: 'flex', gap: 8 }}>
      {this.state.settings.groupBySeries && (
        <Tag
          label="Grouped by series"
          onRemove={() => this.handleSettingChanged({ groupBySeries: false })}
        />
      )}
      {this.state.settings.aggregateColumns && (
        <Tag
          label="Columns Aggregated"
          onRemove={() =>
            this.handleSettingChanged({ aggregateColumns: false })
          }
        />
      )}
    </div>
  );

  getTagRows() {
    const filteredOutput = this.state.settings.groupBySeries
      ? this.props.surveyCalculations.data.tagsForQuestionBySeries
      : this.props.surveyCalculations.data.tagsForQuestion;
    return this.state.chartTags && filteredOutput?.rows ? filteredOutput : null;
  }

  getAnswersRows() {
    const {
      answers,
      answersToCompare,
      question,
      questionToCompare,
      responses,
    } = this.props;
    const willCompare =
      questionToCompare != undefined && questionToCompare?.id != question?.id;
    const tabularData = dataPointsToTabularData(
      question,
      responses,
      answers
    ) || [[]];
    const compareData = willCompare
      ? dataPointsToTabularData(questionToCompare, responses, answersToCompare)
      : null;

    const { groupBySeries: canGroup, aggregateColumns: canAggregate } =
      this.state.settings;

    if (canGroup && canAggregate)
      return aggregateRows(
        this.props.question,
        groupBySeries(
          this.props.question,
          tabularData,
          compareData,
          this.props.questionToCompare
        )
      );
    if (canGroup && !canAggregate)
      return groupBySeries(
        this.props.question,
        tabularData,
        compareData,
        this.props.questionToCompare
      );
    if (!canGroup && canAggregate)
      return aggregateRows(
        this.props.question,
        matrixCompareData(tabularData, compareData)
      );

    return matrixCompareData(tabularData, compareData);
  }

  formatValue = (val = 0) => {
    if (this.props.question.type === FINANCIAL)
      return formatMoney(val, `${this.props.question.currency} `);
    if (this.props.question.type === NUMERICAL) return formatNumber(val);
    return val;
  };

  fetchAIInsights = () => {
    return this.props.getSurveyQuestionInsights(
      where().payload({ question_id: this.props.question.id })
    );
  };
  render() {
    const rawChartConfig = this.getTagRows()
      ? chartDataTagFactory(this.getTagRows(), this.state, this.props.question)
      : chartDataFactory(
          this.getAnswersRows(),
          this.state,
          this.props.question
        );

    const message = areAllAnswersUnique(rawChartConfig.unfilteredRows)
      ? 'Answers consist of only unique or empty responses'
      : undefined;
    const hasDataRender = Boolean(rawChartConfig.filteredRows?.length > 1);

    const responsesOnly = this.state.chartType === 'responseList';
    const exportTitle = `${this.props.survey.name} - ${
      this.props.question?.question ||
      capitalize(this.props.question).replace('_', ' ')
    }`;

    const chartKey = [
      `question${this.props.question?.id}-`,
      `compare-${this.props.questionToCompare?.id}-`,
      this.state.ignoredColumns.join('ic'),
      this.state.ignoredRows.join('ir'),
      `t-${Number(Boolean(this.state.chartTags))}-`,
      `b-${Number(Boolean(this.state.settings.switchRowsAndColumns))}-`,
      `a-${Number(Boolean(this.state.settings.groupBySeries))}-`,
      `b-${Number(Boolean(this.state.settings.aggregateColumns))}-`,
      `g-${Number(Boolean(this.state.settings.dataSorting))}-`,
      `c-${Number(Boolean(this.state.settings.colorByPoint))}-`,
      `e-${Number(Boolean(this.state.settings.hideXAxis))}-`,
      `f-${Number(Boolean(this.state.settings.hideYAxis))}-`,
      `h-${Number(Boolean(this.state.settings.xAxisReversed))}-`,
      `e-${Number(Boolean(this.state.settings.hideNoAnswer))}-`,
      `j-${Number(Boolean(this.state.settings.hideEmpty))}`,
    ].join('-');

    const activeTypes = window.highchart?.series?.map((s) => s?.type) || [];
    const filteredSettings = CHART_SETTINGS.filter((setting) => {
      const isActive =
        setting?.chartTypes?.some((t) => activeTypes.includes(t)) ||
        setting.chartTypes.includes(this.state.chartType);
      if (setting.dependent)
        return this.state.settings[setting.dependent] && isActive;
      return isActive;
    });
    const onClearSettings = () => this.handleSettingChanged(DEFAULT_SETTINGS);
    return (
      <div>
        <Container>
          <Card anchor>
            <Container horizontal nowrap className={cls.contentContainer}>
              <Actions>
                <h3 className={cx('questionHeader')}>
                  <InjectableContent
                    text={
                      this.props.question?.question ||
                      getText(capitalize(this.props.question).replace('_', ' '))
                    }
                  />
                </h3>
              </Actions>
              <Actions nowrap>
                <TabSegments
                  className={cls.tabActions}
                  flat
                  border
                  compact
                  dark
                  segments={getSegments(
                    rawChartConfig.unfilteredRows,
                    this.state
                  )}
                  onToggle={this.handleToggleChartType}
                />
                <SummaryItemSettings
                  settings={this.state.settings}
                  filteredSettings={filteredSettings}
                  chartType={this.state.chartType}
                  onSettingChanged={this.handleSettingChanged}
                  onClearSettings={onClearSettings}
                  title={
                    this.props.question?.question ||
                    `question_${this.props.question?.id}`
                  }
                />
              </Actions>
            </Container>

            <div className={cls.chartPlusTabsWrapper}>
              {message && <div className={cls.info}>{message}</div>}
              {!hasDataRender && (
                <CardEmpty
                  title="No chart to Display"
                  description="Please add answers or tags"
                />
              )}

              {hasDataRender && (
                <Container
                  className={cx(cls.contentContainer, cls.chartContainer, {
                    [cls.chartHidden]: responsesOnly,
                  })}
                >
                  {!responsesOnly && (
                    <Chart
                      key={chartKey}
                      config={getSurveyConfiguration(rawChartConfig)}
                      settings={this.state.settings}
                    />
                  )}
                </Container>
              )}

              <div className={cls.tabsWrapper}>
                {!responsesOnly && (
                  <TabSegments
                    flat
                    border
                    dark
                    segments={getChartSegments(this.state.chartTags)}
                    onToggle={this.handleTagVsAnswerToggle}
                  />
                )}
              </div>
            </div>
          </Card>
        </Container>

        {canAiWriter() && (
          <Card className={cls.content}>
            <AIChartInsights onFetchInsights={this.fetchAIInsights} />
          </Card>
        )}

        {!responsesOnly && (
          <Card className={cls.content}>
            <DataTable
              onIgnoredColumnsAndRows={this.handleIgnored}
              exportTitle={exportTitle}
              source={rawChartConfig.unfilteredRows}
              filteredSource={rawChartConfig.filteredRows}
              actionsRenderer={this.renderActions}
              formatValue={this.formatValue}
            />
          </Card>
        )}

        <Card className={cls.content}>
          <AnswersTable
            projectId={this.props.projectId}
            surveyId={String(this.props.survey.id)}
            question={this.props.question}
            responses={this.props.responses}
            answers={this.props.answers}
          />
        </Card>
      </div>
    );
  }
}
