import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, matchPath } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { bindActionCreators } from 'redux';
import { getInitProject } from 'src/actionCreators/projectActionCreators';
import AnalysisAdd from 'src/pages/App/Analysis/Add/AnalysisAdd';
import Charts from 'src/pages/App/Analysis/Charts';
import AnalysisList from 'src/pages/App/Analysis/List/AnalysisList';
import AnalysisEdit from 'src/pages/App/Analysis/Edit/AnalysisEdit';
import AutoCodeReportSelect from 'src/pages/App/Analysis/Reports/ReportSelect';
import Reports from 'src/pages/App/Analysis/Reports/Reports';
import Stories from 'src/pages/App/Analysis/Stories';
import Summary from 'src/pages/App/Analysis/Summary/Summary';
import Surveys from 'src/pages/App/Analysis/Surveys';
import ReportTagEditor from 'src/pages/App/Analysis/TagEditor/TagEditor';
import ReportTagEditor2 from 'src/pages/App/Analysis/TagEditor/TagEditor2';
import Tags from 'src/pages/App/Analysis/Tags';
import Upgrade from 'src/pages/App/Analysis/Upgrade';
import { canAiTagging, isSubscribed } from 'src/userStorage';
import ProjectGlobalMap from 'src/pages/App/Analysis/Summary/GlobalMap/GlobalMap';

const getProjId = (location) =>
  matchPath(location.pathname, {
    path: '/analysis/:projectId',
    exact: false,
    strict: false,
  })?.params.projectId;
class AnalysisIndex extends React.PureComponent {
  static propTypes = {
    project: PropTypes.object,
    location: ReactRouterPropTypes.location,
    match: ReactRouterPropTypes.match,
    getInitProject: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.fetchProject();
  }
  componentDidUpdate(oldProps) {
    if (getProjId(this.props.location) != getProjId(oldProps.location))
      this.fetchProject();
  }
  fetchProject() {
    if (getProjId(this.props.location))
      this.props.getInitProject(getProjId(this.props.location));
  }
  render() {
    const projectKey = `${(
      this.props.project?.enabled_tag_categories || []
    ).join(',')}-${String(this.props.project?.uid)}-${String(
      getProjId(this.props.location)
    )}`;

    if (!isSubscribed()) {
      return (
        <Switch>
          <Route
            key={projectKey}
            path={this.props.match.path}
            component={AnalysisList}
            exact
          />
          <Route
            key={projectKey}
            path={`${this.props.match.path}/add/:step?`}
            component={AnalysisAdd}
            exact
          />
          <Route
            key={projectKey}
            path={`${this.props.match.path}/:projectId/edit`}
            component={AnalysisEdit}
          />
          <Route
            key={projectKey}
            path={`${this.props.match.path}/:projectId/summary`}
            component={Summary}
            exact
          />
          <Route
            key={projectKey}
            path={`${this.props.match.path}/upgrade`}
            component={Upgrade}
          />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route
          key={projectKey}
          path={this.props.match.path}
          component={AnalysisList}
          exact
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/add/:step?`}
          component={AnalysisAdd}
          exact
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/edit`}
          component={AnalysisEdit}
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/summary/map`}
          component={ProjectGlobalMap}
          exact
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/summary`}
          component={Summary}
          exact
        />
        {canAiTagging() && (
          <Route
            key={projectKey}
            path={`${this.props.match.path}/:projectId/autocode`}
            component={AutoCodeReportSelect}
          />
        )}
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/reports`}
          component={Reports}
          exact
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/reports/:reportId`}
          render={(props) => (
            <ReportTagEditor key={this.props.project.uid} {...props} />
          )}
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/reports2/:reportId`}
          render={(props) => (
            <ReportTagEditor2 key={this.props.project.uid} {...props} />
          )}
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/tags`}
          component={Tags}
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/charts`}
          component={Charts}
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/surveys`}
          component={Surveys}
        />
        <Route
          key={projectKey}
          path={`${this.props.match.path}/:projectId/stories`}
          component={Stories}
        />
        <Redirect
          from={`${this.props.match.path}/:projectId`}
          to={`${this.props.match.url}/:projectId/summary`}
        />
        <Redirect from="*" to={this.props.match.url} />
      </Switch>
    );
  }
}

export default connect(pick(['project']), (dispatch) =>
  bindActionCreators({ getInitProject }, dispatch)
)(AnalysisIndex);
